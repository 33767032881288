import classNames from 'class-names';
import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';

import { StateContext } from '../../StateContext';

const ConnectionBadge = () => {
  const { context } = useContext(StateContext);

  return (
    <Badge
      className={classNames(
        'px-3 py-1',
        context.isConnected && 'badge-success',
        !context.isConnected && (context.isOnline ? '' : 'badge-gray'),
      )}
      style={
        !context.isConnected && context.isOnline
          ? {
              fontSize: '12px',
              color: '#08C945',
              border: '1px solid #08C945',
            }
          : { fontSize: '12px' }
      }
    >
      {context.isConnected && 'CONNECTED'}
      {!context.isConnected && (context.isOnline ? 'AVAILABLE' : 'UNAVAILABLE')}
    </Badge>
  );
};

export default ConnectionBadge;
