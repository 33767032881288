import { Accordion, Button } from '@hvk/react-components';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'class-names';
import PropTypes from 'prop-types';
import React from 'react';

import {
  convertDate,
  convertDuration,
  formatBytes,
  formatWanConnectionName,
} from '../../utility/HelperFunctions';

import { handleGuestVoucherActions } from './ColumnActions';
import MobileTable from './MobileTable';

const columnHelper = createColumnHelper();

//
// Row Selection
//

const SelectBox = ({ type = 'radio', label, inputId, ...rest }) => {
  const ref = React.useRef(null);
  return (
    <>
      <input
        type={type}
        style={{
          width: '20px',
          minWidth: '20px',
          height: '20px',
          minHeight: '20px',
          cursor: 'pointer',
        }}
        onClick={(event) => event.stopPropagation()}
        ref={ref}
        id={inputId}
        {...rest}
      />
      {label != null && label !== '' && (
        // TODO: Attempt to resolve lint issues.
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <label
          className="m-0"
          style={{ cursor: 'pointer' }}
          htmlFor={inputId}
          onClick={(event) => event.stopPropagation()}
        >
          {label}
        </label>
      )}
    </>
  );
};

SelectBox.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']),
  label: PropTypes.string,
  inputId: PropTypes.string.isRequired,
};

const selectableCellSort = (rowA, rowB, columnId) => {
  return rowA?.original[columnId] < rowB?.original[columnId] ? -1 : 1;
};

//
// Sub-Tables
//

const WanUsageSubTable = ({
  data,
  rowClass = 'w-50 px-2',
  rowStyle = { minWidth: '105px' },
}) => {
  const subTableRows = data?.map((wan, index) => (
    <tr key={wan.wanName}>
      <td
        className={classNames(
          index === 0 ? 'pt-1' : 'pt-0',
          index < data.length - 1 ? 'pb-0' : 'pb-1',
          rowClass,
        )}
      >
        <div style={rowStyle}>
          <div className="d-flex justify-content-between">
            <div>{formatWanConnectionName(wan.wanName)}</div>
            <div>{formatBytes(wan.allowedBytes, 'None')}</div>
          </div>
        </div>
      </td>
    </tr>
  ));

  return (
    <table className="m-0 table table-borderless table-sm">
      <tbody>{subTableRows}</tbody>
    </table>
  );
};

WanUsageSubTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      wanName: PropTypes.string.isRequired,
      allowedBytes: PropTypes.number.isRequired,
    }),
  ).isRequired,
  rowClass: PropTypes.string,
  rowStyle: PropTypes.shape({}),
};

const leftWanUsageColumn = columnHelper.accessor(
  ({ wanUsageLimits }) => {
    const leftCols = wanUsageLimits.slice(
      0,
      Math.ceil(wanUsageLimits.length / 2),
    );
    return <WanUsageSubTable data={leftCols} />;
  },
  {
    accessorKey: 'wanUsageLimits1',
    header: '',
    enableSorting: false,
    className: 'p-0',
    rowSpan: 2,
  },
);

const rightWanUsageColumn = columnHelper.accessor(
  ({ wanUsageLimits }) => {
    const rightCols = wanUsageLimits.slice(
      Math.ceil(wanUsageLimits.length / 2),
    );
    return <WanUsageSubTable data={rightCols} />;
  },
  {
    accessorKey: 'wanUsageLimits2',
    header: '',
    enableSorting: false,
    className: 'p-0',
    rowSpan: 2,
  },
);

const splitWanUsageColumn = columnHelper.group({
  header: 'Allocation(s)',
  headerButtonClassNames: 'py-0',
  columns: [leftWanUsageColumn, rightWanUsageColumn],
});

//
// Accordions
//

const AccordionTable = ({
  header,
  headerStyle = {},
  data,
  rowKey,
  columnData,
  noHeaders = false,
}) => {
  if (data == null || columnData == null) {
    return '';
  }
  return (
    <Accordion defaultActiveKey="-1">
      <Accordion.Card className="p-0">
        <Accordion.CardHeader
          controlBtnClass="text-left text-black font-weight-bold"
          eventKey="0"
          arrow="start"
          arrowClass="text-gray-600"
        >
          <div style={headerStyle}>{header}</div>
        </Accordion.CardHeader>
        <Accordion.CardCollapse eventKey="0">
          <MobileTable
            data={[data]}
            rowKey={rowKey}
            columnData={columnData}
            className="border-top"
            subTable
            noHeaders={noHeaders}
          />
        </Accordion.CardCollapse>
      </Accordion.Card>
    </Accordion>
  );
};

AccordionTable.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  headerStyle: PropTypes.shape({}),
  data: PropTypes.shape({}).isRequired,
  rowKey: PropTypes.string.isRequired,
  columnData: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      className: PropTypes.string,
      format: PropTypes.func,
    }),
  ).isRequired,
  noHeaders: PropTypes.bool,
};

const getVoucherAccordion = (profileData, header, isMobile = false) => {
  const cols = [
    {
      header: 'Allocation Amount',
      key: 'wanUsageLimits',
      className: 'p-0',
      format: (wanUsageLimits) => {
        return (
          <WanUsageSubTable
            data={wanUsageLimits}
            rowClass="w-100 px-2"
            rowStyle={{ minWidth: '105px', maxWidth: isMobile ? '160px' : '' }}
          />
        );
      },
    },
    {
      header: 'Description',
      key: 'description',
      format: (description) => {
        return (
          <div style={isMobile ? {} : { maxWidth: '160px' }}>{description}</div>
        );
      },
    },
  ];
  return (
    <AccordionTable
      header={header != null ? header : profileData.name}
      headerStyle={{ lineHeight: 1.2, fontSize: '14px' }}
      data={profileData}
      rowKey="uuid"
      columnData={cols}
      noHeaders
    />
  );
};

const voucherAccordionSort = (rowA, rowB, columnId) => {
  return rowA?.original?.profileData[columnId] <
    rowB?.original?.profileData[columnId]
    ? -1
    : 1;
};

const guestVoucherColumn = columnHelper.accessor(() => {}, {
  accessorKey: 'name',
  header: 'Voucher Profile',
  cell: ({ row }) => {
    const { username, profileData } = row.original;
    const rowId = `select_guest_voucher_${username}`;
    const header = (
      <div className="d-flex align-items-center">
        <SelectBox
          type="checkbox"
          inputId={rowId}
          label={profileData?.name}
          className="mr-2"
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      </div>
    );
    return getVoucherAccordion(profileData, header);
  },
  className: 'p-0',
  width: 220,
  maxWidth: 220,
  sortingFn: voucherAccordionSort,
});

const getGuestVoucherColumn = (props) => {
  return { ...guestVoucherColumn, ...props };
};

const userVoucherColumn = columnHelper.accessor(
  ({ profileData }) => getVoucherAccordion(profileData),
  {
    accessorKey: 'name',
    header: 'Voucher Profile',
    className: 'p-0',
    width: 200,
    maxWidth: 200,
    sortingFn: voucherAccordionSort,
  },
);

//
// Desktop Tables
//

export const selectUserColumns = [
  columnHelper.accessor(() => {}, {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => {
      const rowId = `select_user_${row.original.name}`;
      return (
        <div className="d-flex align-items-center">
          <SelectBox
            inputId={rowId}
            label={row.original.name}
            className="mr-2"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </div>
      );
    },
    sortingFn: selectableCellSort,
  }),
  {
    accessorKey: 'username',
    header: 'Username',
  },
];

export const selectVoucherProfileColumns = [
  columnHelper.accessor(() => {}, {
    accessorKey: 'name',
    header: 'Profile',
    cell: ({ row }) => {
      const rowId = `select_voucher_${row.original.name}`;
      return (
        <div className="d-flex align-items-center">
          <SelectBox
            inputId={rowId}
            label={row.original.name}
            className="mr-2"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </div>
      );
    },
    sortingFn: selectableCellSort,
    rowSpan: 2,
  }),
  splitWanUsageColumn,
  {
    accessorKey: 'description',
    header: 'Description',
    maxWidth: 250,
    rowSpan: 2,
  },
];

export const guestVoucherColumns = [
  getGuestVoucherColumn(),
  {
    accessorKey: 'username',
    header: 'Username',
  },
  {
    accessorKey: 'pin',
    header: 'PIN',
  },
  columnHelper.accessor(
    ({ createdAt }) => {
      return createdAt ? convertDate(createdAt) : '';
    },
    {
      accessorKey: 'createdAt',
      header: 'Date Issued',
    },
  ),
  columnHelper.accessor(
    ({ lastLogin }) => {
      return lastLogin ? convertDate(lastLogin) : '';
    },
    {
      accessorKey: 'lastLogin',
      header: 'Last Login',
    },
  ),
  {
    accessorKey: 'action',
    header: 'Action',
    enableSorting: false,
    className: 'p-2',
    width: 81,
    minWidth: 81,
    maxWidth: 81,
    actions: [
      { icon: 'copy-square', title: 'Copy' },
      { icon: 'print-square', title: 'Print' },
    ],
    actionIconClass: 'h4 m-0',
    actionButtonClass: 'mb-0 p-0 text-gray',
  },
];

export const usageLogColumns = [
  columnHelper.accessor(
    ({ startedAt }) => {
      return startedAt ? convertDate(startedAt) : '';
    },
    {
      accessorKey: 'startedAt',
      header: 'Session Start',
    },
  ),
  columnHelper.accessor(
    ({ stoppedAt }) => {
      return stoppedAt ? convertDate(stoppedAt) : '';
    },
    {
      accessorKey: 'stoppedAt',
      header: 'Session End',
    },
  ),
  columnHelper.accessor(
    ({ wanName }) => {
      return wanName ? formatWanConnectionName(wanName) : '';
    },
    {
      accessorKey: 'wanName',
      header: 'WAN Connection',
    },
  ),
  columnHelper.accessor(
    ({ byteCount }) => {
      return formatBytes(byteCount);
    },
    {
      accessorKey: 'byteCount',
      header: 'Data Used',
    },
  ),
  columnHelper.accessor(
    ({ durationSecs }) => {
      return convertDuration(durationSecs);
    },
    {
      accessorKey: 'durationSecs',
      header: 'Duration',
    },
  ),
];

export const userVoucherColumns = [
  userVoucherColumn,
  {
    accessorKey: 'userFullName',
    header: 'Name',
  },
  {
    accessorKey: 'username',
    header: 'Username',
  },
  columnHelper.accessor(
    ({ createdAt }) => {
      return createdAt ? convertDate(createdAt) : '';
    },
    {
      accessorKey: 'createdAt',
      header: 'Date Issued',
    },
  ),
];

//
// Mobile Tables
//

export const selectVoucherProfileColumnsMobile = [
  columnHelper.accessor(() => {}, {
    accessorKey: 'columns',
    header: () => <div className="text-center">Voucher Profiles</div>,
    cell: ({ row }) => {
      const cols = [
        {
          header: 'Allocation(s)',
          key: 'wanUsageLimits',
          className: 'p-0',
          format: (wanUsageLimits) => {
            return (
              <WanUsageSubTable
                data={wanUsageLimits}
                rowClass="w-100 px-2"
                rowStyle={{
                  maxWidth: '160px',
                  minWidth: '105px',
                }}
              />
            );
          },
        },
        {
          header: 'Description',
          key: 'description',
        },
      ];

      const rowId = `select_voucher_${row.original.name}`;
      const header = (
        <div className="d-flex align-items-center">
          <SelectBox
            inputId={rowId}
            label={row.original.name}
            className="mr-2"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </div>
      );

      return (
        <AccordionTable
          header={header}
          headerStyle={{ lineHeight: 1.2, fontSize: '14px' }}
          data={row.original}
          rowKey="uuid"
          columnData={cols}
        />
      );
    },
    enableSorting: false,
    className: 'p-0',
    rowSpan: 2,
  }),
];

export const guestVoucherColumnsMobile = [
  columnHelper.accessor(() => {}, {
    accessorKey: 'columns',
    header: () => <div className="text-center">Guest Vouchers</div>,
    cell: ({ row }) => {
      const copyIcon = 'copy-square';
      const printIcon = 'print-square';

      const cols = [
        {
          header: 'Voucher Profile',
          key: 'profileData',
          className: 'p-0',
          format: (profileData) =>
            getVoucherAccordion(profileData, profileData.name, true),
        },
        {
          header: 'Username',
          key: 'username',
        },
        {
          header: 'PIN',
          key: 'pin',
        },
        {
          header: 'Date Issued',
          key: 'createdAt',
          format: (createdAt) => {
            return createdAt ? convertDate(createdAt, false) : '\u2014';
          },
        },
        {
          header: 'Last Login',
          key: 'lastLogin',
          format: (lastLogin) => {
            return lastLogin ? convertDate(lastLogin, false) : '\u2014';
          },
        },
        {
          header: 'Action',
          key: 'action',
          format: () => {
            return (
              <div className="d-flex justify-content-around">
                <div />
                <Button
                  key="copy"
                  variant="link"
                  className="mb-0 ml-1 p-0 text-gray"
                  icon={copyIcon}
                  iconClass="h1 m-0"
                  title="Copy"
                  onClick={() =>
                    handleGuestVoucherActions(copyIcon, row.original)
                  }
                />
                <Button
                  key="print"
                  variant="link"
                  className="mb-0 ml-1 p-0 text-gray"
                  icon={printIcon}
                  iconClass="h1 m-0"
                  title="Print"
                  onClick={() =>
                    handleGuestVoucherActions(printIcon, row.original)
                  }
                />
                <div />
              </div>
            );
          },
        },
      ];

      const { createdAt, allocationBundleName, username } = row.original;
      const rowLabel = `${convertDate(
        createdAt,
      )} \u2014 ${allocationBundleName}`;
      const rowId = `select_guest_voucher_${username}`;
      const header = (
        <div className="d-flex align-items-center">
          <SelectBox
            type="checkbox"
            inputId={rowId}
            label={rowLabel}
            className="mr-2"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </div>
      );

      return (
        <AccordionTable
          header={header}
          headerStyle={{ lineHeight: 1.2, fontSize: '14px' }}
          data={row.original}
          rowKey="username"
          columnData={cols}
        />
      );
    },
    enableSorting: false,
    className: 'p-0',
    rowSpan: 2,
  }),
];

export const usageLogColumnsMobile = [
  columnHelper.accessor(
    (data) => {
      const cols = [
        {
          header: 'Session Start',
          key: 'startedAt',
          format: (date) => convertDate(date, false),
        },
        {
          header: 'Session End',
          key: 'stoppedAt',
          format: (date) => convertDate(date, false),
        },
        {
          header: 'WAN Connection',
          key: 'wanName',
          format: formatWanConnectionName,
        },
        {
          header: 'Data Used',
          key: 'byteCount',
          format: formatBytes,
        },
        {
          header: 'Duration',
          key: 'durationSecs',
          format: convertDuration,
        },
      ];
      return (
        <AccordionTable
          header={`${convertDate(
            data.startedAt,
          )} \u2014 ${formatWanConnectionName(data.wanName)}`}
          headerStyle={{ lineHeight: 1.2, fontSize: '14px' }}
          data={data}
          rowKey="startedAt"
          columnData={cols}
        />
      );
    },
    {
      accessorKey: 'columns',
      header: () => <div className="text-center">Usage Log</div>,
      enableSorting: false,
      className: 'p-0',
      rowSpan: 2,
    },
  ),
];

export const userVoucherColumnsMobile = [
  columnHelper.accessor(
    (data) => {
      const cols = [
        {
          header: 'Voucher Profile',
          key: 'profileData',
          className: 'p-0',
          format: (profileData) =>
            getVoucherAccordion(profileData, profileData.name, true),
        },
        {
          header: 'Name',
          key: 'userFullName',
        },
        {
          header: 'Username',
          key: 'username',
        },
        {
          header: 'Date Issued',
          key: 'createdAt',
          format: (createdAt) => {
            return createdAt ? convertDate(createdAt, false) : '\u2014';
          },
        },
      ];
      return (
        <AccordionTable
          header={`${data.allocationBundleName} \u2014 ${
            data.userFullName || data.username
          }`}
          headerStyle={{ lineHeight: 1.2, fontSize: '14px' }}
          data={data}
          rowKey="username"
          columnData={cols}
        />
      );
    },
    {
      accessorKey: 'columns',
      header: () => <div className="text-center">User Vouchers</div>,
      enableSorting: false,
      className: 'p-0',
      rowSpan: 2,
    },
  ),
];
