import { Icon } from '@hvk/react-components';
import React from 'react';

const KvhLogo = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        backgroundColor: '#CC0000',
        width: '114px',
        height: '51px',
        margin: '10px auto 10px auto',
      }}
    >
      <Icon
        name="kvh-logo"
        color="white"
        style={{ height: '80%', width: '80%', color: 'white' }}
      />
    </div>
  );
};

export default KvhLogo;
