import { isServiceMocked } from './isServiceMocked';
import * as mockService from './mock/MockUsageLogService';
import { axios2 } from './ServiceClients';
import ServiceUrls from './ServiceURLs';

export const getUsageLog = (accessToken, timeoutMs = 60000) => {
  if (isServiceMocked('usageLog', 'getUsageLog')) {
    return mockService.getUsageLog();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_USAGE_LOG,
    'GET',
    null,
    null,
    headers,
    timeoutMs,
  );
};
