import React from 'react';

const Spinner = () => {
  return (
    <div>
      <div className="spinner-border text-primary" role="status" />
    </div>
  );
};

export default Spinner;
