// Generic error messages to be displayed as needed.
export const ErrorMsgs = {
  common_error: 'Something went wrong.',
  information_not_available:
    'Information not available while offline, please try again later.',
  logout_error:
    'You have been logged out for security. Please log back in to continue.',
  no_vouchers:
    'You have not been assigned any guest vouchers. Please talk to a manager to gain Internet access.',
  no_wans:
    'You have not been assigned any usage profiles. Please talk to a manager to gain Internet access.',
  required_fields_missing: 'Request is missing required fields.',
};

// Login and Set Password page text and toaster messages.
export const LoginMsgs = {
  email_pass_empty: 'Username and Password are required.',
  login_failure: 'Login failed, please try again later.',
  login_success: 'Login successful.',
  reset_password_failure: 'Password reset failed, please try again later.',
  reset_password_success: 'Password successfully changed.',
  username_validation_error: 'Username cannot have spaces.',
};

// Start/Stop Session page text and toaster messages.
export const InternetAccessMsgs = {
  invalid_profile_token: 'Invalid profile token.',
  invalid_session_token: 'Invalid session token.',
  min_session_length: 'Session must be at least 1 minute long.',
  no_active_sessions: 'No active sessions running to stop.',
  no_internet: 'Cannot start session while Internet is unavailable.',
  session_end_failure: 'Session was not ended successfully.',
  session_end_no_data: 'You have used all of your allocated data for this WAN.',
  session_end_no_time: 'You have used all of your allocated time for this WAN.',
  session_end_success: 'Session ended successfully.',
  session_end_timeout:
    'A running Internet session has been detected and ended successfully.',
  session_end_wan_switch: 'Session was ended due to a WAN change.',
  session_resume_success:
    'A running Internet session has been detected and resumed successfully.',
  session_start_failure: 'Session was not started successfully.',
  session_start_success: 'Session started successfully.',
};

// Default messages based on HTTP error codes received from API calls.
export const ApiMsgs = {
  onboard_fw_token_400: 'Cannot log in user on this IP.',
  onboard_fw_delete_crew_session_400: 'Bad session stop request.',
  onboard_fw_delete_crew_session_401: InternetAccessMsgs.invalid_profile_token,
  onboard_fw_delete_crew_session_503: 'Session cannot be stopped at this time.',
  onboard_fw_get_crew_session_400: 'Bad session status request.',
  onboard_fw_get_crew_session_401: InternetAccessMsgs.invalid_profile_token,
  onboard_fw_get_crew_session_503: 'Session status unavailable.',
  onboard_fw_post_crew_session_400: 'Bad session start request.',
  onboard_fw_post_crew_session_401: InternetAccessMsgs.invalid_profile_token,
  onboard_fw_post_crew_session_503: 'Session cannot be started at this time.',
  onshore_login_400: ErrorMsgs.required_fields_missing,
  onshore_login_401: 'Incorrect login credentials.',
  onshore_pw_reset_400: 'Invalid password reset request.',
  onshore_pw_reset_401: 'Cannot set password for this user.',
  onshore_session_400: ErrorMsgs.required_fields_missing,
  onshore_session_401: 'Crew Internet service is not currently enabled.',
  onshore_session_404: 'Wan not found for user profile.',
  onshore_session_422: 'Not enough time or data to start session.',
  onshore_session_500: 'Wan name found in multiple profiles.',
};

// Expected error messages gathered from external components.
export const ExternalMsgs = {
  django_token_not_valid: 'Given token not valid for any token type',
};

// User roles to determine access to different features.
export const Roles = {
  crew: 'crew',
  guest: 'guest',
  ob_manager: 'ob_manager',
};
