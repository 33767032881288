import { axios2 } from './ServiceClients';
import ServiceUrls from './ServiceURLs';

export const getCbxStatus = (timeoutMs = 1000) => {
  return axios2(
    ServiceUrls.ONBOARD_CBX_STATUS,
    'GET',
    null,
    null,
    null,
    timeoutMs,
  );
};
