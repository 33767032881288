export const rootRoute = '/';
export const setPasswordRoute = '/setPassword';

export const accountSummaryRoute = '/accountSummary';
export const usageLogRoute = '/usageLog';
export const privacyPolicyRoute = '/privacyPolicy';
export const termsOfUseRoute = '/termsOfUse';

export const guestVouchersRoute = '/guestVouchers';
export const guestVouchersAddRoute = `${guestVouchersRoute}/add`;
export const userVouchersRoute = '/userVouchers';
export const userVouchersAddRoute = `${userVouchersRoute}/add`;
