import { toast } from 'react-toastify';

import { getJWTField } from '../utility/HelperFunctions';
import {
  ApiMsgs,
  ErrorMsgs,
  ExternalMsgs,
  InternetAccessMsgs,
} from '../utility/StaticTexts';

import {
  deleteCrewSession,
  deleteUserSession,
  getCrewSession,
  postCrewSession,
  postUserSession,
} from './InternetSessionService';

export const startSession = async (
  accessToken,
  sessionSecs,
  wanName,
  canRetry = true,
) => {
  const userSessionData = {
    sessionTimeoutSecs: sessionSecs,
    wanName,
  };

  // Default results in the event that something goes wrong.
  let results = {
    success: false,
    sessionUUID: '',
    onshoreStartToken: '',
    sessionStatus: -1,
    logOutUser: false,
  };
  try {
    // Start the session onshore and receive a profile token.
    await postUserSession(accessToken, userSessionData)
      .then(async (userSessionResponse) => {
        if (userSessionResponse?.status === 201) {
          // Get profile token and decode.
          results.onshoreStartToken =
            userSessionResponse?.data?.totalAllocation?.userProfileToken;
          const sessionUUID = getJWTField(
            results.onshoreStartToken,
            'session_uuid',
          );
          if (sessionUUID != null) {
            results.sessionUUID = sessionUUID;
          } else {
            toast.error(InternetAccessMsgs.invalid_profile_token);
          }

          // Start the session on the terminal.
          await postCrewSession(results.onshoreStartToken)
            .then(async (crewSessionResponse) => {
              if (crewSessionResponse?.status === 200) {
                results.success = true;

                // Confirm session has been started successfully.
                await getCrewSession(
                  results.onshoreStartToken,
                  results.sessionUUID,
                )
                  .then((getCrewSessionResponse) => {
                    results.sessionStatus =
                      getCrewSessionResponse?.data?.status;
                  })
                  .catch((error) => {
                    switch (error?.response?.status) {
                      case 400:
                        toast.error(ApiMsgs.onboard_fw_get_crew_session_400);
                        break;
                      case 401:
                        toast.error(ApiMsgs.onboard_fw_get_crew_session_401);
                        break;
                      case 503:
                        toast.error(ApiMsgs.onboard_fw_get_crew_session_503);
                        break;
                      default:
                        // eslint-disable-next-line no-console
                        console.log(error?.response);
                        toast.error(ErrorMsgs.common_error);
                    }
                    results.sessionStatus = error?.response?.status;
                  });
              } else {
                toast.error(ErrorMsgs.common_error);
                results.sessionStatus = crewSessionResponse?.status;
              }
            })
            .catch((error) => {
              switch (error?.response?.status) {
                case 400:
                  toast.error(ApiMsgs.onboard_fw_post_crew_session_400);
                  break;
                case 401:
                  toast.error(ApiMsgs.onboard_fw_post_crew_session_401);
                  break;
                case 503:
                  toast.error(ApiMsgs.onboard_fw_post_crew_session_503);
                  break;
                default:
                  // eslint-disable-next-line no-console
                  console.log(error?.response);
                  toast.error(ErrorMsgs.common_error);
              }
              results.sessionStatus = error?.response?.status;
            });
        } else if (canRetry) {
          results = await startSession(
            accessToken,
            sessionSecs,
            wanName,
            false,
          ).then((newResults) => newResults);
        } else {
          results.sessionStatus = userSessionResponse?.status;
          toast.error(ErrorMsgs.common_error);
        }
      })
      .catch((error) => {
        switch (error?.response?.status) {
          case 400:
            toast.error(ApiMsgs.onshore_session_400);
            break;
          case 401:
            if (
              error?.response?.data?.errorMessage ===
              ExternalMsgs.django_token_not_valid
            ) {
              // If invalid token, log out the user for security.
              results.logOutUser = true;
            } else {
              // Use API-supplied message if availaible.
              toast.error(
                error?.response?.data?.errorMessage ||
                  ApiMsgs.onshore_session_401,
              );
            }
            break;
          case 404:
            toast.error(ApiMsgs.onshore_session_404);
            break;
          case 422:
            // Use API-supplied message if availaible.
            toast.error(
              error?.response?.data?.errorMessage ||
                ApiMsgs.onshore_session_422,
            );
            break;
          case 500:
            toast.error(ApiMsgs.onshore_session_500);
            break;
          default:
            // eslint-disable-next-line no-console
            console.log(error?.response);
            toast.error(ErrorMsgs.common_error);
        }
        results.sessionStatus = error?.response?.status;
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    toast.error(ErrorMsgs.common_error);
  }

  return results;
};

export const stopSession = async (
  accessToken,
  onshoreStartToken,
  sessionUUID,
) => {
  // Default results in the event that something goes wrong.
  let results = {
    success: false,
    timeUsed: 0,
    bytesUsed: 0,
  };
  try {
    if (sessionUUID != null && sessionUUID !== '') {
      // Stop session on the terminal.
      await deleteCrewSession(onshoreStartToken, sessionUUID)
        .then(async (deleteCrewSessionResponse) => {
          if (deleteCrewSessionResponse?.status === 200) {
            // Confirm session has been stopped successfully.
            await getCrewSession(onshoreStartToken, sessionUUID, true)
              .then((getCrewSessionResponse) => {
                const userData = getCrewSessionResponse?.data;
                const userSessionData = {
                  bytesIn: userData?.bytes_in,
                  bytesOut: userData?.bytes_out,
                };
                if (
                  getCrewSessionResponse?.status === 200 &&
                  userData?.status >= 5
                ) {
                  results = {
                    success: true,
                    timeUsed:
                      userData?.duration != null ? userData.duration : 0,
                    bytesUsed:
                      (userData?.bytes_in != null ? userData.bytes_in : 0) +
                      (userData?.bytes_out != null ? userData.bytes_out : 0),
                  };

                  // Stop session onshore, but ignore response.
                  deleteUserSession(accessToken, userSessionData).catch(
                    (error) => {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    },
                  );
                } else {
                  toast.error(ErrorMsgs.common_error);
                }
              })
              .catch((error) => {
                switch (error?.response?.status) {
                  case 400:
                    toast.error(ApiMsgs.onboard_fw_get_crew_session_400);
                    break;
                  case 401:
                    toast.error(ApiMsgs.onboard_fw_get_crew_session_401);
                    break;
                  case 503:
                    toast.error(ApiMsgs.onboard_fw_get_crew_session_503);
                    break;
                  default:
                    // eslint-disable-next-line no-console
                    console.log(error?.response);
                    toast.error(ErrorMsgs.common_error);
                }
              });
          }
        })
        .catch((error) => {
          switch (error?.response?.status) {
            case 400:
              toast.error(ApiMsgs.onboard_fw_delete_crew_session_400);
              break;
            case 401:
              toast.error(ApiMsgs.onboard_fw_delete_crew_session_401);
              break;
            case 503:
              toast.error(ApiMsgs.onboard_fw_delete_crew_session_503);
              break;
            default:
              // eslint-disable-next-line no-console
              console.log(error?.response);
              toast.error(ErrorMsgs.common_error);
          }
        });
    } else {
      toast.error(InternetAccessMsgs.no_active_sessions);
      results.success = true;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    toast.error(ErrorMsgs.common_error);
  }

  return results;
};
