import PropTypes from 'prop-types';
import React from 'react';

import { StateContextProvider } from './StateContext';
import AppRoutes from './utility/routes/AppRoutes';

import 'react-toastify/dist/ReactToastify.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h3 className="m-auto">Oops, something snapped...</h3>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

const App = () => {
  return (
    <ErrorBoundary>
      <StateContextProvider>
        <AppRoutes />
      </StateContextProvider>
    </ErrorBoundary>
  );
};

export default App;
