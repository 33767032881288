import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

import {
  formatBytes,
  formatWanConnectionName,
} from '../../utility/HelperFunctions';

const SummaryTableGuest = ({ voucherAllocations, userContext }) => {
  const labelClass =
    'data-table-label py-2 border-right bold-text text-uppercase';

  return (
    <div>
      <div className="h6">Vouchers</div>
      <Table className="border m-0" style={{ fontSize: '14px' }}>
        <tbody>
          <tr>
            <td className={labelClass} />
            <td className={labelClass}>Allowance</td>
            <td className={labelClass}>Usage</td>
          </tr>
          {Object.keys(voucherAllocations).map((voucher) => {
            const { wanName, allowedBytes, totalUsageBytes } =
              voucherAllocations[voucher];
            return (
              <tr key={wanName}>
                <td
                  className={labelClass}
                  style={
                    wanName === userContext.wanName
                      ? { backgroundColor: '#1a84c7', color: 'white' }
                      : {}
                  }
                >
                  {formatWanConnectionName(wanName)}
                </td>
                <td className="data-table-value py-2 border-right">
                  {formatBytes(allowedBytes, '\u2014')}
                </td>
                <td className="data-table-value py-2">
                  {formatBytes(totalUsageBytes, '\u2014')}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

SummaryTableGuest.propTypes = {
  voucherAllocations: PropTypes.shape({
    vsat: PropTypes.shape({
      wanName: PropTypes.string.isRequired,
      allowedBytes: PropTypes.number.isRequired,
      totalUsageBytes: PropTypes.number.isRequired,
    }).isRequired,
    cell0: PropTypes.shape({
      wanName: PropTypes.string.isRequired,
      allowedBytes: PropTypes.number.isRequired,
      totalUsageBytes: PropTypes.number.isRequired,
    }).isRequired,
    wifi0: PropTypes.shape({
      wanName: PropTypes.string.isRequired,
      allowedBytes: PropTypes.number.isRequired,
      totalUsageBytes: PropTypes.number.isRequired,
    }).isRequired,
    alternate: PropTypes.shape({
      wanName: PropTypes.string.isRequired,
      allowedBytes: PropTypes.number.isRequired,
      totalUsageBytes: PropTypes.number.isRequired,
    }).isRequired,
    backup: PropTypes.shape({
      wanName: PropTypes.string.isRequired,
      allowedBytes: PropTypes.number.isRequired,
      totalUsageBytes: PropTypes.number.isRequired,
    }).isRequired,
  }),
  userContext: PropTypes.shape({
    wanName: PropTypes.string.isRequired,
  }),
};

export default SummaryTableGuest;
