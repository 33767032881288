import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import TopNavBar from '../../components/TopNav/TopNav';
import AddGuestVouchers from '../../container/GuestVouchers/AddGuestVouchers';
import GuestVouchers from '../../container/GuestVouchers/GuestVouchers';
import StartStopSession from '../../container/InternetSession/StartStopSession';
import Login from '../../container/Login/Login';
import SetPassword from '../../container/Login/SetPassword';
import PrivacyPolicy from '../../container/StaticPages/PrivacyPolicy';
import TermsOfUse from '../../container/StaticPages/TermsOfUse';
import SummaryPage from '../../container/SummaryPage/SummaryPage';
import UsageLog from '../../container/UsageLog/UsageLog';
import AddUserVouchers from '../../container/UserVouchers/AddUserVouchers';
import UserVouchers from '../../container/UserVouchers/UserVouchers';
import { StateContext } from '../../StateContext';
import { isOnboardManager } from '../HelperFunctions';

import {
  accountSummaryRoute,
  guestVouchersAddRoute,
  guestVouchersRoute,
  privacyPolicyRoute,
  rootRoute,
  setPasswordRoute,
  termsOfUseRoute,
  usageLogRoute,
  userVouchersAddRoute,
  userVouchersRoute,
} from '.';

const AppRoutes = () => {
  const { context } = useContext(StateContext);
  const isManager = isOnboardManager(context);

  return (
    <BrowserRouter>
      <TopNavBar />
      {context.isLoggedIn ? (
        <Routes>
          <Route exact path={rootRoute} element={<StartStopSession />} />
          <Route path={accountSummaryRoute} element={<SummaryPage />} />
          <Route path={usageLogRoute} element={<UsageLog />} />
          <Route path={privacyPolicyRoute} element={<PrivacyPolicy />} />
          <Route path={termsOfUseRoute} element={<TermsOfUse />} />

          {isManager && (
            <>
              <Route path={guestVouchersRoute} element={<GuestVouchers />} />
              <Route
                path={guestVouchersAddRoute}
                element={<AddGuestVouchers />}
              />
              <Route path={userVouchersRoute} element={<UserVouchers />} />
              <Route
                path={userVouchersAddRoute}
                element={<AddUserVouchers />}
              />
            </>
          )}
        </Routes>
      ) : (
        <Routes>
          <Route path={setPasswordRoute} element={<SetPassword />} />
          <Route path={privacyPolicyRoute} element={<PrivacyPolicy />} />
          <Route path={termsOfUseRoute} element={<TermsOfUse />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default AppRoutes;
