import React from 'react';
import { createRoot } from 'react-dom/client';

/* eslint import/no-webpack-loader-syntax: off */
/* eslint import/no-unresolved: off */
import '!!svg-sprite-loader!@hvk/theme/dist/spritemap.svg';

import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import '@hvk/theme/dist/main.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

reportWebVitals();
