import classNames from 'class-names';
import React, { useContext, useEffect, useState } from 'react';

import SummaryTable from '../../components/DataTables/SummaryTable';
import SummaryTableGuest from '../../components/DataTables/SummaryTableGuest';
import Spinner from '../../components/Spinner/Spinner';
import ContentBox from '../../layout/ContentBox';
import { getUserSummary } from '../../services/SummaryService';
import { StateContext } from '../../StateContext';
import { isGuestUser, isVtidValid } from '../../utility/HelperFunctions';
import { ErrorMsgs, ExternalMsgs } from '../../utility/StaticTexts';

import styles from './summary-page.module.css';

const SummaryPage = () => {
  const { context, setContext } = useContext(StateContext);
  const isGuest = isGuestUser(context);

  const [wanAllocations, setWanAllocations] = useState({});
  const [voucherAllocations, setVoucherAllocations] = useState({});
  const [loadedContents, setLoadedContents] = useState(false);
  const [showDisconnected, setShowDisconnected] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const handleGetUserSummary = async () => {
    try {
      setShowSpinner(true);
      setShowDisconnected(!context.isOnline);

      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = await isVtidValid(context.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      await getUserSummary(context.accessToken)
        .then((response) => {
          if (response?.status === 200) {
            let newWanAllocations = {};
            if (response.data.wanAllocations != null) {
              newWanAllocations = response.data.wanAllocations?.reduce(
                (prevMapping, current) => {
                  const newMapping = prevMapping;
                  newMapping[current.wanName] = current;
                  return newMapping;
                },
                {},
              );
            }
            setWanAllocations(newWanAllocations);

            let newVoucherAllocations = {};
            if (response.data.voucherAllocations != null) {
              newVoucherAllocations = response.data.voucherAllocations?.reduce(
                (prevMapping, current) => {
                  const newMapping = prevMapping;
                  if (newMapping[current.wanName] != null) {
                    newMapping[current.wanName].allowedBytes +=
                      current.allowedBytes;
                    newMapping[current.wanName].totalUsateBytes +=
                      current.totalUsateBytes;
                  } else {
                    newMapping[current.wanName] = current;
                  }
                  return newMapping;
                },
                {},
              );
            }
            setVoucherAllocations(newVoucherAllocations);
            setLoadedContents(true);
          } else {
            setShowDisconnected(true);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.errorMessage ===
            ExternalMsgs.django_token_not_valid
          ) {
            // If invalid token, log out the user for security.
            setContext({ forceLogOut: true });
          } else {
            setShowDisconnected(true);
          }
        });
    } catch (error) {
      setShowDisconnected(true);
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    // Attempts to load content if not already loaded.
    if (!loadedContents) {
      handleGetUserSummary();
    }

    // Attempts to show content if loaded but not showing.
    if (loadedContents && showDisconnected) {
      setShowDisconnected(!context.isOnline);
    }
  }, [context.isOnline, loadedContents]);

  return (
    <ContentBox heading="Account Summary">
      {showSpinner ? (
        <div className="d-flex justify-content-center py-4">
          <Spinner />
        </div>
      ) : (
        <div>
          {showDisconnected ? (
            <div className="p-4">{ErrorMsgs.information_not_available}</div>
          ) : (
            <>
              {!isGuest && Object.keys(wanAllocations).length === 0 && (
                <div className="p-4">{ErrorMsgs.no_wans}</div>
              )}
              {isGuest && Object.keys(voucherAllocations).length === 0 && (
                <div className="p-4">{ErrorMsgs.no_vouchers}</div>
              )}

              <div className={styles.summaryTable}>
                {!isGuest &&
                  Object.keys(wanAllocations).map((wan) => {
                    return (
                      <div
                        className={classNames(
                          styles.summaryTableCol,
                          'col-sm-12 col-md-6',
                        )}
                        key={wanAllocations[wan].name}
                      >
                        <SummaryTable
                          wanAllocation={wanAllocations[wan]}
                          voucherAllocation={voucherAllocations[wan]}
                          userContext={context}
                        />
                      </div>
                    );
                  })}
                {isGuest && (
                  <div className={classNames(styles.summaryTableCol, 'col-12')}>
                    <SummaryTableGuest
                      voucherAllocations={voucherAllocations}
                      userContext={context}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </ContentBox>
  );
};

export default SummaryPage;
