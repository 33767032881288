import { Button, CustomModal } from '@hvk/react-components';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { stopSession } from '../../services/StartStopSessionService';
import { StateContext } from '../../StateContext';
import { InternetAccessMsgs } from '../../utility/StaticTexts';
import Spinner from '../Spinner/Spinner';

const DisconnectModal = ({ modal, onHide, onDisconnect }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const { context, setContext } = useContext(StateContext);

  const disconnect = (e) => {
    setIsDisabled(true);
    // Attempt to stop the session.
    stopSession(
      context.accessToken,
      context.onshoreStartToken,
      context.sessionUUID,
    )
      .then((results) => {
        if (results.success) {
          // Clear out session interval and reset session-related context.
          clearInterval(context.sessionIntervalID);
          setContext({
            isConnected: false,
            onshoreStartToken: '',
            sessionUUID: '',
            sessionStart: 0,
            sessionEnd: 0,
            sessionLength: 0,
            sessionIntervalID: null,
          });

          // Close the modal and run any callbacks.
          onHide(false);
          onDisconnect(e);

          setTimeout(() => {
            toast.success(InternetAccessMsgs.session_end_success, {
              className: 'bg-success',
            });
          }, 100);
        } else {
          // Close the modal and run any callbacks.
          onHide(false);
          onDisconnect(e);

          setTimeout(() => {
            toast.error(InternetAccessMsgs.session_end_failure);
          }, 100);
        }
      })
      .catch(() => {
        // Close the modal and run any callbacks.
        onHide(false);
        onDisconnect(e);

        setTimeout(() => {
          toast.error(InternetAccessMsgs.session_end_failure);
        }, 100);
      });
  };

  return (
    <CustomModal modal={modal} onHide={onHide} size="md">
      <CustomModal.Header onHide={onHide} centered>
        Are you sure you want to disconnect and log off?
      </CustomModal.Header>
      <CustomModal.Body>
        <div className="mb-4 text-center font-weight-bold">
          You have an active Internet session running.
        </div>
        <div className="my-2 text-center">
          Select <strong>Disconnect</strong> to end this session and log out, or
          select <strong>Cancel</strong> to continue using the Internet.
        </div>
        <div className="px-2 pt-4 d-flex justify-content-center">
          <Button
            variant="link"
            className="text-capitalize mx-4"
            onClick={() => onHide(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="mx-4 text-black"
            style={{
              backgroundColor: '#FFB000',
              borderColor: '#FFB000',
            }}
            disabled={isDisabled}
            onClick={disconnect}
          >
            Disconnect
          </Button>
        </div>
        {isDisabled && (
          <div
            className="px-2 pt-4 d-flex justify-content-center"
            style={{ height: '44px' }}
          >
            <Spinner />
          </div>
        )}
      </CustomModal.Body>
    </CustomModal>
  );
};

DisconnectModal.propTypes = {
  modal: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
};

export default DisconnectModal;
