import { ExternalMsgs } from '../utility/StaticTexts';

// Calls a GET API to get a paginated set of data. If no more data
// is available or needed, returns that data. Otherwise sends another
// request for the next set of data and merges the results.
//
// Returns null if an API call was unsuccessful.
const getPaginatedResults = async (
  apiGetRequest,
  context,
  setContext,
  offset = 0,
  recordsPerPage = 100,
  maxRecords = null,
  queryParams = [],
  onError = () => {},
) => {
  let result = null;
  let hasMoreData = false;
  let isResponseOkay = false;
  let responseData = null;

  const paginatedQueryParams = [
    queryParams.length > 0
      ? [...queryParams, `&limit=${recordsPerPage}&offset=${offset}`].join('')
      : `?limit=${recordsPerPage}&offset=${offset}`,
  ];

  // Runs a single query to get 1 page of records.
  await apiGetRequest(context.accessToken, paginatedQueryParams)
    .then((response) => {
      if (response?.status === 200) {
        // Set appropriate flags and capture records.
        isResponseOkay = true;
        responseData = response.data.data;

        // Cap data returned if needed, or flag that more data is available if needed.
        if (maxRecords != null && responseData.length > maxRecords) {
          responseData = response.data.data.slice(maxRecords);
        } else if (response.data.totalRecords > offset + recordsPerPage) {
          hasMoreData =
            maxRecords == null || maxRecords - responseData.length > 0;
        }
      } else {
        onError();
      }
    })
    .catch((error) => {
      if (
        error?.response?.data?.errorMessage ===
        ExternalMsgs.django_token_not_valid
      ) {
        // If invalid token, log out the user for security.
        setContext({ forceLogOut: true });
      } else {
        onError();
      }
    });

  if (isResponseOkay && hasMoreData) {
    const remainingData = await getPaginatedResults(
      apiGetRequest,
      context,
      setContext,
      offset + recordsPerPage,
      recordsPerPage,
      maxRecords != null ? maxRecords - responseData.length : null,
      queryParams,
      onError,
    );
    if (remainingData != null) {
      result = [...responseData, ...remainingData];
    }
    // Else result will remain null since paginated result failed.
  } else {
    // Set result to the current response data, or null if not set.
    result = responseData;
  }

  return result;
};

// Gets a full set of data from successive paginated API calls.
// Returns null if unable to get all requested data.
//
// Assumes the following about the given GET API call:
//   - It accepts the query parameters:
//       `?limit=${recordsPerPage}`
//       `?offset=${offset}`
//   - The API response status code is 200 when successful.
//   - The API response data has the following format:
//     {
//        totalRecords: 205   <-- An integer >= 0.
//        data: [...]         <-- An array of data objects w/ similar structure.
//     }
export const callPaginatedGetApi = async (
  apiGetRequest,
  context,
  setContext,
  startOffset = 0,
  recordsPerPage = 100,
  maxRecords = null,
  queryParams = [],
  onError = () => {},
) => {
  let paginatedResults = null;
  try {
    paginatedResults = await getPaginatedResults(
      apiGetRequest,
      context,
      setContext,
      startOffset,
      recordsPerPage,
      maxRecords,
      queryParams,
      onError,
    );
  } catch (error) {
    onError();
  }
  return paginatedResults;
};
