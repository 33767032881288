import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { matchProfile } from '../../utility/HelperFunctions';

import styles from './print-voucher.module.css';
import PrintVoucher from './PrintVoucher';

const PrintVoucherGrid = ({
  voucherData = [],
  profileData = [],
  selectedRows = [],
  showAllVouchers = false,
}) => {
  const [combinedData, setCombinedData] = useState([]);
  const { printVoucherContainer } = styles;

  useEffect(() => {
    if (voucherData.length > 0 && profileData.length > 0) {
      setCombinedData(
        voucherData.map((user) => {
          return {
            ...user,
            profileData: matchProfile(user.allocationBundleName, profileData),
          };
        }),
      );
    }
  }, [voucherData, profileData]);

  return (
    <div id="printVoucherPage" className={printVoucherContainer}>
      <div className="d-flex flex-wrap">
        {combinedData.map((row, i) => {
          return (
            <div
              style={{
                display:
                  showAllVouchers || selectedRows.includes(i) ? 'flex' : 'none',
              }}
              key={row.username}
            >
              <div id={`printVoucher__${row.username}`} className="mb-3 mr-3">
                <PrintVoucher
                  voucherData={row}
                  profileData={matchProfile(
                    row?.allocationBundleName,
                    profileData,
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PrintVoucherGrid.propTypes = {
  voucherData: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      pin: PropTypes.string.isRequired,
      allocationBundleName: PropTypes.string.isRequired,
    }),
  ),
  profileData: PropTypes.arrayOf(
    PropTypes.shape({
      wanUsageLimits: PropTypes.arrayOf(
        PropTypes.shape({
          wanName: PropTypes.string.isRequired,
          allowedBytes: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ),
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  showAllVouchers: PropTypes.bool,
};

export default PrintVoucherGrid;
