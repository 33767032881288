import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { getCbxStatus } from './services/ContextService';
import {
  deleteUserSession,
  getCrewSession,
} from './services/InternetSessionService';
import { MIN_SESSION_BYTES, MIN_SESSION_SECS } from './utility/Constants';
import { isGuestUser, isVtidValid } from './utility/HelperFunctions';
import { ExternalMsgs, InternetAccessMsgs } from './utility/StaticTexts';

const StateContext = React.createContext();

// TODO: Restructure context into more meaningful format.
export const defaultStateContext = {
  acceptedTerms: false,
  isOnline: false,
  isConnected: false,
  isLoggedIn: false,
  forceLogOut: false,
  wanName: '',
  accessToken: '',
  onshoreStartToken: '',
  username: '',
  vtid: '',
  sessionUUID: '',
  sessionStart: 0,
  sessionEnd: 0,
  sessionLength: 0,
  bytesUsed: 0,
  remainingBytes: 0,
  remainingVoucherBytes: 0,
  remainingSecs: 0,
  terminalModelType: '',
  sessionIntervalID: null,
  userRole: '',
};

const StateContextProvider = ({ children }) => {
  const getContext = () => {
    try {
      const c = localStorage.getItem('context');
      if (c) {
        return JSON.parse(c);
      }
    } catch (error) {}
    return defaultStateContext;
  };
  const [_context, _setContext] = useState(getContext);

  const setContext = (c) => {
    try {
      const prev = getContext();
      localStorage.setItem('context', JSON.stringify({ ...prev, ...c }));
      _setContext((_prev) => ({ ..._prev, ...c }));
    } catch (error) {}
  };

  const contextPair = {
    context: _context,
    setContext,
  };

  const memoizedContext = useMemo(() => contextPair, [contextPair]);

  const updateTerminalInfo = (isOnline, wanName, terminalModelType) => {
    setContext({ isOnline, wanName, terminalModelType });
  };

  const updateConnectionStatus = (isConnected, sessionStart, bytesUsed) => {
    setContext({ isConnected, sessionStart, bytesUsed });
  };

  const handleStopSessionOnshore = (crewData, wanChanged) => {
    const currentContext = getContext();

    const isOldSession = currentContext.sessionStart <= 0;

    // Stop session onshore, but ignore response.
    const userSessionData = {
      bytesIn: crewData?.bytes_in,
      bytesOut: crewData?.bytes_out,
    };
    try {
      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = isVtidValid(currentContext.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      // Do not interfere with delete session request if vtid mismatch occurs.
      deleteUserSession(currentContext.accessToken, userSessionData).catch(
        (error) => {
          if (
            error?.response?.data?.errorMessage ===
            ExternalMsgs.django_token_not_valid
          ) {
            // If invalid token, log out the user for security.
            setContext({ forceLogOut: true });
          }
          // eslint-disable-next-line no-console
          console.log(error);
        },
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    // Stop any existing intervals or timeouts.
    clearInterval(currentContext.sessionIntervalID);

    // Reset session-related context variables to their defaults.
    setContext({
      isConnected: false,
      onshoreStartToken: '',
      sessionUUID: '',
      sessionStart: 0,
      sessionEnd: 0,
      sessionLength: 0,
      sessionIntervalID: null,
      bytesUsed: 0,
    });

    // Reduce remaining seconds and bytes based on usage,
    // if WAN did not change and not unlimited usage.
    if (!wanChanged) {
      // Track time remaining.
      let remainingSecs = null;
      if (currentContext.remainingSecs != null && crewData?.duration != null) {
        remainingSecs = currentContext.remainingSecs - crewData.duration;
        setContext({ remainingSecs });
      }

      // Track data remaining.
      let remainingBytes = null;
      let { remainingVoucherBytes } = currentContext;
      if (
        currentContext.remainingBytes != null &&
        crewData?.bytes_in != null &&
        crewData?.bytes_out != null
      ) {
        remainingBytes =
          currentContext.remainingBytes -
          (crewData.bytes_in + crewData.bytes_out);
        if (remainingBytes < 0 && currentContext.remainingVoucherBytes > 0) {
          const voucherUsageBytes = -remainingBytes;
          remainingVoucherBytes -= voucherUsageBytes;
          remainingBytes = 0;
        }
        setContext({ remainingBytes, remainingVoucherBytes });
      }

      const noTimeRemaining =
        !isGuestUser(currentContext) &&
        remainingSecs != null &&
        remainingSecs < MIN_SESSION_SECS;
      const noDataRemaining =
        remainingBytes != null &&
        remainingBytes + remainingVoucherBytes < MIN_SESSION_BYTES;

      // Display a toast message on completion.
      if (isOldSession) {
        toast.success(InternetAccessMsgs.session_end_timeout, {
          className: 'bg-success',
        });
      } else if (noTimeRemaining) {
        toast.error(InternetAccessMsgs.session_end_no_time);
      } else if (noDataRemaining) {
        toast.error(InternetAccessMsgs.session_end_no_data);
      } else {
        toast.success(InternetAccessMsgs.session_end_success, {
          className: 'bg-success',
        });
      }
    } else {
      // Display toast for unexpected session stop due to WAN change.
      toast.error(InternetAccessMsgs.session_end_wan_switch);
    }
  };

  const checkStatus = async () => {
    const currentContext = getContext();

    let wanChanged = false;

    // NOTE: make sure combined timeouts are less than the interval otherwise memory leak
    if (currentContext.isLoggedIn) {
      try {
        // Get information about the terminal, if available.
        // NOTE: Default timeout is 1000ms.
        await getCbxStatus()
          .then((cbxResponse) => {
            const stateName = cbxResponse.data?.connection?.state?.name;
            const wanName = cbxResponse.data?.connection?.gateway?.device;
            const model = cbxResponse.data?.terminal?.antennaModel;
            const type = cbxResponse.data?.terminal?.bdu_ce_type;

            if (wanName !== currentContext.wanName && wanName != null) {
              wanChanged = true;
            }

            // Update fields only if all were found in response.
            if (stateName && wanName && model && type) {
              // TODO: Choose one to be returned by the API for consistency.
              const isOnline = ['connected', 'online'].includes(
                stateName?.toLowerCase(),
              );
              let terminalModelType = '';
              if (model !== undefined && type !== undefined) {
                terminalModelType = `${model}_${type.substring(
                  0,
                  type.indexOf('-'),
                )}`;
              }
              updateTerminalInfo(isOnline, wanName, terminalModelType);
            } else {
              // eslint-disable-next-line no-console
              console.log(`Missing response fields: ${cbxResponse}`);
            }
          })
          .catch((_error) => {
            updateTerminalInfo(
              false,
              currentContext.wanName,
              currentContext.terminalModelType,
            );
          });
      } catch (error) {
        updateTerminalInfo(
          false,
          currentContext.wanName,
          currentContext.terminalModelType,
        );
      }
    } else {
      updateTerminalInfo(
        false,
        currentContext.wanName,
        currentContext.terminalModelType,
      );
    }

    // Get information about any existing sessions.
    if (
      currentContext.isLoggedIn &&
      currentContext.onshoreStartToken != null &&
      currentContext.onshoreStartToken !== '' &&
      currentContext.sessionUUID != null &&
      currentContext.sessionUUID !== ''
    ) {
      try {
        // NOTE: Default timeout is 1000ms.
        await getCrewSession(
          currentContext.onshoreStartToken,
          currentContext.sessionUUID,
        )
          .then((crewResponse) => {
            // Track byte usage.
            let bytesUsed = 0;
            if (
              crewResponse?.data?.bytes_in != null &&
              crewResponse?.data?.bytes_out != null
            ) {
              bytesUsed =
                crewResponse.data.bytes_in + crewResponse.data.bytes_out;
            }

            // TODO: Define status codes more clearly.
            if (crewResponse?.data?.status < 5) {
              if (currentContext.sessionStart <= 0) {
                // Display a success toast to indicate that session resumed.
                toast.success(InternetAccessMsgs.session_resume_success, {
                  className: 'bg-success',
                });
              }
              updateConnectionStatus(
                true,
                crewResponse?.data?.started_at,
                bytesUsed,
              );
            } else {
              handleStopSessionOnshore(crewResponse?.data, wanChanged);
            }
          })
          .catch((_error) => {
            updateConnectionStatus(false, currentContext.sessionStart, 0);
          });
      } catch (error) {
        updateConnectionStatus(false, currentContext.sessionStart, 0);
      }
    }
  };

  // Check status every 3 seconds.
  useEffect(() => {
    const interval = setInterval(checkStatus, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StateContext.Provider value={memoizedContext}>
      {children}
    </StateContext.Provider>
  );
};

StateContextProvider.propTypes = {
  children: PropTypes.node,
};

export { StateContextProvider, StateContext };
