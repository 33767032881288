import classNames from 'class-names';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';

import styles from './content-box.module.css';

const ContentBox = ({
  children,
  className = styles.contentbox,
  heading = '',
  htmlHeading,
  isLogin = false,
}) => {
  return (
    <div className={classNames('page-wrapper', !isLogin && 'mx-lg-5')}>
      <Container fluid className={className}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          theme="colored"
          hideProgressBar
          closeOnClick
        />
        {htmlHeading || <header className="h5 d-flex mb-4">{heading}</header>}
        {children}
      </Container>
    </div>
  );
};

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  heading: PropTypes.string,
  htmlHeading: PropTypes.node,
  isLogin: PropTypes.bool,
};

export default ContentBox;
