import { mockDelay } from './mockDelay';

export const getUserSummary = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          uuid: 'fb0bd2c4-46bb-4598-8ae3-322ac1555f84',
          createdAt: '2022-12-15T11:27:23.530217Z',
          updatedAt: '2022-12-28T13:28:00.592140Z',
          name: 'u1',
          username: 'u1',
          enabled: true,
          temporaryPassword: '',
          passwordStatus: 'password_set',
          fleetIdentifier: 'Loopers Fleet',
          lastLogin: '2022-12-28T13:28:00.592028Z',
          wanAllocations: [
            {
              uuid: 'b9302f16-4e2e-4ba6-ab75-5c0add229994',
              createdAt: '2022-12-20T14:08:54.509849Z',
              updatedAt: '2022-12-20T14:08:54.509863Z',
              name: 'profile 2',
              allowedBytes: 150000,
              allocationPeriod: 'week',
              dailyLimitSecs: 8765,
              wanName: 'vsat',
              totalUsageBytes: 0,
              totalUsageTodaySecs: 0,
            },
            {
              uuid: 'a30e2882-af8a-495d-8b9c-2eadfda8428b',
              createdAt: '2022-12-15T11:26:30.293554Z',
              updatedAt: '2022-12-15T11:26:30.293568Z',
              name: 'a1',
              allowedBytes: 999999,
              allocationPeriod: 'week',
              dailyLimitSecs: 300,
              wanName: 'cell0',
              totalUsageBytes: 0,
              totalUsageTodaySecs: 0,
            },
          ],
          voucherAllocations: [],
        }
      : null,
    status: success ? 200 : 504,
  };
};
