import { isServiceMocked } from './isServiceMocked';
import * as mockService from './mock/MockVouchersService';
import { axios2 } from './ServiceClients';
import ServiceUrls from './ServiceURLs';

export const getProfileBundles = (
  accessToken,
  queryParams = [],
  timeoutMs = 60000,
) => {
  if (isServiceMocked('vouchers', 'getProfileBundles')) {
    return mockService.getProfileBundles();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_PROFILE_BUNDLES,
    'GET',
    null,
    queryParams,
    headers,
    timeoutMs,
  );
};

export const getCrewUsers = (
  accessToken,
  queryParams = [],
  timeoutMs = 60000,
) => {
  if (isServiceMocked('vouchers', 'getCrewUsers')) {
    return mockService.getCrewUsers();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_CREW_USERS,
    'GET',
    null,
    queryParams,
    headers,
    timeoutMs,
  );
};

export const getGuestVouchers = (
  accessToken,
  queryParams = [],
  timeoutMs = 60000,
) => {
  if (isServiceMocked('vouchers', 'getGuestVouchers')) {
    return mockService.getGuestVouchers();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_GUEST_VOUCHERS,
    'GET',
    null,
    queryParams,
    headers,
    timeoutMs,
  );
};

export const postGuestVouchers = (
  accessToken,
  voucherInfo,
  timeoutMs = 60000,
) => {
  if (isServiceMocked('vouchers', 'postGuestVouchers')) {
    return mockService.postGuestVouchers();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_GUEST_VOUCHERS,
    'POST',
    voucherInfo,
    null,
    headers,
    timeoutMs,
  );
};

export const getUserVouchers = (
  accessToken,
  queryParams = [],
  timeoutMs = 60000,
) => {
  if (isServiceMocked('vouchers', 'getUserVouchers')) {
    return mockService.getUserVouchers();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_USER_VOUCHERS,
    'GET',
    null,
    queryParams,
    headers,
    timeoutMs,
  );
};

export const postUserVouchers = (
  accessToken,
  voucherInfo,
  timeoutMs = 60000,
) => {
  if (isServiceMocked('vouchers', 'postUserVouchers')) {
    return mockService.postUserVouchers();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_USER_VOUCHERS,
    'POST',
    voucherInfo,
    null,
    headers,
    timeoutMs,
  );
};
