import jwt_decode from 'jwt-decode';

import { getUserOnboardToken } from '../services/AuthService';

import { Roles } from './StaticTexts';

/*
Helper functions.
*/

// Returns date in the format DD Mon YYYY HH:MM UTC
export const convertDate = (date, showUTC = true) => {
  const newDate = new Date(date);
  const formattedDate = newDate
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
      timeZoneName: 'short',
    })
    .replace(',', ' ');
  if (!showUTC) {
    return formattedDate.slice(0, -4);
  }
  return formattedDate;
};

// Returns date in the format YYYY-MM-DD hh:mm:ss, or an empty string if no data.
export const convertCsvDate = (date) => {
  const newDate = new Date(date);
  const formattedDate = newDate.toISOString().replace('T', ' ').split('.')[0];
  return date ? formattedDate : '';
};

// Converts seconds to Days HH:MM:SS format.
export const convertDuration = (seconds) => {
  const formattedDuration = new Date(seconds * 1000)
    .toISOString()
    .slice(11, 19);
  if (seconds < 86400) {
    return formattedDuration;
  }
  const days = Math.floor(seconds / 86400);
  if (days === 1) {
    return `${days} day ${formattedDuration}`;
  }
  return `${days} days ${formattedDuration}`;
};

// Converts bytes into KB, MB, and so on.
export const formatBytes = (bytes, defaultMsg = '0 Bytes') => {
  if (!+bytes || +bytes < 1) return defaultMsg;

  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
};

// Capitalizes the string, or just the first letter if specified.
export const capitalize = (word, firstLetter = false) => {
  let newWord = word;
  if (firstLetter) {
    newWord =
      word.length > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word;
  } else {
    newWord = word.toUpperCase();
  }
  return newWord;
};

// Converts HH:MM:SS to seconds.
export const convertHMS = (timeString) => {
  const arr = timeString.split(':');
  const seconds = arr[0] * 3600 + arr[1] * 60 + arr[2];
  return seconds;
};

// Formats the given WAN name, or just returns the WAN name if no formatting needed.
export const formatWanConnectionName = (name) => {
  const nameMap = {
    cell: 'Cell',
    cell0: 'Cell',
    wifi: 'Wi-Fi',
    wifi0: 'Wi-Fi',
    vsat: 'VSAT',
    alternate: 'Alternate',
    backup: 'Backup',
  };
  return nameMap[name] ?? name;
};

// Returns the value of the given field in the access token, or null if no such field exists.
export const getJWTField = (token, field) => {
  let result = null;
  try {
    const t = jwt_decode(token);
    result = t[field];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  return result;
};

// HACK: Validates vtid by retrieving the current JWT token for the terminal
//       and comparing to the vtid in context.
// TODO: Have /login API return vtid info and extract from that API so that
//       this HACK becomes unnecessary.
export const isVtidValid = async (contextVtid) => {
  let vtidsMatch = false;
  try {
    await getUserOnboardToken()
      .then((onboardResponse) => {
        // Extracts the vtid from the JWT token:
        const jwtVtid = getJWTField(onboardResponse.data.token, 'vtid');
        vtidsMatch =
          jwtVtid != null && jwtVtid !== '' && jwtVtid === contextVtid;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error?.response);
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    vtidsMatch = false;
  }

  return vtidsMatch;
};
// END HACK

// Returns true if the user is a guest, and false otherwise.
export const isGuestUser = (context) => {
  return context.userRole === Roles.guest;
};

// Returns true if the user is a standard crew member, and false otherwise.
export const isCrewUser = (context) => {
  return context.userRole === Roles.crew;
};

// Returns true if the user is an onboard manager, and false otherwise.
export const isOnboardManager = (context) => {
  return context.userRole === Roles.ob_manager;
};

// Gets the voucher profile matching the given name from an array of profiles.
export const matchProfile = (profileName, profileData) => {
  let result = {};
  let foundMatch = false;
  profileData.forEach((profile) => {
    if (!foundMatch && profile.name === profileName) {
      foundMatch = true;
      result = profile;
    }
  });
  return result;
};
