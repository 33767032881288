import { Icon, useSmallScreen } from '@hvk/react-components';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { StateContext, defaultStateContext } from '../../StateContext';
import { isGuestUser, isOnboardManager } from '../../utility/HelperFunctions';
import {
  accountSummaryRoute,
  guestVouchersRoute,
  privacyPolicyRoute,
  rootRoute,
  termsOfUseRoute,
  usageLogRoute,
  userVouchersRoute,
} from '../../utility/routes';
import { ErrorMsgs } from '../../utility/StaticTexts';
import DisconnectModal from '../modals/DisconnectModal';

import ConnectionBadge from './ConnectionBadge';
import HamburgerMenu from './Hamburger';
import HelpButton from './HelpButton';
import KvhLogo from './KvhLogo';

const InternetIcon = () => {
  return (
    <BootstrapNavbar.Brand className="mx-3">
      <Icon
        name="internet-outline"
        style={{
          height: '30px',
          width: '30px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      />
    </BootstrapNavbar.Brand>
  );
};

const TopNavBar = () => {
  const { context, setContext } = useContext(StateContext);
  const navigate = useNavigate();

  const isGuest = isGuestUser(context);
  const isManager = isOnboardManager(context);

  const [clicked, setClicked] = useState(false);
  const isSmallScreen = useSmallScreen('sm');
  const isMediumSize = useSmallScreen('md');

  useEffect(() => {
    // Set default background color based on window size. This allows
    // mobile views to appear seamless with the bottom of the screen.
    document.body.style.backgroundColor = isMediumSize ? '#FFFFFF' : '#F8F7F8';
  }, [isMediumSize]);

  const logOut = (e) => {
    if (e != null) {
      e.preventDefault();
    }
    const newContext = defaultStateContext;
    newContext.acceptedTerms = context.acceptedTerms;
    setContext(newContext);
    navigate(rootRoute);
  };

  useEffect(() => {
    if (context.forceLogOut === true) {
      // If invalid token, log out the user for security.
      setTimeout(() => {
        toast.error(ErrorMsgs.logout_error);
      }, 100);
      logOut();
    }
  }, [context.forceLogOut]);

  const [disconnectModal, setDisconnectModal] = useState(false);

  const openDisconnectModal = () => {
    setDisconnectModal(true);
  };

  const onHideDisconnectModal = (showModal) => {
    setDisconnectModal(showModal);
  };

  const handleLogOut = (e) => {
    if (context.sessionStart > 0) {
      openDisconnectModal();
    } else {
      logOut(e);
    }
  };

  const getTitle = () => {
    return (
      <h4 className="mb-0" style={{ fontWeight: '100' }}>
        KVH Crew Internet
      </h4>
    );
  };

  return (
    <BootstrapNavbar
      variant="dark"
      style={{ backgroundColor: '#254850', position: 'sticky' }}
      sticky="top"
      className="p-0"
      id="nav-bar"
    >
      {isSmallScreen ? (
        <>
          {!context.isLoggedIn && <InternetIcon />}
          {context.isLoggedIn && (
            <BootstrapNavbar.Brand
              className="mx-3"
              onClick={() => {
                setClicked(!clicked);
              }}
            >
              {!clicked ? (
                <span>
                  <Icon
                    name="menu-bars"
                    style={{
                      height: '30px',
                      width: '30px',
                      marginTop: '15px',
                      marginBottom: '15px',
                    }}
                  />
                </span>
              ) : (
                <HamburgerMenu clicked handleLogOut={handleLogOut} />
              )}
            </BootstrapNavbar.Brand>
          )}

          <BootstrapNavbar.Brand className="px-2">
            {getTitle()}
          </BootstrapNavbar.Brand>
        </>
      ) : (
        <>
          <InternetIcon />
          <BootstrapNavbar.Brand className="px-2">
            {getTitle()}
          </BootstrapNavbar.Brand>
          <BootstrapNavbar.Collapse className="justify-content-end">
            {context.isLoggedIn && (
              <>
                <div className="px-4">
                  <ConnectionBadge />
                </div>
                <HelpButton />
                <NavDropdown
                  className="nav-dropdown pr-4"
                  title={
                    <Badge
                      style={{
                        fontSize: '16px',
                        color: '#08C945',
                        backgroundColor: '#00000040',
                        padding: '1px 30px 1px 0px',
                        margin: '0px -25px 0px 0px',
                      }}
                    >
                      <div
                        className="text-white font-weight-bold d-inline-flex align-items-center"
                        style={{
                          padding: '5px',
                        }}
                      >
                        <span className="icon mr-2">
                          <Icon
                            name="user-circle-outline"
                            style={{ height: '30px', width: '30px' }}
                          />
                        </span>
                        <span>{isGuest ? 'Guest User' : context.username}</span>
                      </div>
                    </Badge>
                  }
                  id="navbarScrollingDropdown"
                  alignRight
                >
                  <NavDropdown.Item
                    onClick={() => {
                      navigate(rootRoute);
                    }}
                    style={{ width: '230px' }}
                  >
                    Internet Access
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate(accountSummaryRoute);
                    }}
                  >
                    Account Summary
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate(usageLogRoute);
                    }}
                  >
                    Usage Log
                  </NavDropdown.Item>
                  {isManager && (
                    <>
                      <NavDropdown.Item
                        onClick={() => {
                          navigate(guestVouchersRoute);
                        }}
                      >
                        Guest Vouchers
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          navigate(userVouchersRoute);
                        }}
                      >
                        User Vouchers
                      </NavDropdown.Item>
                    </>
                  )}
                  <div
                    style={{
                      borderTop: '1px solid #00000029',
                      width: '80%',
                      margin: '10px auto',
                    }}
                  />
                  <NavDropdown.Item
                    onClick={() => {
                      navigate(privacyPolicyRoute);
                    }}
                  >
                    Privacy Policy
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate(termsOfUseRoute);
                    }}
                  >
                    Terms of Use
                  </NavDropdown.Item>
                  <div
                    style={{
                      borderTop: '1px solid #00000029',
                      width: '80%',
                      margin: '10px auto',
                    }}
                  />
                  <NavDropdown.Item onClick={handleLogOut}>
                    Sign Out
                  </NavDropdown.Item>
                  <NavDropdown.ItemText className="pt-1 pb-3 text-center">
                    <KvhLogo />
                    <div style={{ fontSize: '12px', color: '#ADB5BD' }}>
                      &copy; {format(new Date(), 'yyyy')} KVH Industries, Inc.
                    </div>
                    <div style={{ fontSize: '12px', color: '#ADB5BD' }}>
                      All rights reserved.
                    </div>
                  </NavDropdown.ItemText>
                </NavDropdown>
              </>
            )}
          </BootstrapNavbar.Collapse>
        </>
      )}
      {disconnectModal && (
        <DisconnectModal
          modal={disconnectModal}
          onHide={onHideDisconnectModal}
          onDisconnect={(e) => logOut(e)}
        />
      )}
    </BootstrapNavbar>
  );
};

export default TopNavBar;
