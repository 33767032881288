import { isServiceMocked } from './isServiceMocked';
import * as mockService from './mock/MockAuthService';
import { axios2 } from './ServiceClients';
import ServiceUrls from './ServiceURLs';

export const getUserOnboardToken = (timeoutMs = 10000) => {
  if (isServiceMocked('auth', 'getUserOnboardToken')) {
    return mockService.getUserOnboardToken();
  }

  return axios2(
    ServiceUrls.ONBOARD_FW_TOKEN,
    'GET',
    null,
    null,
    null,
    timeoutMs,
  );
};

export const getAccessToken = (
  onboardGenToken,
  loginInfo,
  timeoutMs = 60000,
) => {
  if (isServiceMocked('auth', 'getAccessToken')) {
    return mockService.getAccessToken();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${onboardGenToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_LOGIN,
    'POST',
    loginInfo,
    null,
    headers,
    timeoutMs,
  );
};

export const setPassword = (
  onboardGenToken,
  setPasswordInfo,
  timeoutMs = 60000,
) => {
  if (isServiceMocked('auth', 'setPassword')) {
    return mockService.setPassword();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${onboardGenToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_PASSWORD_RESET,
    'POST',
    setPasswordInfo,
    null,
    headers,
    timeoutMs,
  );
};
