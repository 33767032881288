import { mockDelay } from './mockDelay';

export const getUserOnboardToken = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          token:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjYzMzk4NjcsImlhdCI6MTY5NDcxNzQ2NywiaXBfYWRkciI6IjEyNy4wLjAuMSIsInZ0aWQiOiI5OTk5OTk5MyIsIndhbl9uYW1lIjoiY2VsbDAifQ.02rEHn2WJp6S_Ubw4EAniZmn4YCT_qBcfbXbUPRgUgU',
          // {
          //   "iat": 1694717467,
          //   "exp": 1726339867,
          //   "ip_addr": "127.0.0.1",
          //   "vtid": "99999993",
          //   "wan_name": "cell0"
          // }
        }
      : null,
    status: success ? 200 : 504,
  };
};

export const getAccessToken = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          accessToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk1NDA4MzE2LCJpYXQiOjE2OTUzMjE5MTYsImp0aSI6ImRmZjE3YTY1YTQ0NzQxOWZhY2VhOTAyOGI2ZDE3MmUzIiwidXNlcl9pZCI6ImZiMGJkMmM0LTQ2YmItNDU5OC04YWUzLTMyMmFjMTU1NWY4NCIsInVzZXJuYW1lIjoidTEiLCJ2dGlkIjoiOTk5OTk5OTMiLCJpcF9hZGRyIjoiMTkyLjE2OC41LjUxIiwidXNlcl9yb2xlIjoiY3JldyJ9.O4BFRVaW2ew-xysEgbWHUBJnBnjgY5UnSG_coDrY6ek',
          // {
          //   "token_type": "access",
          //   "exp": 1695408316,
          //   "iat": 1695321916,
          //   "jti": "dff17a65a447419facea9028b6d172e3",
          //   "user_id": "fb0bd2c4-46bb-4598-8ae3-322ac1555f84",
          //   "username": "u1",
          //   "vtid": "99999993",
          //   "ip_addr": "192.168.5.51",
          //   "user_role": "crew"
          // }
        }
      : null,
    status: success ? 200 : 504,
  };
};

export const setPassword = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          accessToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk1NDA4MzE2LCJpYXQiOjE2OTUzMjE5MTYsImp0aSI6ImRmZjE3YTY1YTQ0NzQxOWZhY2VhOTAyOGI2ZDE3MmUzIiwidXNlcl9pZCI6ImZiMGJkMmM0LTQ2YmItNDU5OC04YWUzLTMyMmFjMTU1NWY4NCIsInVzZXJuYW1lIjoidTEiLCJ2dGlkIjoiOTk5OTk5OTMiLCJpcF9hZGRyIjoiMTkyLjE2OC41LjUxIiwidXNlcl9yb2xlIjoiY3JldyJ9.O4BFRVaW2ew-xysEgbWHUBJnBnjgY5UnSG_coDrY6ek',
          // {
          //   "token_type": "access",
          //   "exp": 1695408316,
          //   "iat": 1695321916,
          //   "jti": "dff17a65a447419facea9028b6d172e3",
          //   "user_id": "fb0bd2c4-46bb-4598-8ae3-322ac1555f84",
          //   "username": "u1",
          //   "vtid": "99999993",
          //   "ip_addr": "192.168.5.51",
          //   "user_role": "crew"
          // }
        }
      : null,
    status: success ? 200 : 504,
  };
};
