import { Icon } from '@hvk/react-components';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { StateContext } from '../../StateContext';
import { isGuestUser, isOnboardManager } from '../../utility/HelperFunctions';
import {
  accountSummaryRoute,
  guestVouchersRoute,
  privacyPolicyRoute,
  rootRoute,
  termsOfUseRoute,
  usageLogRoute,
  userVouchersRoute,
} from '../../utility/routes';

import ConnectionBadge from './ConnectionBadge';
import { getHelpUrl } from './HelpButton';
import KvhLogo from './KvhLogo';

const HamburgerMenu = ({ clicked, handleLogOut }) => {
  const { context } = useContext(StateContext);
  const isGuest = isGuestUser(context);
  const isManager = isOnboardManager(context);

  return (
    <div>
      <ul
        style={{
          display: clicked ? 'block' : 'none',
          maxWidth: '340px',
          width: '90%',
          fontSize: '17px',
        }}
      >
        <li className="d-flex p-3" style={{ float: 'right' }}>
          <Icon
            name="multiply"
            style={{
              height: '16px',
              width: '16px',
              color: '#ADB5BD',
            }}
          />
        </li>
        <li className="d-flex p-3">
          <ConnectionBadge />
        </li>
        <li className="px-3 py-2">
          <span className="text-white">
            {isGuest ? 'Guest User' : context.username}
          </span>
        </li>
        <hr
          className="my-0 px-3"
          style={{
            border: '10px solid #343a41',
            backgroundColor: '#718190',
            height: '1px',
          }}
        />
        <li className="px-3 py-2">
          <Link style={{ color: '#ADB5BD' }} to={rootRoute}>
            Internet Access
          </Link>
        </li>
        <li className="px-3 py-2">
          <Link style={{ color: '#ADB5BD' }} to={accountSummaryRoute}>
            Account Summary
          </Link>
        </li>
        <li className="px-3 py-2">
          <Link style={{ color: '#ADB5BD' }} to={usageLogRoute}>
            Usage Log
          </Link>
        </li>
        {isManager && (
          <>
            <li className="px-3 py-2">
              <Link style={{ color: '#ADB5BD' }} to={guestVouchersRoute}>
                Guest Vouchers
              </Link>
            </li>
            <li className="px-3 py-2">
              <Link style={{ color: '#ADB5BD' }} to={userVouchersRoute}>
                User Vouchers
              </Link>
            </li>
          </>
        )}
        <li className="px-3 py-2">
          <a style={{ color: '#ADB5BD' }} href={getHelpUrl(context)}>
            Help
          </a>
        </li>
        <hr
          className="my-0 px-3"
          style={{
            border: '10px solid #343a41',
            backgroundColor: '#718190',
            height: '1px',
          }}
        />
        <li className="px-3 py-2">
          <Link style={{ color: '#ADB5BD' }} to={privacyPolicyRoute}>
            Privacy Policy
          </Link>
        </li>
        <li className="px-3 py-2">
          <Link style={{ color: '#ADB5BD' }} to={termsOfUseRoute}>
            Terms of Use
          </Link>
        </li>
        <hr
          className="my-0 px-3"
          style={{
            border: '10px solid #343a41',
            backgroundColor: '#718190',
            height: '1px',
          }}
        />
        <li className="px-3 pt-2">
          <Link
            style={{ color: '#ADB5BD' }}
            to={rootRoute}
            onClick={handleLogOut}
          >
            Sign Out
          </Link>
        </li>
        <li className="text-center py-5">
          <KvhLogo />
          <div style={{ fontSize: '14px', color: '#ADB5BD' }}>
            &copy; {format(new Date(), 'yyyy')} KVH Industries, Inc.
          </div>
          <div style={{ fontSize: '14px', color: '#ADB5BD' }}>
            All rights reserved.
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HamburgerMenu;

HamburgerMenu.propTypes = {
  clicked: PropTypes.bool,
  handleLogOut: PropTypes.func.isRequired,
};
