import classNames from 'class-names';
import PropTypes from 'prop-types';
import React from 'react';

import MobileTableRow from './MobileTableRow';

const MobileTable = ({
  data,
  rowKey,
  columnData,
  className = '',
  subTable = false,
  noHeaders = false,
}) => {
  return (
    <div>
      {data.map((rowData) => {
        return (
          <div
            key={rowData[rowKey]}
            className={classNames(
              'd-flex justify-content-center',
              !subTable && 'mx-n2',
              className,
            )}
          >
            <MobileTableRow
              data={rowData}
              columnData={columnData}
              subTable={subTable}
              noHeaders={noHeaders}
            />
          </div>
        );
      })}
    </div>
  );
};

MobileTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowKey: PropTypes.string.isRequired,
  columnData: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      className: PropTypes.string,
      format: PropTypes.func,
    }),
  ).isRequired,
  className: PropTypes.string,
  subTable: PropTypes.bool,
  noHeaders: PropTypes.bool,
};

export default MobileTable;
