import axios from 'axios';

import Config from '../config';

// axios2 calls axios given the SerivdeURLs baseKey which is used to lookup base URL in config
export const axios2 = (
  endpoint,
  method = 'GET',
  data = null,
  params = null,
  headers = null,
  timeout = null,
) => {
  const { baseKey, relative } = endpoint;
  const base = Config.endpoints[baseKey];
  let path = relative;
  if (params && params.length > 0) {
    if (!path.endsWith('/')) {
      path += '/';
    }
    // NOTE: could do better here, we could substitute in params
    path += params.join('/');
  }
  return axios.request({
    baseURL: base,
    url: path,
    method,
    data,
    headers,
    timeout,
  });
};
