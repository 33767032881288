import { isServiceMocked } from './isServiceMocked';
import * as mockService from './mock/MockInternetSessionService';
import { axios2 } from './ServiceClients';
import ServiceUrls from './ServiceURLs';

export const postCrewSession = (onshoreStartToken, timeoutMs = 10000) => {
  if (isServiceMocked('internetSession', 'postCrewSession')) {
    return mockService.postCrewSession();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${onshoreStartToken}`,
  };
  return axios2(
    ServiceUrls.ONBOARD_FW_CREW_SESSION,
    'POST',
    { data: onshoreStartToken },
    null,
    headers,
    timeoutMs,
  );
};

export const deleteCrewSession = (
  onshoreStartToken,
  sessionID,
  timeoutMs = 10000,
) => {
  if (isServiceMocked('internetSession', 'deleteCrewSession')) {
    return mockService.deleteCrewSession();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${onshoreStartToken}`,
  };
  return axios2(
    ServiceUrls.ONBOARD_FW_CREW_SESSION,
    'DELETE',
    null,
    [sessionID],
    headers,
    timeoutMs,
  );
};

export const getCrewSession = (
  onshoreStartToken,
  sessionID,
  closingSession = false,
  timeoutMs = 1000,
) => {
  if (isServiceMocked('internetSession', 'getCrewSession')) {
    return mockService.getCrewSession(closingSession);
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${onshoreStartToken}`,
  };
  return axios2(
    ServiceUrls.ONBOARD_FW_CREW_SESSION,
    'GET',
    null,
    [sessionID],
    headers,
    timeoutMs,
  );
};

export const postUserSession = (
  accessToken,
  sessionInfo,
  timeoutMs = 60000,
) => {
  if (isServiceMocked('internetSession', 'postUserSession')) {
    return mockService.postUserSession();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_SESSION,
    'POST',
    sessionInfo,
    null,
    headers,
    timeoutMs,
  );
};

export const deleteUserSession = (
  accessToken,
  sessionInfo,
  timeoutMs = 60000,
) => {
  if (isServiceMocked('internetSession', 'deleteUserSession')) {
    return mockService.deleteUserSession();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_SESSION,
    'DELETE',
    sessionInfo,
    null,
    headers,
    timeoutMs,
  );
};
