import { Button, CustomModal, useSmallScreen } from '@hvk/react-components';
import PropTypes from 'prop-types';
import React from 'react';

import PrintVoucherGrid from '../../container/GuestVouchers/PrintVoucherGrid';
import Spinner from '../Spinner/Spinner';

const PrintVoucherModal = ({
  modal,
  onHide,
  voucherData = [],
  profileData = [],
  selectedRows = [],
  showAllVouchers = false,
}) => {
  const isMediumScreen = useSmallScreen('md');

  const isSingleVoucher = selectedRows.length <= 1;
  const pluralLetter = isSingleVoucher ? '' : 's';

  const handlePrint = () => {
    // Get sprite map for icons.
    const spriteMap = window.document.getElementById('__SVG_SPRITE_NODE__');
    const voucherElement = window.document.getElementById('printVoucherPage');

    // Open new tab/window for print preview.
    const printWindow = window.open('_blank');

    // Add sprite map and print contents to page.
    printWindow.document.open();
    printWindow.document.write('<html><body>');
    printWindow.document.write(spriteMap.outerHTML);
    printWindow.document.write(voucherElement.outerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    // Copy all styles to new page.
    window.document.head
      .querySelectorAll('link, style')
      .forEach((htmlElement) => {
        printWindow.document.head.appendChild(htmlElement.cloneNode(true));
      });

    // Set background color to white.
    printWindow.document.body.style.backgroundColor = '#FFFFFF';

    // Set document title for tab view:
    printWindow.document.title = `Print Voucher${pluralLetter}`;

    // Print vouchers and close the new tab/window on completion.
    printWindow.print();
    printWindow.window.close();
  };

  return (
    <CustomModal
      modal={modal}
      onHide={onHide}
      size={isSingleVoucher ? 'md' : 'lg'}
    >
      <CustomModal.Header onHide={onHide} centered>
        Print Voucher{pluralLetter}
      </CustomModal.Header>
      <CustomModal.Body>
        <div className="mb-4 text-center font-weight-bold">
          The following voucher{pluralLetter} will be printed:
        </div>
        <div className="d-flex justify-content-center">
          <div
            className="mx-n4 pl-3 pt-3 border"
            style={{
              maxHeight: '480px',
              width: isMediumScreen || isSingleVoucher ? '334px' : '632px',
              overflow: 'auto',
            }}
          >
            <div className="d-flex justify-content-center">
              <PrintVoucherGrid
                voucherData={voucherData}
                profileData={profileData}
                selectedRows={selectedRows}
                showAllVouchers={showAllVouchers}
              />
            </div>
          </div>
        </div>
        <div className="pt-4 px-2 d-flex justify-content-center">
          <Button
            variant="link"
            className="text-capitalize mx-4"
            onClick={() => onHide(false)}
          >
            Cancel
          </Button>
          <Button type="submit" className="mx-4" onClick={handlePrint}>
            Print Voucher{pluralLetter}
          </Button>
        </div>

        {false && (
          <div
            className="px-2 pt-4 d-flex justify-content-center"
            style={{ height: '44px' }}
          >
            <Spinner />
          </div>
        )}
      </CustomModal.Body>
    </CustomModal>
  );
};

PrintVoucherModal.propTypes = {
  modal: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  voucherData: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      pin: PropTypes.string.isRequired,
      allocationBundleName: PropTypes.string.isRequired,
    }),
  ),
  profileData: PropTypes.arrayOf(
    PropTypes.shape({
      wanUsageLimits: PropTypes.arrayOf(
        PropTypes.shape({
          wanName: PropTypes.string.isRequired,
          allowedBytes: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ),
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  showAllVouchers: PropTypes.bool,
};

export default PrintVoucherModal;
