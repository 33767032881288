const Config = {
  endpoints: {
    apiBaseUrlOnshore: '/',
    apiBaseUrlOnboardFW: 'https://crew.kvhonboard.com',
    apiBaseUrlOnboardCbx: 'https://kvhonboard.com',
  },
  // Note: Do not mock services in production!
  //       Set as either true/false or a list of the methods to mock.
  // mockedServices: {
  //   auth: ['getUserOnboardToken'],
  //   internetSession: ['postCrewSession', 'deleteCrewSession', 'getCrewSession'],
  //   summary: false,
  //   usageLog: false,
  //   vouchers: false,
  // },
};

export default Config;
