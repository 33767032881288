import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

import {
  formatBytes,
  formatWanConnectionName,
} from '../../utility/HelperFunctions';

const SummaryTable = ({ wanAllocation, voucherAllocation, userContext }) => {
  const period = () => {
    switch (wanAllocation.allocationPeriod.toLowerCase()) {
      case 'day':
        return 'daily';
      case 'week':
        return 'weekly';
      case 'month':
        return 'monthly';
      default:
        return '';
    }
  };

  const dailyLimit =
    wanAllocation?.dailyLimitSecs != null
      ? `${Math.floor(wanAllocation.dailyLimitSecs / 60)} minutes`
      : 'Unlimited';

  const dataAllowance =
    wanAllocation?.allowedBytes != null
      ? `${formatBytes(wanAllocation.allowedBytes)} ${period()}`
      : 'Unlimited';

  const dataUsage = formatBytes(wanAllocation?.totalUsageBytes, '\u2014');
  const voucherAllowance = formatBytes(
    voucherAllocation?.allowedBytes,
    '\u2014',
  );
  const voucherUsage = formatBytes(
    voucherAllocation?.totalUsageBytes,
    '\u2014',
  );

  const valueClass = 'data-table-value py-2';
  const labelClass = 'data-table-label py-2 bold-text border-right';
  const selectedLabelClass = 'data-table-value py-2 bold-text text-white';

  return (
    <div>
      <div className="h6">{formatWanConnectionName(wanAllocation.wanName)}</div>
      <Table className="border m-0" style={{ fontSize: '14px' }}>
        <tbody>
          <tr>
            <td className={labelClass}>PROFILE</td>
            {userContext.wanName === wanAllocation.wanName ? (
              <td
                className={selectedLabelClass}
                style={{ backgroundColor: '#1a84c7' }}
              >
                {wanAllocation.name}
              </td>
            ) : (
              <td className={valueClass}>{wanAllocation.name}</td>
            )}
          </tr>
          <tr>
            <td className={labelClass}>DAILY LIMIT</td>
            <td className={valueClass}>{dailyLimit}</td>
          </tr>
          <tr>
            <td className={labelClass}>ALLOWANCE</td>
            <td className={valueClass}>{dataAllowance}</td>
          </tr>
          <tr>
            <td className={labelClass}>USAGE</td>
            <td className={valueClass}>{dataUsage}</td>
          </tr>
          <tr>
            <td className={labelClass}>VOUCHER ALLOWANCE</td>
            <td className={valueClass}>{voucherAllowance}</td>
          </tr>
          <tr>
            <td className={labelClass}>VOUCHER USAGE</td>
            <td className={valueClass}>{voucherUsage}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

SummaryTable.propTypes = {
  wanAllocation: PropTypes.shape({
    wanName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    allocationPeriod: PropTypes.string,
    dailyLimitSecs: PropTypes.number,
    allowedBytes: PropTypes.number,
    totalUsageBytes: PropTypes.number,
  }),
  voucherAllocation: PropTypes.shape({
    allowedBytes: PropTypes.number.isRequired,
    totalUsageBytes: PropTypes.number.isRequired,
  }),
  userContext: PropTypes.shape({
    wanName: PropTypes.string.isRequired,
  }),
};

export default SummaryTable;
