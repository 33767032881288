import { Icon } from '@hvk/react-components';
import React, { useContext } from 'react';

import { StateContext } from '../../StateContext';
import { onlineHelp } from '../../utility/routes/ExternalLinks';

export const getHelpUrl = (context) => {
  return `${onlineHelp}?f=Product:${context.terminalModelType}`;
};

const HelpButton = () => {
  const { context } = useContext(StateContext);

  return (
    <a href={getHelpUrl(context)} target="_blank" rel="noreferrer">
      <Icon
        name="help-thin"
        style={{ height: '30px', width: '30px', color: '#FFFFFF' }}
      />
    </a>
  );
};

export default HelpButton;
