import { Icon } from '@hvk/react-components';
import classNames from 'class-names';
import PropTypes from 'prop-types';
import React from 'react';

import {
  formatBytes,
  formatWanConnectionName,
} from '../../utility/HelperFunctions';

import styles from './print-voucher.module.css';

const KvhIcon = () => {
  return (
    <Icon
      name="kvh-logo"
      style={{ height: '29px', width: '94px', color: '#0C203C' }}
    />
  );
};

const PrintVoucher = ({ voucherData, profileData }) => {
  const {
    printVoucher,
    voucherHeader,
    voucherTitle,
    voucherTitleSm,
    voucherBody,
    voucherColTitle,
    voucherTable,
    voucherFooter,
  } = styles;

  if (voucherData == null) {
    // TODO: Toast message when no data?
    return <div />;
  }
  const { username, pin, allocationBundleName } = voucherData;
  const wanUsageLimits = profileData.wanUsageLimits.filter(
    (wan) => wan.allowedBytes > 0,
  );

  return (
    <div className={printVoucher} id="printerVoucher">
      <div className={voucherHeader}>
        Guest Voucher {'\u2013'} Internet Access
      </div>
      <div
        className={classNames(
          voucherTitle,
          allocationBundleName.length >= 20 && voucherTitleSm,
        )}
      >
        {allocationBundleName}
      </div>
      <div className={voucherBody}>
        <div className="d-flex px-1">
          <div className="w-50 pr-2">
            <div className={voucherColTitle}>Credentials</div>
            <table
              className={classNames(
                'table table-borderless table-sm',
                voucherTable,
              )}
            >
              <tbody>
                <tr>
                  <td className="py-0">Username:</td>
                  <td className="py-0">{username}</td>
                </tr>
                <tr>
                  <td className="py-0">PIN:</td>
                  <td className="py-0">{pin}</td>
                </tr>
              </tbody>
            </table>
            {wanUsageLimits.length > 3 && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '54px' }}
              >
                <KvhIcon />
              </div>
            )}
          </div>
          <div className="w-50 pl-2">
            <div className={voucherColTitle}>Allocated Data</div>
            <table
              className={classNames(
                'table table-borderless table-sm',
                voucherTable,
              )}
            >
              <tbody>
                {wanUsageLimits.map((wan) => {
                  return (
                    <tr key={wan.wanName}>
                      <td className="py-0">
                        {formatWanConnectionName(wan.wanName)}:
                      </td>
                      <td className="py-0 text-right">
                        {formatBytes(wan.allowedBytes)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {wanUsageLimits.length <= 3 && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: wanUsageLimits.length < 3 ? '54px' : '38px' }}
          >
            <KvhIcon />
          </div>
        )}
      </div>
      <div className={voucherFooter}>
        This voucher provides Internet access on board. You may use this voucher
        until your data runs out for each connection listed above.
      </div>
    </div>
  );
};

PrintVoucher.propTypes = {
  voucherData: PropTypes.shape({
    username: PropTypes.string.isRequired,
    pin: PropTypes.string.isRequired,
    allocationBundleName: PropTypes.string.isRequired,
  }),
  profileData: PropTypes.shape({
    wanUsageLimits: PropTypes.arrayOf(
      PropTypes.shape({
        wanName: PropTypes.string.isRequired,
        allowedBytes: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }),
};

export default PrintVoucher;
