import { Button, Icon, Slider, useSmallScreen } from '@hvk/react-components';
import classNames from 'class-names';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Spinner from '../../components/Spinner/Spinner';
import ContentBox from '../../layout/ContentBox';
import {
  startSession,
  stopSession,
} from '../../services/StartStopSessionService';
import { getUserSummary } from '../../services/SummaryService';
import { StateContext } from '../../StateContext';
import { MIN_SESSION_BYTES, MIN_SESSION_SECS } from '../../utility/Constants';
import {
  capitalize,
  formatBytes,
  isGuestUser,
  isVtidValid,
} from '../../utility/HelperFunctions';
import { ExternalMsgs, InternetAccessMsgs } from '../../utility/StaticTexts';

const states = {
  loading: 'Page Loading',
  startPressed: 'Start Session Button Pressed',
  stopPressed: 'Stop Session Button Pressed',
};

const StartStopSession = () => {
  const [isFirstGetSummary, setIsFirstGetSummary] = useState(true);
  const [allocationPeriod, setAllocationPeriod] = useState(null);
  const [remainingTimeout, setRemainingTimeout] = useState(0);
  const [sessionMins, setSessionMins] = useState(30);
  const [sessionState, setSessionState] = useState(states.loading);

  const { context, setContext } = useContext(StateContext);
  const isGuest = isGuestUser(context);

  const isExtraSmallScreen = useSmallScreen('xs');

  const handleManualStopSession = async () => {
    // HACK: Validates vtid by retrieving the current JWT token for the terminal
    //       and comparing to the vtid in context.
    const vtidsMatch = await isVtidValid(context.vtid);
    if (!vtidsMatch) {
      // If invalid token or vtid, log out the user for security.
      setContext({ forceLogOut: true });
    }
    // END HACK

    // Do not interfere with delete session request if vtid mismatch occurs.
    await stopSession(
      context.accessToken,
      context.onshoreStartToken,
      context.sessionUUID,
    )
      .then((results) => {
        if (results.success) {
          // Clear out session interval and reset session-related context.
          clearInterval(context.sessionIntervalID);
          setContext({
            isConnected: false,
            onshoreStartToken: '',
            sessionUUID: '',
            sessionStart: 0,
            sessionEnd: 0,
            sessionLength: 0,
            bytesUsed: 0,
            sessionIntervalID: null,
          });

          // If stopped while online, reduce time and data usage.
          if (context.isOnline) {
            // Reduce remaining seconds and bytes based on usage, if appropriate.
            if (context.remainingSecs != null && results.timeUsed != null) {
              setContext({
                remainingSecs: context.remainingSecs - results.timeUsed,
              });
            }
            let voucherBytesUsed = 0;
            if (context.remainingBytes != null && results.bytesUsed != null) {
              const remainingBytes = context.remainingBytes - results.bytesUsed;
              if (remainingBytes < 0) {
                voucherBytesUsed = -remainingBytes;
                setContext({ remainingBytes: 0 });
              } else {
                setContext({ remainingBytes });
              }
            }
            if (context.remainingVoucherBytes > 0 && voucherBytesUsed > 0) {
              setContext({
                remainingVoucherBytes:
                  context.remainingVoucherBytes - voucherBytesUsed,
              });
            }

            // Display a success toast on completion.
            toast.success(InternetAccessMsgs.session_end_success, {
              className: 'bg-success',
            });
          }
        } else {
          // If session not stopped successfully, still show session stopped on UI.
          // This makes it so that the user is not stuck, unable to stop the session.
          setContext({ sessionStart: 0 });
        }
      })
      .catch(() => {
        toast.error(InternetAccessMsgs.session_end_failure);
      });
  };

  const handleStartSession = async () => {
    // HACK: Validates vtid by retrieving the current JWT token for the terminal
    //       and comparing to the vtid in context.
    const vtidsMatch = await isVtidValid(context.vtid);
    if (!vtidsMatch) {
      // If invalid token or vtid, log out the user for security.
      setContext({ forceLogOut: true });
    }
    // END HACK

    if (vtidsMatch) {
      // Only start session if online and session length is > 0 mins.
      if (!context.isOnline) {
        toast.error(InternetAccessMsgs.no_internet);
      } else if (sessionMins < 1) {
        toast.error(InternetAccessMsgs.min_session_length);
      } else {
        let sessionTime = sessionMins * 60;
        if (
          context.remainingSecs != null &&
          context.remainingSecs >= MIN_SESSION_SECS
        ) {
          sessionTime = Math.min(sessionTime, context.remainingSecs);
        }
        await startSession(context.accessToken, sessionTime, context.wanName)
          .then(
            ({
              success,
              sessionUUID,
              onshoreStartToken,
              sessionStatus,
              logOutUser,
            }) => {
              // If start session was successful, set appropriate context fields.
              // Note: sessionIntervalID is set using an effect when sessionStart
              //       and sessionEnd are updated.
              if (success) {
                const now = Math.floor(Date.now() / 1000);
                const end = now + sessionMins * 60;
                setContext({
                  sessionUUID,
                  sessionStart: now,
                  sessionEnd: end,
                  sessionLength: sessionMins * 60,
                  bytesUsed: 0,
                  onshoreStartToken,
                  // TODO: Define status codes more clearly.
                  isConnected: sessionStatus >= 0 && sessionStatus < 5,
                });
                toast.success(InternetAccessMsgs.session_start_success, {
                  className: 'bg-success',
                });
              } else if (logOutUser) {
                // If invalid token, log out the user for security.
                setContext({ forceLogOut: true });
              }
            },
          )
          .catch(() => {
            toast.error(InternetAccessMsgs.session_start_failure);
          });
      }
    }
  };

  const handleButton = async () => {
    // If a button is pressed, run the appropriate method.
    if (sessionState === states.startPressed) {
      await handleStartSession();
    } else if (sessionState === states.stopPressed) {
      await handleManualStopSession();
    }

    // Reset after the method finishes, except for initial page load.
    if (sessionState !== states.loading) {
      setSessionState(null);
    }
  };

  useEffect(() => {
    handleButton();
  }, [sessionState]);

  const handleGetSummary = async () => {
    try {
      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = await isVtidValid(context.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      if (vtidsMatch) {
        // When the page is first loaded, get profile information.
        await getUserSummary(context.accessToken)
          .then((userSummary) => {
            // Update remaining bytes and seconds for WAN.
            const wanAllocations = userSummary?.data?.wanAllocations;
            let wanFound = false;
            if (userSummary.status === 200 && wanAllocations) {
              Object.values(wanAllocations).forEach((a) => {
                if (a.wanName === context.wanName) {
                  let remainingBytes = null;
                  if (a?.allowedBytes !== null) {
                    remainingBytes = a.allowedBytes - a.totalUsageBytes;
                  }
                  let remainingSecs = null;
                  if (a?.dailyLimitSecs !== null) {
                    remainingSecs = a.dailyLimitSecs - a.totalUsageTodaySecs;
                  }
                  setContext({ remainingBytes, remainingSecs });
                  setAllocationPeriod(a?.allocationPeriod);
                  wanFound = true;
                }
              });
            }

            // Reset info if WAN not found.
            if (!wanFound) {
              setContext({
                remainingBytes: 0,
                remainingSecs: 0,
              });
              setAllocationPeriod(null);
            }

            // Update remaining voucher data for WAN.
            const voucherAllocations = userSummary?.data?.voucherAllocations;
            if (voucherAllocations) {
              let remainingVoucherBytes = 0;
              Object.values(voucherAllocations).forEach((a) => {
                if (a.wanName === context.wanName && a?.allowedBytes !== null) {
                  remainingVoucherBytes += a.allowedBytes - a.totalUsageBytes;
                }
              });
              setContext({ remainingVoucherBytes });
            }
          })
          .catch((error) => {
            if (
              error?.response?.data?.errorMessage ===
              ExternalMsgs.django_token_not_valid
            ) {
              // If invalid token, log out the user for security.
              setContext({ forceLogOut: true });
            } else {
              setContext({
                remainingBytes: 0,
                remainingSecs: 0,
                remainingVoucherBytes: 0,
              });
              setAllocationPeriod(null);
            }
          });
      }
    } catch (error) {
      setContext({
        remainingBytes: 0,
        remainingSecs: 0,
        remainingVoucherBytes: 0,
      });
      setAllocationPeriod(null);
      // TODO: Try again on an interval?
    } finally {
      // Make spinner disappear after call if the page was just loaded.
      if (sessionState === states.loading) {
        setSessionState(null);
      }
    }
  };

  useEffect(() => {
    if (!isFirstGetSummary) {
      setTimeout(handleGetSummary, 300);
    }
  }, [context.wanName, context.isConnected]);

  useEffect(() => {
    handleGetSummary();
    setIsFirstGetSummary(false);
  }, []);

  useEffect(() => {
    // Whenever sessionStart or sessionEnd are changed to values > 0, a new
    // session has started and session intervals are set.
    if (context.sessionStart > 0 && context.sessionEnd > context.sessionStart) {
      // Clear out any pre-existing session intervals.
      clearInterval(context.sessionIntervalID);

      // Set session minutes, seconds until timeout, and remaining timeout.
      setSessionMins(Math.ceil(context.sessionLength / 60));
      const timeoutSecs = context.sessionEnd - Math.floor(Date.now() / 1000);
      setRemainingTimeout(Math.ceil(timeoutSecs / 60));

      // Update every second to show accurate time remaining for the session.
      const id = setInterval(() => {
        const now = Math.floor(Date.now() / 1000);
        const timeRemainingSecs = context.sessionEnd - now;
        setRemainingTimeout(Math.ceil(timeRemainingSecs / 60));
      }, 1000);

      // Place IDs into context so that other components can close if needed.
      setContext({ sessionIntervalID: id });
    }
  }, [context.sessionStart, context.sessionEnd]);

  const getMbRemainingPanel = (live = false) => {
    // Format period to display in the panel.
    // TODO: Move allocation period formatting code to new function.
    let period = allocationPeriod;
    if (period != null) {
      period = capitalize(allocationPeriod, true);
    }
    let label = 'Unlimited';
    if (context.remainingBytes != null) {
      let displayBytes = context.remainingBytes;
      if (live) {
        displayBytes -= context.bytesUsed;
      } else if (context.remainingBytes < MIN_SESSION_BYTES) {
        displayBytes = 0;
      }
      label = formatBytes(displayBytes);
    }

    return (
      <div className={isExtraSmallScreen ? '' : 'mx-3'}>
        <Icon
          name="dial"
          style={{ height: '46px', width: '46px', color: '#ADB5BD' }}
        />
        <div>
          <strong>Remaining Data for {period}</strong>
        </div>
        <div>
          <strong className="h3 text-success">{label}</strong>
        </div>
      </div>
    );
  };

  const getVoucherMbRemainingPanel = (live = false) => {
    let displayBytes = context.remainingVoucherBytes;
    if (live) {
      const voucherBytesUsed = context.bytesUsed - context.remainingBytes;
      if (voucherBytesUsed > 0) {
        displayBytes -= voucherBytesUsed;
      }
    } else if (context.remainingVoucherBytes <= 0) {
      return null;
    }
    const label = formatBytes(displayBytes);

    return (
      <div className={isExtraSmallScreen ? '' : 'mx-3'}>
        <Icon
          name="ticket"
          style={{ height: '46px', width: '46px', color: '#ADB5BD' }}
        />
        <div>
          <strong>Remaining Voucher Data</strong>
        </div>
        <div>
          <strong className="h3 text-success">{label}</strong>
        </div>
      </div>
    );
  };

  const getTimeRemainingPanel = () => {
    // Format label to display in the panel.
    // TODO: Move label formatting code to new function.
    let label = 'Unlimited';
    if (context.remainingSecs != null) {
      if (context.remainingSecs >= MIN_SESSION_SECS) {
        const remainingMins = Math.floor(context.remainingSecs / 60);
        label = `${remainingMins < 1 ? '<1' : remainingMins} min`;
      } else {
        label = '0 min';
      }
    }

    return (
      <div className={isExtraSmallScreen ? '' : 'mx-3'}>
        <Icon
          name="timer"
          style={{ height: '46px', width: '46px', color: '#ADB5BD' }}
        />
        <div>
          <strong>Remaining Time for Today</strong>
        </div>
        <div>
          <strong className="h3 text-success">{label}</strong>
        </div>
      </div>
    );
  };

  const getSessionTimerPanel = () => {
    // Format label to display in the panel.
    // TODO: Move label formatting code to new function.
    let label = '0 min';
    if (remainingTimeout != null) {
      label = `${remainingTimeout < 1 ? '<1' : remainingTimeout} min`;
    }

    return (
      <div className={isExtraSmallScreen ? '' : 'mx-3'}>
        <Icon
          name="timer"
          style={{ height: '46px', width: '46px', color: '#ADB5BD' }}
        />
        <div>
          <strong>Auto-Disconnect Timer</strong>
        </div>
        <div>
          <strong className="h3 text-success">{label}</strong>
        </div>
      </div>
    );
  };

  const getStatusInfo = () => {
    const sessionStarted = context.sessionStart > 0;
    const displayTime = context.remainingSecs != null;
    const displayBytes = context.remainingBytes != null;
    const displayVoucherBytes =
      displayBytes && context.remainingVoucherBytes > 0;

    return (
      <>
        {isGuest && (
          <>
            <div className="my-4 d-flex justify-content-around">
              {getVoucherMbRemainingPanel(sessionStarted)}
              {!isExtraSmallScreen && sessionStarted && getSessionTimerPanel()}
            </div>
            {isExtraSmallScreen && sessionStarted && (
              <div className="my-4 d-flex justify-content-around">
                {getSessionTimerPanel()}
              </div>
            )}
          </>
        )}
        {!isGuest && allocationPeriod != null && (
          <div className="my-4 d-flex justify-content-around">
            {sessionStarted ? (
              <>
                {displayBytes && getMbRemainingPanel(true)}
                {!isExtraSmallScreen &&
                  displayVoucherBytes &&
                  getVoucherMbRemainingPanel(true)}
                {!isExtraSmallScreen && getSessionTimerPanel()}
              </>
            ) : (
              <>
                {getMbRemainingPanel(false)}
                {!isExtraSmallScreen &&
                  displayVoucherBytes &&
                  getVoucherMbRemainingPanel(false)}
                {!isExtraSmallScreen && displayTime && getTimeRemainingPanel()}
              </>
            )}
          </div>
        )}
        {!isGuest && allocationPeriod != null && isExtraSmallScreen && (
          <>
            <div className="mb-4 d-flex justify-content-around">
              {getVoucherMbRemainingPanel(sessionStarted)}
            </div>
            <div className="mb-4 d-flex justify-content-around">
              {!sessionStarted && displayTime && getTimeRemainingPanel()}
              {sessionStarted && getSessionTimerPanel()}
            </div>
          </>
        )}
      </>
    );
  };

  const getButtonMsgText = () => {
    const msgs = [];
    if (sessionState === states.loading) {
      msgs.push('Fetching profile info...');
    } else if (!context.isOnline) {
      msgs.push('Internet is currently unavailable.');
    } else {
      if (!isGuest && allocationPeriod === null) {
        msgs.push(
          'An allocation profile for the current WAN connection has not been assigned to you.',
        );
      }
      if (isGuest && context.remainingVoucherBytes < MIN_SESSION_BYTES) {
        msgs.push(
          'You do not have enough voucher data available to start a session.',
        );
      }
      if (sessionMins < 1) {
        msgs.push('Auto-disconnect timer must be at least 1 minute.');
      }
    }

    return msgs;
  };

  const getButtonMsgs = () => {
    return (
      <div>
        {getButtonMsgText().map((msg) => {
          return <div key={msg}>{msg}</div>;
        })}
      </div>
    );
  };

  const noTimeRemaining =
    !isGuest &&
    context.remainingSecs != null &&
    context.remainingSecs < MIN_SESSION_SECS;

  const noDataRemaining = isGuest
    ? context.remainingVoucherBytes < MIN_SESSION_BYTES
    : context.remainingBytes != null &&
      context.remainingBytes + context.remainingVoucherBytes <
        MIN_SESSION_BYTES;

  const startSessionDisabled =
    sessionState ||
    sessionMins < 1 ||
    noTimeRemaining ||
    noDataRemaining ||
    !context.isOnline;

  return (
    <ContentBox heading="Internet Access">
      <div className="mt-4">
        <div className="d-flex justify-content-center">
          <div
            className={classNames('mb-3', !isExtraSmallScreen && 'py-3')}
            style={{ width: '600px' }}
          >
            <div className={isExtraSmallScreen ? 'pb-3' : 'pb-2'}>
              Set auto-disconnect timer
            </div>
            <Slider
              disabled={
                context.sessionStart > 0 &&
                context.sessionEnd > context.sessionStart
              }
              minValue={1}
              maxValue={120}
              defaultValue={
                context.sessionLength > 0
                  ? Math.ceil(context.sessionLength / 60)
                  : sessionMins
              }
              showLabels
              minLabel="1"
              maxLabel="120"
              showValue
              showUnits
              unitLabel="minutes"
              onSlideChange={(value) => setSessionMins(value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="mb-3 text-center" style={{ width: '800px' }}>
            {context.sessionStart > 0 ? (
              <div className="mt-5">
                {getStatusInfo()}
                <Button
                  className="my-4 text-black"
                  style={{
                    backgroundColor: '#FFB000',
                    borderColor: '#FFB000',
                  }}
                  disabled={sessionState}
                  onClick={() => setSessionState(states.stopPressed)}
                >
                  STOP INTERNET SESSION
                </Button>
                {sessionState && <Spinner />}
                {getButtonMsgs()}
              </div>
            ) : (
              <>
                <Button
                  className={classNames(
                    'my-4',
                    sessionMins < 1 || !context.isOnline
                      ? 'btn-light-gray'
                      : 'btn-success',
                  )}
                  disabled={startSessionDisabled}
                  onClick={() => setSessionState(states.startPressed)}
                >
                  START INTERNET SESSION
                </Button>
                {sessionState && <Spinner />}
                {getButtonMsgs()}
                {getStatusInfo()}
              </>
            )}
          </div>
        </div>
      </div>
    </ContentBox>
  );
};

export default StartStopSession;
