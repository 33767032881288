import {
  Button,
  Input,
  PasswordFormControl,
  useSmallScreen,
} from '@hvk/react-components';
import classNames from 'class-names';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Spinner from '../../components/Spinner/Spinner';
import ContentBox from '../../layout/ContentBox';
import {
  getAccessToken,
  getUserOnboardToken,
} from '../../services/AuthService';
import { StateContext } from '../../StateContext';
import { getJWTField } from '../../utility/HelperFunctions';
import { kvhLinkIcon } from '../../utility/ImagePaths';
import { rootRoute, setPasswordRoute } from '../../utility/routes';
import { ipMobileCast } from '../../utility/routes/ExternalLinks';
import { ApiMsgs, LoginMsgs } from '../../utility/StaticTexts';

import styles from './login.module.css';

const getLoginFields = (isMobile = false) => {
  return (
    <>
      <Input
        name="username"
        label="Username"
        labelClassName="mb-1"
        groupClassName={classNames('text-white', isMobile ? 'mb-1' : 'mb-4')}
      />
      <PasswordFormControl
        name="password"
        label="Password / PIN"
        labelClassName="mb-1"
        groupClassName={classNames('text-white', isMobile ? 'mb-0' : 'mb-3')}
        // HACK: Sets passwordOptions to same values as on Set password page
        //       to avoid tooltip from pulling this password's (different) options.
        passwordOptions={{
          minChars: 8,
          noSpaces: true,
        }}
      />
    </>
  );
};

const AcceptTermsCheckbox = () => {
  const { context, setContext } = useContext(StateContext);
  const isExtraSmallScreen = useSmallScreen('xs');

  // TODO: Use Input from @hvk/react-components to provide consistent styling.

  return (
    <Form.Group className="d-flex align-items-center justify-content-center pt-3">
      <div style={{ width: '32px', height: '32px' }}>
        <input
          type="checkbox"
          style={{ width: '32px', height: '32px' }}
          checked={context.acceptedTerms}
          onChange={() => setContext({ acceptedTerms: !context.acceptedTerms })}
        />
      </div>
      <div className="ml-4">
        <Form.Text
          className="text-white"
          style={isExtraSmallScreen ? { fontSize: 11 } : {}}
        >
          <i>
            By signing into my account, I agree to the{' '}
            <u>
              <a
                rel="noopener noreferrer"
                className="text-white"
                href="privacyPolicy"
                target="_blank"
              >
                KVH Privacy Policy
              </a>
            </u>{' '}
            and{' '}
            <u>
              <a
                rel="noopener noreferrer"
                className="text-white"
                href="termsOfUse"
                target="_blank"
              >
                Terms of Use
              </a>
            </u>
            .
          </i>
        </Form.Text>
      </div>
    </Form.Group>
  );
};

const LoginPage = () => {
  const { context, setContext } = useContext(StateContext);
  const navigate = useNavigate();
  const isExtraSmallScreen = useSmallScreen('xs');
  const isMediumScreen = useSmallScreen('md');

  const [showSpinner, setShowSpinner] = useState(false);

  const schema = yup.object().shape({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  });

  const validateUsername = (username) => {
    return username.indexOf(' ') === -1;
  };

  const handleLoginSubmit = (values) => {
    setShowSpinner(true);
    const { username, password } = values;
    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();
    try {
      if (validateUsername(trimmedUsername)) {
        getUserOnboardToken()
          .then((onboardResponse) => {
            const loginData = {
              username: trimmedUsername,
              password: trimmedPassword,
              onboardToken: onboardResponse.data.token,
            };

            // Extracts the vtid from the JWT token:
            const vtid = getJWTField(onboardResponse.data.token, 'vtid') || '';

            getAccessToken(onboardResponse.data.token, loginData)
              .then((response) => {
                if (response?.status === 200) {
                  if (response.data?.requirePasswordUpdate === true) {
                    navigate(setPasswordRoute, {
                      state: {
                        username: trimmedUsername,
                        tempPassword: trimmedPassword,
                        onboardToken: onboardResponse.data.token,
                        vtid,
                      },
                    });
                  } else {
                    const userRole = getJWTField(
                      response.data.accessToken,
                      'user_role',
                    );
                    setContext({
                      isLoggedIn: true,
                      accessToken: response.data.accessToken,
                      username: trimmedUsername,
                      vtid,
                      userRole,
                    });
                    navigate(rootRoute);
                  }
                } else {
                  toast.error(LoginMsgs.login_failure);
                  setShowSpinner(false);
                }
              })
              .catch((error) => {
                switch (error?.response?.status) {
                  case 400:
                    toast.error(ApiMsgs.onshore_login_400);
                    break;
                  case 401:
                    // Use API-supplied message if availaible.
                    toast.error(
                      error?.response?.data?.errorMessage ||
                        ApiMsgs.onshore_login_401,
                    );
                    break;
                  default:
                    // eslint-disable-next-line no-console
                    console.log(error?.response);
                    toast.error(LoginMsgs.login_failure);
                }
                setShowSpinner(false);
              });
          })
          .catch((error) => {
            if (error?.response?.status === 400) {
              toast.error(ApiMsgs.onboard_fw_token_400);
            } else {
              // eslint-disable-next-line no-console
              console.log(error?.response);
              toast.error(LoginMsgs.login_failure);
            }
            setShowSpinner(false);
          });
      } else {
        toast.error(LoginMsgs.username_validation_error);
        setShowSpinner(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      toast.error(LoginMsgs.login_failure);
      setShowSpinner(false);
    }
  };

  const getLoginText = (isMobile = false) => {
    return (
      <>
        {!isMobile && (
          <Form.Text className="text-white">
            To access a web page, you first need to sign into the Crew Internet
            service. When you are done using the Internet, please remember to
            sign out to avoid consuming more data.
          </Form.Text>
        )}
        {isMobile && (
          <Form.Text className="text-white" style={{ fontSize: 12 }}>
            To access a web page, you first need to sign into the Crew Internet
            service.
          </Form.Text>
        )}
      </>
    );
  };

  const getLoginButton = () => {
    return showSpinner ? (
      <div style={{ height: '44px' }}>
        <Spinner />
      </div>
    ) : (
      <Button
        className={context.acceptedTerms ? 'btn-primary' : 'btn-light-gray'}
        type="submit"
        disabled={!context.acceptedTerms}
      >
        Sign In
      </Button>
    );
  };

  const getIPMobileCastButton = () => {
    return (
      <Button
        className="btn-primary"
        href={ipMobileCast}
        target="_blank"
        rel="noreferrer"
      >
        View KVH Link
      </Button>
    );
  };

  const getFormFieldsDesktop = () => {
    return (
      <div>
        <Form.Group className="mb-4 text-white">
          <h4 className="text-center pb-2">Sign In</h4>
          {getLoginText(false)}
        </Form.Group>
        {getLoginFields(false)}
        <AcceptTermsCheckbox />
        <Form.Group className="text-center m-0 pt-4">
          {getLoginButton()}
        </Form.Group>
      </div>
    );
  };

  const getFormFieldsTablet = () => {
    return (
      <div
        style={{
          paddingTop: `70px`,
        }}
      >
        <Form.Group className="mb-4 text-white">
          <h4 className="text-center pb-2">Sign In</h4>
          {getLoginText(false)}
        </Form.Group>
        {getLoginFields(false)}
        <AcceptTermsCheckbox />
        <Form.Group className="text-center m-0 pt-4">
          {getLoginButton()}
        </Form.Group>
      </div>
    );
  };

  const getFormFieldsMobile = () => {
    return (
      <div
        style={{
          paddingTop: '70px',
        }}
      >
        <Form.Group className="mb-2 text-white">
          <h4 className="text-center pb-1">Sign In</h4>
          {getLoginText(true)}
        </Form.Group>
        {getLoginFields(true)}
        <AcceptTermsCheckbox />
        <Form.Group className="text-center m-0 pb-2">
          {getLoginButton()}
        </Form.Group>
      </div>
    );
  };

  const getFormFieldsKVHLinkDesktop = () => {
    return (
      <div>
        <Form.Group className="text-center m-0 pt-4">
          <Image src={kvhLinkIcon} alt="" />
        </Form.Group>
        <Form.Group className="mt-2 text-white text-center mb-4">
          <Form.Text>Watch news, sports, movies here</Form.Text>
          <Form.Text className="m-0">without using your own data.</Form.Text>
        </Form.Group>
        <Form.Group className="text-center m-0 mt-4">
          {getIPMobileCastButton()}
        </Form.Group>
      </div>
    );
  };

  const getFormFieldsKVHLinkTablet = () => {
    return (
      <div style={{ paddingTop: '70px' }}>
        <Form.Group className="text-center m-0 pt-4">
          <Image src={kvhLinkIcon} alt="" />
        </Form.Group>
        <Form.Group className="mt-2 text-white text-center mb-4">
          <Form.Text>Watch news, sports, movies here</Form.Text>
          <Form.Text className="m-0">without using your own data.</Form.Text>
        </Form.Group>
        <Form.Group className="text-center m-0 mt-4">
          {getIPMobileCastButton()}
        </Form.Group>
      </div>
    );
  };

  const getFormFieldsKVHLinkMobile = () => {
    return (
      <div>
        <Form.Group className="text-center m-0 pt-2">
          <Image src={kvhLinkIcon} alt="" width="180px" />
        </Form.Group>
        <Form.Group
          className="mt-1 text-white text-center"
          style={{ fontSize: 14 }}
        >
          <Form.Text>Watch news, sports, movies here</Form.Text>
          <Form.Text className="m-0">without using your own data.</Form.Text>
        </Form.Group>
        <Form.Group className="text-center m-0">
          {getIPMobileCastButton()}
        </Form.Group>
      </div>
    );
  };

  return (
    <ContentBox isLogin className={classNames(styles.authFormContainer, 'p-0')}>
      <Formik
        validationSchema={schema}
        onSubmit={handleLoginSubmit}
        initialValues={{
          username: '',
          password: '',
        }}
      >
        {(formik) => (
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            className={classNames(
              styles.authForm,
              'mx-0 px-0 d-flex align-items-center',
              isMediumScreen && 'my-0',
              isExtraSmallScreen && 'px-4',
            )}
            style={
              isMediumScreen
                ? { height: '100vh', width: '100vw', maxWidth: '1000px' }
                : {}
            }
          >
            {isExtraSmallScreen ? (
              <div style={{ width: '100%' }}>
                {getFormFieldsMobile()}
                <hr
                  style={{
                    color: 'white',
                    backgroundColor: 'white',
                    opacity: '40%',
                    height: '0px',
                    width: '90%',
                  }}
                />
                {getFormFieldsKVHLinkMobile()}
              </div>
            ) : (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: '100%' }}
              >
                <div />
                <div style={{ width: '40%' }}>
                  {isMediumScreen
                    ? getFormFieldsTablet()
                    : getFormFieldsDesktop()}
                </div>
                <div style={isMediumScreen ? { paddingTop: '70px' } : {}}>
                  <hr
                    className="my-0"
                    style={{
                      color: 'white',
                      backgroundColor: 'white',
                      opacity: '40%',
                      height: '435px',
                      width: '1px',
                    }}
                  />
                </div>
                <div>
                  {isMediumScreen
                    ? getFormFieldsKVHLinkTablet()
                    : getFormFieldsKVHLinkDesktop()}
                </div>
                <div />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </ContentBox>
  );
};

export default LoginPage;
