import { isServiceMocked } from './isServiceMocked';
import * as mockService from './mock/MockSummaryService';
import { axios2 } from './ServiceClients';
import ServiceUrls from './ServiceURLs';

export const getUserSummary = (accessToken, timeoutMs = 60000) => {
  if (isServiceMocked('summary', 'getUserSummary')) {
    return mockService.getUserSummary();
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios2(
    ServiceUrls.ONSHORE_SUMMARY,
    'GET',
    null,
    null,
    headers,
    timeoutMs,
  );
};
