import { mockDelay } from './mockDelay';

export const postCrewSession = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    status: success ? 200 : 504,
  };
};

export const deleteCrewSession = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    status: success ? 200 : 504,
  };
};

export const getCrewSession = async (
  closingSession = false,
  msDelay = 500,
  success = true,
) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          bytes_in: 123,
          bytes_out: 234,
          duration: 150,
          started_at: 1671646987,
          status: closingSession ? 10 : 0,
        }
      : null,
    status: success ? 200 : 504,
  };
};

export const postUserSession = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          uuid: '00000000-0000-0000-0000-000000000000',
          byteCount: null,
          durationSecs: null,
          requestedTimeoutSecs: 120,
          terminalIdentifier: '12819823',
          wanName: 'cell0',
          startAt: '2022-11-24T08:19:12.362463Z',
          stopAt: null,
          createdAt: '2022-11-24T08:19:12.362752Z',
          updatedAt: '2022-11-24T08:19:12.362760Z',
          wanAllocation: {
            uuid: '00000000-0000-0000-0000-000000000000',
            enforced: true,
            allocationPeriod: 'month',
            allowedBytes: 1099,
            remainingBytes: 1099,
            dailyLimitSecs: null,
            remainingTodaySecs: null,
            totalUsageBytes: 0,
            totalUsageTodaySecs: 0,
          },
          voucher: {
            voucherCount: 0,
            allowedBytes: 2000,
            remainingBytes: 2000,
            totalUsageBytes: 0,
          },
          totalAllocation: {
            allowedBytes: 3099,
            remainingBytes: 3099,
            dailyLimitSecs: null,
            remainingTodaySecs: null,
            totalUsageBytes: 0,
            totalUsageTodaySecs: 0,
            userProfileToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTQ3MTc0NjcsImV4cCI6MTcyNjMzOTg2Nywic2Vzc2lvbl91dWlkIjoiSTBEMjAiLCJpcF9hZGRyIjoiMTkyLjE2OC41LjUxIiwid2FuX25hbWUiOiJjZWxsMCIsImFsbG9jYXRpb25fYWxsb3dlZF9ieXRlcyI6MTAwMDAwMDAsImFsbG9jYXRpb25fcmVtYWluaW5nX2J5dGVzIjoxMDAwMDAwMCwiYWxsb2NhdGlvbl9kYWlseV9saW1pdF9zZWNzIjoxMDAwMDAwMCwiYWxsb2NhdGlvbl9yZW1haW5pbmdfdG9kYXlfc2VjcyI6MTIwLCJzZXNzaW9uX3RpbWVvdXRfc2VjcyI6MTIwLCJhbGxvY2F0aW9uX3BlcmlvZCI6ImRheSJ9.cnJ8d0DGvUO-sadHnJ9bZRB_8-yzbBrnmHjfq-Uv7Oc',
            // {
            //   "iat": 1694717467,
            //   "exp": 1726339867,
            //   "session_uuid": "I0D20",
            //   "ip_addr": "192.168.5.51",
            //   "wan_name": "cell0",
            //   "allocation_allowed_bytes": 10000000,
            //   "allocation_remaining_bytes": 10000000,
            //   "allocation_daily_limit_secs": 10000000,
            //   "allocation_remaining_today_secs": 120,
            //   "session_timeout_secs": 120,
            //   "allocation_period": "day"
            // }
          },
        }
      : null,
    status: success ? 201 : 504,
  };
};

export const deleteUserSession = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    status: success ? 204 : 504,
  };
};
