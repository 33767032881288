import { Card, useSmallScreen } from '@hvk/react-components';
import classNames from 'class-names';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import ContentBox from '../../layout/ContentBox';

const PrivacyPolicy = () => {
  const isExtraSmallScreen = useSmallScreen('xs');

  const [collapsedAccordians, setCollapsedAccordians] = useState(
    Array(13).fill(false),
  );
  const [swapState, setSwapState] = useState(null);

  const swapAccordianState = (index) => {
    if (index < collapsedAccordians.length) {
      const newState = collapsedAccordians;
      newState[index] = !newState[index];
      setCollapsedAccordians(newState);
    }
  };

  useEffect(() => {
    if (swapState != null) {
      swapAccordianState(swapState);
      setSwapState(null);
    }
  }, [swapState]);

  return (
    <ContentBox heading="Privacy Policy">
      <div className={classNames('mb-3', !isExtraSmallScreen && 'py-4')}>
        <div className="mb-4">
          <strong>
            <em>Effective Date:&nbsp; September 22, 2022</em>
          </strong>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Overview</strong>
          </p>
          <p className="mb-4">
            Your privacy is important to us. KVH Industries, Inc. and its
            subsidiaries and affiliates (collectively &quot;<u>KVH,</u>&quot;
            &quot;<u>we</u>
            ,&quot; &quot;<u>us</u>&quot; or &quot;<u>our</u>&quot;) is a global
            company and we process your personal data when you interact with us
            by visiting our website, purchasing our products or using our
            services. Every time we process your personal data we do so by fully
            respecting your privacy and your other rights and freedoms.
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="0" onClick={() => setSwapState(0)}>
              {collapsedAccordians[0] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card className="border">
                <div
                  id="overview"
                  className="more-panel"
                  style={{ display: 'block' }}
                >
                  <p className="mb-2">
                    KVH is a manufacturer of solutions that provide global
                    high-speed Internet, television, and voice services via
                    satellite to mobile users at sea and on land as well as a
                    leading provider of commercially licensed entertainment
                    including news, sports, movies, and music to commercial and
                    leisure customers in the maritime, hotel, and retail
                    markets.{' '}
                  </p>
                  <p className="mb-2">
                    KVH is committed to protecting the privacy and security of
                    your personal data (described below in Information We
                    Collect).
                  </p>
                  <p className="mb-2">
                    This Privacy Policy explains how we treat personal data
                    about you that we receive in connection with:
                  </p>
                  <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                    <li className="mb-2">
                      Our websites (individually and collectively, our &quot;
                      <u>Website</u>
                      &quot;), including this website, <u>kvh.com</u>,{' '}
                      <u>kvhmobileworld.com</u>, and <u>myKVH.com</u>; and{' '}
                    </li>
                    <li className="mb-2">
                      The services and products KVH makes available pursuant to
                      agreements with subscribers, their service providers and
                      authorized users (individually and collectively the &quot;
                      <u>Services</u>
                      &quot;).
                    </li>
                  </ul>
                  <p className="mb-2">
                    Also, please bear in mind that this Privacy Policy
                    complements the{' '}
                    <strong>
                      <a href="termsOfUse">
                        <u>Terms of Use</u>
                      </a>
                    </strong>{' '}
                    and{' '}
                    <strong>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.kvh.com/legal/cookies-policy"
                        target="_blank"
                      >
                        <u>Cookies Policy</u>
                      </a>
                    </strong>{' '}
                    posted on our Website. Use of our Website is conditioned
                    upon your agreement to the Terms of Use, Cookies Policy and
                    this Privacy Policy.{' '}
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Information We Collect</strong>
          </p>
          <p className="mb-4">
            By &quot;personal data,&quot; this means any information regarding
            an identified or identifiable individual. Depending on the context
            of your interactions with KVH, we collect and use different types of
            personal data. We may use personal data from potential candidates
            for employment as well as personal data from website visitors,
            prospective clients and/or providers using our services through our
            websites for registration of services or cookies analytics.{' '}
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="1" onClick={() => setSwapState(1)}>
              {collapsedAccordians[1] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card className="border">
                <div id="info_collect" className="more-panel">
                  <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                    <li className="mb-2">
                      <strong>
                        Information collected from you when you browse our
                        website:
                      </strong>{' '}
                      Through certain technologies employed by our Website, we
                      collect non-personally identifiable information about you.
                      For more details about this type of data collection,
                      please refer to the{' '}
                      <strong>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.kvh.com/legal/cookies-policy"
                          target="_blank"
                        >
                          <u>Cookies Policy</u>
                        </a>{' '}
                      </strong>
                      posted on our Website.
                    </li>
                    <li className="mb-2">
                      <strong>
                        Information collected when using our products or
                        services:
                      </strong>{' '}
                      When we or our partners provide certain services, we may
                      obtain your physical location data. For example, when
                      using mini-VSAT or using myKVH.com, we collect data
                      identifying the actual location of a physical address or
                      connected device using information such as GPS data,
                      geocodes, latitude and longitude information, and images
                      of the various locations. We supplement this data with
                      your disclosed information about your location (such as
                      your ZIP or postal code) to provide you with content that
                      may be of interest to you. If you provide information
                      about other individuals authorized to use your
                      subscriptions account, you represent you have permission
                      from these individuals. KVH disclaims any legal duty to
                      verify the accuracy or completeness of any personal data
                      provided to KVH.
                    </li>
                    <li className="mb-2">
                      <strong>
                        Information collected when you are invited to visit a
                        customized Website:{' '}
                      </strong>
                      On occasions, we may invite you to visit customized
                      Websites via an email containing a personalized URL, a
                      notice on a registration page, or in response to you
                      logging on to a certain portion of our Website. If you are
                      invited to visit one of these customized Websites, you may
                      find it customized with references to products and/or
                      services that we believe may be of interest to you based
                      on your previous interactions with KVH and on information
                      you have provided to us. While you are visiting these
                      customized Websites, we may collect tracking or other
                      non-personally identifiable information about your visit
                      to better tailor the site to your interests. This can also
                      be collected through the use of cookies and web beacons.
                      Please refer to the{' '}
                      <strong>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.kvh.com/legal/cookies-policy"
                          target="_blank"
                        >
                          <u>Cookies Policy</u>
                        </a>
                      </strong>
                      . If you do not want your information to be collected in
                      this way, do not accept the invitation to visit these
                      customized Websites.
                    </li>
                  </ul>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Children&apos;s Information</strong>
          </p>
          <p className="mb-2">
            We do not knowingly collect personal data on children under age 16
            without the verifiable permission of a parent or guardian. The
            content of our Website and the products and services available are
            not intended for, or directed to, children under age 16. If you are
            under 16 years of age, then please do not use or access our Website
            or any of our other Websites at any time or in any manner.{' '}
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>How We Use the Information We Collect</strong>
          </p>
          <p className="mb-4">
            The purposes for which we collect and use your personal data may
            vary depending on the type of relationship you have with us, such as
            if you apply for a position with our company, if you are one of our
            clients or service providers, or a user of one of our websites. KVH
            always collects and uses personal data according to the purpose
            limitation principle. The use of personal data for new purposes will
            be consistent and meet your privacy expectations. Otherwise we will
            request your authorization.
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="2" onClick={() => setSwapState(2)}>
              {collapsedAccordians[2] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card className="border">
                <div id="how-use" className="more-panel">
                  <p className="mb-2">
                    KVH may use the information we obtain for:{' '}
                  </p>
                  <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                    <li className="mb-2">
                      <strong>Recruitment</strong>: if you apply for a position
                      with our company we will use your personal data to
                      consider you for employment and to administer your
                      account.
                    </li>
                    <li className="mb-2">
                      <strong>Communications and account management</strong>:
                      establish or activate your accounts, provision of services
                      and manage our communications with you including providing
                      appropriate technical support.
                    </li>
                    <li className="mb-2">
                      <strong>Marketing and analytics</strong>: send
                      newsletters, offers or programs that may be of interest to
                      you, perform data analyses to determine usage patterns and
                      the effectiveness of our sales, marketing and advertising.
                    </li>
                    <li className="mb-2">
                      <strong>Business development</strong>: operate, evaluate
                      and improve our business, including the analysis and
                      enhancement of our products, services, and Website, and
                      developing new products and services.
                    </li>
                    <li className="mb-2">
                      <strong>Security</strong>: protect against, identify and
                      prevent fraud and other criminal activity, claims and
                      other liabilities.
                    </li>
                    <li className="mb-2">
                      <strong>Administrative management</strong>: perform
                      accounting, auditing, billing, reconciliation and
                      collection activities.
                    </li>
                    <li className="mb-2">
                      <strong>Compliance with laws</strong>: comply with
                      applicable legal requirements and our policies.
                    </li>
                  </ul>
                  <p className="mb-2">
                    We may also use the information we obtain about you in other
                    ways which we provide specific notice or opt-out at the time
                    of collection.
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Legal Basis for Data Collection and Use</strong>
          </p>
          <p className="mb-4">
            KVH only collects and uses personal data when there is a fair and
            legal basis for its collection and use, for instance, when the
            collection of personal data is necessary to enter into a contract,
            to meet our legitimate interests, to comply with legal obligations
            or when we have your consent.
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="3" onClick={() => setSwapState(3)}>
              {collapsedAccordians[3] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card className="border">
                <div id="legal_basis" className="more-panel">
                  <p className="mb-2">
                    The information we collect when we enter into a contract or
                    business relationship with you, unless indicated otherwise,
                    is mandatory to develop our contractual relationship and to
                    comply with legal obligations. For instance, some tax laws,
                    labor, anti-fraud or compliance laws require organizations
                    to collect certain information that may vary depending on
                    the local regulations. Without the required mandatory
                    information, we would not be able to work with you or
                    provide you our services.{' '}
                  </p>
                  <p className="mb-2">
                    Marketing activities are usually based on your consent or to
                    an existing business relationship with us. However, you can
                    opt-out of these communications anytime and free of charge.
                  </p>
                  <p className="mb-2">
                    When we use your personal data for our legitimate interests
                    (e.g. security, business development, marketing, analytics),
                    we always conduct a balancing test in order to ensure that
                    data subject rights are not overridden.{' '}
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Information We Share</strong>
          </p>
          <p className="mb-4">
            At times, KVH engages third party contractors, service providers,
            and other vendors to help us accomplish our business objectives.
            There are other circumstances where we may disclose personal data
            where required by law or in response to lawful requests by public
            authorities or judicial bodies. California residents have the right
            to request information regarding the disclosure of their personal
            data.{' '}
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="4" onClick={() => setSwapState(4)}>
              {collapsedAccordians[4] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card className="border">
                <div id="info_share" className="more-panel">
                  <p className="mb-2">
                    In order to provide you services worldwide, we share your
                    personal data to our KVH entities. We also share some
                    personal data you provide to us with trusted companies we
                    have hired to provide services to and for us, such as
                    sending out our newsletters, fulfilling orders you submit,
                    administering our promotion and marketing activities or
                    collecting and processing information on our behalf. These
                    companies – our vendors – are contractually bound to use
                    personal data that we share with them only to perform the
                    services we have hired them to provide. Among the vendors
                    whose services we employ may be companies that provision
                    services, activation of products, distribute products or
                    help us deliver online advertisements such as banner ads.
                    Other vendors may collect and use information you provide to
                    us to help us better understand the offers, promotions, and
                    types of advertising that are most appealing to our
                    customers.
                  </p>
                  <p className="mb-2">
                    We will also share your personal data in the event that the
                    business of KVH is transferred to another entity by way of
                    merger, sale of the company&apos;s assets, or otherwise.
                  </p>
                  <p className="mb-2">
                    In certain circumstances, KVH may be required to disclose
                    your personal data in response to valid requests by public
                    authorities, including to meet national security or law
                    enforcement requirements.
                  </p>
                  <p className="mb-4">
                    We may also disclose personal data in the context of any
                    transaction involving all or a portion of the business.
                  </p>
                  <p className="mb-4">
                    <strong>California Residents</strong>
                  </p>
                  <div className="mb-4">
                    <p className="mb-2">
                      <strong>
                        <em>
                          California Residents - Disclosure to Third Parties
                        </em>
                      </strong>
                    </p>
                    <p className="mb-2">
                      Under California Civil Code sections 1798.83-1798.84, also
                      known as the &quot;Shine the Light Law,&quot; California
                      residents who use our Website are entitled to request and
                      receive once a year, free of charge, a notice from us
                      describing what categories of personal data we share with
                      third parties for their direct marketing purposes. As
                      indicated above, such sharing only takes place with your
                      consent. That notice will identify the categories of
                      information shared, the third parties and affiliates with
                      which it was shared, and their names and addresses. If you
                      are a California resident and would like a copy of this
                      notice, please submit a written request to the contact
                      address below. In your request, please specify that you
                      want a &quot;California Privacy Rights Notice.&quot;
                      Please allow at least thirty days for a response.
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="mb-2">
                      <strong>
                        <em>California Residents - Do Not Track Notice</em>
                      </strong>
                    </p>
                    <p className="mb-2">
                      Except as described in the Cookies Policy, we do not track
                      Website visitors over time and across third party websites
                      to provide targeted advertising and therefore our Website
                      does not respond to Do Not Track (&quot;
                      <u>DNT</u>
                      &quot;) signals. You may opt out of tracking as described
                      in the Cookies Policy. However, some third-party sites do
                      keep track of your browsing activities when they serve you
                      content, which enables them to tailor what they present to
                      you. If you are visiting such sites, your web browser may
                      allow you to set the DNT signal so that third parties
                      (particularly advertisers) know you do not want to be
                      tracked. Third parties that have content embedded on
                      KVH&apos;s websites such as a social feature or a stock
                      ticker may set cookies on a user&apos;s browser and/or
                      obtain information about the fact that a web browser
                      visited a specific KVH website from a certain IP address.
                      Third parties cannot collect any other personal data from
                      KVH&apos;s websites unless you provide it to them
                      directly.
                    </p>
                  </div>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Retention Periods</strong>
          </p>
          <p className="mb-4">
            KVH applies the storage limitation principle in order to retain
            personal data in our records only for the length of time required to
            fulfill the purpose for which the data was collected.{' '}
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="5" onClick={() => setSwapState(5)}>
              {collapsedAccordians[5] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card className="border">
                <div id="retention" className="more-panel">
                  <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                    <li className="mb-2">
                      According to our retention policy, we only keep personal
                      data in our records as long as they are necessary for the
                      purposes they have been processed. KVH adheres to our
                      retention policy to determine how long we retain data,
                      including personal data. The retention periods are
                      established considering our legitimate business purposes
                      and according to local regulations such as tax, employment
                      law and other commercial regulations.
                    </li>
                  </ul>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Your Choices</strong>
          </p>
          <p className="mb-4">
            We offer you certain choices about how we communicate with you and
            how we use information that is collected from you. If you reside or
            otherwise find yourself in the territory of the EEA, United Kingdom
            or Switzerland, we are committed to facilitate and exercise rights
            granted by applicable data protection law in a timely manner.{' '}
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="6" onClick={() => setSwapState(6)}>
              {collapsedAccordians[6] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card className="border">
                <div id="choices" className="more-panel">
                  <p className="mb-4">
                    <strong>Your Choices: </strong>If you have any inquiries or
                    to exercise your rights and choices listed below, write to{' '}
                    <u>privacy@kvh.com</u> or contact us through our postal
                    address or by telephone. Please be aware that to protect
                    your security and privacy, KVH may take reasonable steps to
                    verify your identity prior to making changes to your
                    personal data. Modifying or deleting your personal data may
                    affect our ability to provide services to you.
                  </p>
                  <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                    <li className="mb-2">
                      <strong>
                        Opt In, Opt Out or Unsubscribe to marketing
                        communications:
                      </strong>{' '}
                      When you provide information to us on our Website, we will
                      give you the opportunity to opt-in to receiving
                      promotional and marketing information from KVH and/or
                      selected third parties. In addition, in each communication
                      you will be provided with the option to opt-out by
                      clicking on the unsubscribe link. You may also change your
                      preferences and choose not to receive any marketing
                      communications from us by emailing us at{' '}
                      <u>privacy@kvh.com</u>, and we will honor your request.
                      Also, please note that you may continue to receive
                      materials while we are updating our lists. Regarding our
                      use of cookies and your choices, see our{' '}
                      <strong>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.kvh.com/legal/cookies-policy"
                          target="_blank"
                        >
                          <u>Cookies Policy</u>
                        </a>
                      </strong>
                      .{' '}
                    </li>
                    <li className="mb-2">
                      <strong>Right to access your own personal data:</strong>{' '}
                      You have the right to obtain confirmation of whether and
                      how we process your personal data, as well as the right to
                      obtain a copy of this data by contacting us by email at{' '}
                      <u>privacy@kvh.com</u>.
                    </li>
                    <li className="mb-2">
                      <strong>
                        Erasure, correction, objection and other requests:
                      </strong>{' '}
                      You have the right to obtain erasure, and correction of
                      your personal data, under certain conditions. You may also
                      object at any time to the processing of your personal
                      data, based on your specific situation. In this case, we
                      will assess your request and provide a reply in a timely
                      manner, according to our legal obligations. For all the
                      processing operations that are based on your consent, you
                      can withdraw it at any time and we will stop those
                      processing operations. To exercise these rights, send an
                      email to us at <u>privacy@kvh.com</u> with the words
                      &quot;UPDATE MY INFORMATION&quot; in the subject line.{' '}
                    </li>
                    <li className="mb-2">
                      <p className="mb-2">
                        <strong>
                          Privacy rights under the EEA regulation:
                        </strong>{' '}
                        In addition to the previous choices, if you reside or
                        otherwise find yourself in the territory of the EU, KVH
                        is committed to also facilitate the exercise of these
                        other rights granted by EU data protection law in a
                        timely manner:
                        <br />
                      </p>
                      <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                        <li className="mb-2">
                          <strong>Marketing Practices: </strong>KVH does not
                          engage in any automated decision making using your
                          personal data. However, we may use our marketing
                          automation software to determine your level of
                          interest in our products and services for further
                          engagement. We combine information collected through
                          the use of cookies on our websites, such as IP
                          address, with other information you provide us through
                          webforms, by email or during trade shows to determine
                          your interests in our products, so we can provide you
                          with more relevant information about our products when
                          we send you email marketing communications. We ask
                          your consent to conduct these activities and you have
                          the right to withdraw it at any time by sending us an
                          email to <u>privacy@kvh.com</u>.
                        </li>
                        <li className="mb-2">
                          <strong>Right to portability:</strong> you have the
                          right to obtain a copy of your personal data in a
                          structured or commonly used and machine-readable
                          format where technically feasible where we collected
                          your data based on a contract or your consent. To
                          exercise this right, send an email to{' '}
                          <u>privacy@kvh.com</u>.
                        </li>
                        <li className="mb-2">
                          <strong>
                            Right to lodge a complaint with a supervisory
                            authority
                          </strong>
                          : you have the right to lodge a complaint with the
                          supervisory authority of your habitual residence,
                          place of work or place of the alleged infringement, if
                          you are not satisfied with our response.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Cross-Border Transfers</strong>
          </p>
          <p className="mb-4">
            KVH is a global organization and provides global services. Sharing
            information cross border is essential so that you receive the same
            high-quality service wherever you are in the world. As a result,
            subject to applicable law, your personal data may be transferred to,
            accessed from and stored in servers and facilities located outside
            the country where you live as may be necessary for the purposes
            described in this Privacy Policy. Where we transfer store, and
            process your personal data outside of the EEA, we have implemented
            appropriate safeguards to provide adequate level of data protection.
            To protect your personal data when it is transferred to the United
            States we have self-certified under the EU-US Privacy Shield and
            under the Swiss-US Privacy Shield. We also use contractual
            protections for the transfer of personal data from the EU
            (including, for example, the European Commission&apos;s standard
            contractual clauses), or an adequacy decision of the European
            Commission confirming an adequate level of data protection in the
            respective non-EEA country.{' '}
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="7" onClick={() => setSwapState(7)}>
              {collapsedAccordians[7] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card className="border">
                <div id="cross_border" className="more-panel">
                  <p className="mb-2">
                    When you interact with our Website, purchase our products or
                    use our services, then your personal data may be transferred
                    to the United States. Please see the following section for
                    more information about the Privacy Shield.{' '}
                  </p>
                  <p className="mb-2">
                    When we transfer your personal data outside the EEA, we will
                    protect that information as described in this Privacy Policy
                    and in accordance with applicable law. We use contractual
                    protections for the transfer of personal data from the EEA
                    (including, for example, the European Commission&apos;s
                    standard contractual clauses). We have self-certified under
                    the EU-US Privacy Shield and under the Swiss-US Privacy
                    Shield, and abide by the principles of Notice, Choice,
                    Accountability for Onward Transfer, Security, Data
                    Integrity, Access, and Enforcement with respect to the
                    EEA/Swiss personal information that it receives while it is
                    a participant of the Privacy Shield program. When you
                    purchase any product or service, access the myKVH.com site
                    or use any KVH services, you understand and agree that we
                    may transfer your personal data globally to KVH entities,
                    affiliates or subsidiaries, as well as third-party service
                    providers and vendors.{' '}
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Privacy Shield</strong>
          </p>
          <p className="mb-4">
            KVH complies with the EU-U.S. Privacy Shield Framework and
            Swiss-U.S. Privacy Shield Framework (&quot;Privacy Shield&quot;) as
            set forth by the U.S. Department of Commerce regarding the
            collection, use, and retention of personal data transferred from the
            European Union and Switzerland to the United States. KVH has
            certified to the Department of Commerce that it adheres to the
            Privacy Shield Principles. If there is any conflict between the
            terms in this privacy policy and the Privacy Shield Principles, the
            Privacy Shield Principles shall govern. To learn more about the
            Privacy Shield program, and to view our certification, please visit
            the U.S. Department of Commerce&apos;s Privacy Shield website
            located at <u>https://www.privacyshield.gov/</u>.
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="8" onClick={() => setSwapState(8)}>
              {collapsedAccordians[8] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card className="border">
                <div id="shield" className="more-panel">
                  <p className="mb-4">
                    KVH processes all the personal data we receive in the United
                    States from the EU, the EEA, and Switzerland in accordance
                    with the following{' '}
                    <strong>Privacy Shield Principles</strong>:
                  </p>
                  <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                    <li className="mb-2">
                      <strong>Notice</strong>: Through this Privacy Policy and
                      when we collect your personal data we provide you with
                      complete information about how we process your personal
                      data and our commitment with the Privacy Shield
                      Principles;{' '}
                    </li>
                    <li className="mb-2">
                      <strong>Choices and Access</strong>: We provide you with
                      different Choices, including the possibility to opt out
                      before disclosing your personal data to a third party or
                      if we intend to use it for unrelated purposes and the
                      right of access to your own personal data;
                    </li>
                    <li className="mb-2">
                      <strong>Accountability for Onward Transfers</strong>: When
                      your personal data is transferred to another organization,
                      we take appropriate steps to sign the necessary
                      contractual agreements to protect your personal data;
                    </li>
                    <li className="mb-2">
                      <strong>Security</strong>: We maintain reasonable and
                      appropriate Security measures to protect your personal
                      data from loss, misuse and unauthorized access,
                      disclosures, alteration or destruction;
                    </li>
                    <li className="mb-2">
                      <strong>Data Integrity and Purpose Limitation</strong>: We
                      only process personal data necessary for the relevant
                      purposes of the processing above;{' '}
                    </li>
                    <li className="mb-2">
                      <strong>Data Retention</strong>: We keep personal data for
                      no longer than is necessary to fulfill the defined
                      purposes for which the personal data is processed; and
                    </li>
                    <li className="mb-4">
                      <strong>Recourse, Enforcement, and Liability</strong>: We
                      provide different mechanisms for assuring compliance with
                      these Principles according to the section below, Dispute
                      resolution.{' '}
                    </li>
                  </ul>
                  <p className="mb-4">
                    <strong>Your Rights</strong>:
                  </p>
                  <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                    <li className="mb-2">
                      <strong>Right of access</strong>: You have the right to
                      access your own data that has been transferred to KVH
                      under the Privacy Shield.
                    </li>
                    <li className="mb-2">
                      <strong>Right of correction</strong>: You can request the
                      correction, or amendment of your personal data to keep the
                      information accurate and up to date.
                    </li>
                    <li className="mb-4">
                      <strong>Right to delete</strong>: You have the right to
                      delete your personal data when it is no longer needed and
                      there is no other legal ground that justifies its
                      retention.
                    </li>
                  </ul>
                  <p className="mb-2">
                    <strong>Disclosures and sharing of data</strong>
                  </p>
                  <p className="mb-4">
                    In accordance with our policy regarding disclosure and
                    sharing of data, your personal data will only be shared as
                    appropriate with third parties that process information on
                    behalf of, or with, KVH. KVH employs contractual provisions
                    with third parties to safeguard your personal data in
                    accordance with this Privacy Policy and applicable law.
                    Under certain circumstances, KVH in the U.S. may remain
                    liable for the acts of these third parties if they process
                    personal data in a manner that is inconsistent with the
                    Privacy Shield Principles or applicable data protection law.
                    KVH may also remain liable under the Privacy Shield
                    Principles if the company&apos;s third-party processor
                    onward transfer recipients process relevant personal data in
                    a manner inconsistent with the Privacy Shield Principles,
                    unless KVH proves that it is not responsible for the event
                    giving rise to the damage.
                  </p>
                  {/* <a name="dispute10" /> */}
                  <p className="mb-4">
                    <strong>Dispute resolution mechanisms</strong>
                  </p>
                  <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
                    <li className="mb-2">
                      <strong>Complaint handling mechanism</strong>: In
                      compliance with the Privacy Shield Principles, KVH commits
                      to resolve complaints about our collection or use of your
                      personal data. EU and Swiss individuals with any inquiries
                      or complaints about our Privacy Shield policy or our
                      handling of your personal data under the Privacy Shield,
                      should first contact us by writing to the following
                      address and we will respond to your inquiry promptly:
                      <p className="mb-2">
                        <strong>Attn. Felise Feingold</strong>
                        <br />
                        <em>
                          Vice President, General Counsel and Compliance Officer
                        </em>
                        <br />
                        <u>privacy@kvh.com</u>
                        <strong> </strong>
                        <br />
                        KVH Industries, Inc.
                        <br />
                        50 Enterprise Center
                        <br />
                        Middletown, RI 02842 USA
                      </p>
                    </li>
                    <li className="mb-2">
                      <strong>Recourse to Arbitration: </strong>KVH has further
                      committed to refer unresolved Privacy Shield complaints to
                      American Arbitration Association, an alternative dispute
                      resolution provider located in the United States. If,
                      after attempting to address privacy questions or concerns
                      with KVH, you do not receive timely acknowledgment of your
                      complaint from us, or if you still have a specific privacy
                      concern that has not been resolved to your satisfaction,
                      please visit <u>https://go.adr.org/privacyshield.html</u>{' '}
                      for more information or to file a complaint. The services
                      of the American Arbitration Association are provided at no
                      cost to you and you can submit your unresolved privacy
                      concern to the following address:
                      <p className="mb-2">
                        Submit your unresolved privacy concern to the American
                        Arbitration Association for resolution:
                      </p>
                      <p className="mb-2">
                        American Arbitration Association
                        <br />
                        Case Filing Services
                        <br />
                        877.495.4185: Toll free
                        <br />
                        877.304.8457: Fax
                        <br />
                        <u>casefiling@adr.org</u>
                        <br />
                        <u>https://go.adr.org/privacyshield.html</u>
                      </p>
                    </li>
                    <li className="mb-2">
                      <strong>Contact your Data Protection Authority</strong>:
                      If you are based in the EU or the EEA, you may choose to
                      contact your local <u>Data Protection Authority </u>
                      (&quot;
                      <u>DPA</u>
                      &quot;), or the{' '}
                      <u>
                        Swiss Federal Data Protection and Information
                        Commissioner
                      </u>
                      , if you are based in Switzerland. Your DPA or the Swiss
                      Commissioner may refer your complaint directly to the
                      Department of Commerce on your behalf. In this case, the
                      Privacy Shield Team will then work to resolve your
                      concern.{' '}
                    </li>
                    <li className="mb-4">
                      <strong>
                        US Department of Commerce&apos;s Privacy Shield
                        Framework:{' '}
                      </strong>
                      You may also have the option to select binding arbitration
                      for the resolution of your complaint under certain
                      circumstances. For more information on binding
                      arbitration, see{' '}
                      <u>
                        US Department of Commerce&apos;s Privacy Shield
                        Framework
                      </u>
                      .
                    </li>
                  </ul>
                  <p className="mb-2">
                    <strong>Enforcement and compliance </strong>
                  </p>
                  <p className="mb-2">
                    For purposes of enforcing compliance with the Privacy
                    Shield, KVH is subject to the investigatory and enforcement
                    authority of the US Federal Trade Commission. For more
                    information about the Privacy Shield and to view KVH&apos;s
                    certification page, please visit the <u>website</u> of the
                    US Federal Trade Commission on Privacy Shield.
                  </p>
                  <p className="mb-2">
                    If there is any conflict between the terms in this Privacy
                    Statement and the Privacy Shield Principles, the Privacy
                    Shield Principles shall govern.
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Security</strong>
          </p>
          <p className="mb-4">
            KVH is committed to the security, confidentiality and integrity
            principle. We are committed to processing your data in a secure
            manner and have put in place commercially reasonable security
            measures to prevent the personal data we hold from being
            accidentally or deliberately compromised.
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="9" onClick={() => setSwapState(9)}>
              {collapsedAccordians[9] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="9">
              <Card className="border">
                <div id="security" className="more-panel">
                  <p className="mb-2">
                    KVH uses industry standard administrative, technical and
                    physical safeguards designed to protect the personal data
                    you provide against accidental, unlawful or unauthorized
                    destruction, loss, alteration, access, disclosure or use.
                    KVH&apos;s Websites kvh.com and kvhmobileworld.com are SSL
                    certified websites. SSL certificates are small data files
                    that digitally bind an encryption key to a particular
                    organization. KVH&apos;s SSL certificates allow a secure
                    connection from a web server to our browser.{' '}
                  </p>
                  <p className="mb-2">
                    We also conduct information risk assessments. We ensure that
                    our staff understands the importance of protecting personal
                    data, and we are responsibly managing access rights within
                    the company. We include both physical security and IT
                    security in our overall data security approach. We are
                    diligent in selecting vendors that process personal data on
                    our behalf so that they also ensure appropriate technical
                    and organizational measures to protect the data.
                  </p>
                  <p className="mb-2">
                    KVH makes reasonable efforts to notify individuals and
                    regulatory authorities, as required by law, if we reasonably
                    believe that personal data has been stolen, disclosed,
                    altered or infringed by an unauthorized person. We create
                    and maintain a breach notification and reporting protocol.{' '}
                  </p>
                  <p className="mb-2">
                    We also endorse the concept of privacy by design which is an
                    approach to projects that promotes privacy and data
                    protection compliance from the outset. This means
                    considering the privacy and security implications for any
                    new project or process throughout its lifecycle.
                  </p>
                  <p className="mb-2">
                    Despite these security measures, you should be aware that it
                    is impossible to guarantee absolute security with respect to
                    information sent through the Internet.
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Changes to the Privacy Policy</strong>
          </p>
          <p className="mb-4">
            We reserve the right to modify this Privacy Policy and related
            business practices at any time. We will duly inform you of any
            changes.
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="10" onClick={() => setSwapState(10)}>
              {collapsedAccordians[10] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="10">
              <Card className="border">
                <div id="changes" className="more-panel">
                  <p className="mb-2">
                    This Privacy Policy is subject to change from time to time.
                    KVH will endeavor to post a notice of any changes to this
                    Privacy Policy for a period of thirty (30) days following
                    any such modifications. The time stamp you see on the policy
                    will indicate the last date it was revised.
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Severability clause</strong>
          </p>
          <p className="mb-4">
            If any term of this Agreement is to any extent invalid, illegal, or
            incapable of being enforced, such term shall be excluded; all other
            terms hereof shall remain in full force and effect
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="11" onClick={() => setSwapState(11)}>
              {collapsedAccordians[11] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="11">
              <Card className="border">
                <div id="severability" className="more-panel">
                  <p className="mb-2">
                    If any term or provision of this Privacy Policy shall be
                    found to be invalid, illegal or otherwise unenforceable,
                    such finding shall not affect the other terms or provisions
                    of this Privacy Policy, or the whole of this Privacy Policy,
                    but such term or provision shall be deemed modified to the
                    extent necessary to render such term or provision
                    enforceable, and the rights and obligations of you and KVH
                    shall be construed and enforced accordingly, preserving to
                    the fullest permissible extent the intent and agreements set
                    forth in this Privacy Policy.{' '}
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>How to Contact Us</strong>
          </p>
          <p className="mb-4">
            At KVH we are committed to apply this privacy policy. For this
            reason, if you have any privacy concern or questions about how your
            personal data is used, please feel free to contact us at{' '}
            <u>privacy@kvh.com</u>, through our postal address or by telephone.
          </p>
          <Accordion className="more">
            <Accordion.Toggle eventKey="12" onClick={() => setSwapState(12)}>
              {collapsedAccordians[12] ? '- Learn More' : '+ Learn More'}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="12">
              <Card className="border">
                <div id="contact" className="more-panel">
                  <p className="mb-2">You may also contact us at:</p>
                  <p className="mb-2">
                    <strong>Felise Feingold</strong>
                    <br />
                    <em>
                      Sr. Vice President, General Counsel &amp; Compliance
                      Officer
                    </em>
                    <br />
                    KVH Industries, Inc.
                    <br />
                    50 Enterprise Center
                    <br />
                    Middletown, RI 02842 USA
                    <br />
                    401-847-3327
                  </p>
                  <p className="mb-2">
                    If you are located in Europe, you may contact us at:
                  </p>
                  <p className="mb-2">
                    <strong>Felise Feingold</strong>
                    <br />
                    <em>
                      Sr. Vice President, General Counsel &amp; Compliance
                      Officer
                    </em>
                    <br />
                    KVH Industries A/S
                    <br />
                    Kokkedal Industripark 2B
                    <br />
                    2980 Kokkedal, Denmark
                    <br />
                    +1 401-847-3327
                  </p>
                </div>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
      </div>
    </ContentBox>
  );
};

export default PrivacyPolicy;
