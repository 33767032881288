import { useSmallScreen } from '@hvk/react-components';
import classNames from 'class-names';
import React from 'react';

import ContentBox from '../../layout/ContentBox';

const TermsOfUse = () => {
  const isExtraSmallScreen = useSmallScreen('xs');

  return (
    <ContentBox heading="Terms of Use">
      <div className={classNames('mb-3', !isExtraSmallScreen && 'py-4')}>
        <div className="mb-4">
          <p className="mb-2">
            <strong>
              <em>Effective Date:&nbsp; February 14, 2023</em>
            </strong>
          </p>
          <p className="mb-2">
            By using this KVH Crew Internet service (&quot;Service&quot;), you,
            as the Subscriber, acknowledge and agree to the following:
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Registration and Passwords; User Account</strong>
          </p>
          <p className="mb-2">
            We require you to have a password and provide registration details
            to access this Service. The details you provide in this registration
            process must be correct, current, and complete. If KVH believes that
            the details are not correct, current, and complete, we have the
            right to refuse you access to the Service, or any of its resources,
            and to terminate or suspend your account, if any.{' '}
            <span style={{ textTransform: 'uppercase' }}>
              YOUR USER ACCOUNT ACCESS CREDENTIALS AND PASSWORD(S) MUST NOT BE
              DISCLOSED TO ANY OTHER INDIVIDUAL(S). YOU ARE RESPONSIBLE FOR
              MAINTAINING THE CONFIDENTIALITY OF ANY PASSWORD(S) AND ACCESS
              CREDENTIALS YOU ARE GIVEN TO ACCESS THIS SERVICE, AND YOU ARE
              FULLY RESPONSIBLE FOR ALL ACTIVITIES THAT OCCUR UNDER YOUR
              PASSWORD(S) AND USER ACCOUNT.
            </span>{' '}
            KVH shall not be liable for any loss that you incur as a result of
            someone else using your password or user account access credentials,
            either with or without your knowledge because you have not
            maintained the confidentiality of your user account and/or password.
            You may be held liable for any losses incurred by KVH, its
            affiliates, officers, directors, employees, consultants, agents, and
            representatives due to someone else&apos;s use of your account or
            password because you have not maintained the confidentiality of your
            password. You agree to notify us immediately of any unauthorized use
            of your password(s). We reserve the absolute right not to issue a
            password to any person or entity.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Service Limitations and Subscriber Data</strong>
          </p>
          <p className="mb-2">
            Subscriber acknowledges that the operation of the Service may not be
            continuous, uninterrupted, or error-free. The Service may become
            unavailable or limited because of network equipment failures,
            incompatibility of subscriber equipment, capacity limitations of a
            service provider, emergency pre-emption as required by governmental
            authorities, or periodic or emergency maintenance conducted by KVH
            or a service provider for modifications, upgrades, repairs, and
            similar purposes. The Service is also subject to transmission and
            reception limitations caused by various technical and coverage
            issues. Given these limitations, Subscriber is solely responsible
            for management of its Subscriber data transmitted or maintained
            using the Service, including but not limited to back-up and
            restoration of that data. KVH and its service providers are not
            responsible for the loss of Subscriber data or for the back-up or
            restoration of subscriber data.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Permissible Use</strong>
          </p>
          <p className="mb-4">
            Subscriber agrees not to engage in unlawful or unacceptable use of
            the Service, including without limitation:
          </p>
          <ul className={isExtraSmallScreen ? 'pl-4' : ''}>
            <li className="mb-2">
              posting, disseminating, storing or transmitting duplicative or
              unsolicited messages, chain letters, pyramid schemes, or
              unsolicited e-mail (commercial or otherwise);
            </li>
            <li className="mb-2">
              posting, uploading, disseminating, storing or transmitting
              material of any kind or nature that, to a reasonable person, may
              be abusive, obscene, harmful, hateful, pornographic, defamatory,
              harassing, libelous, deceptive, fraudulent, invasive of
              another&apos;s privacy, grossly offensive, vulgar, threatening,
              malicious or racially, ethnically or otherwise objectionable;
            </li>
            <li className="mb-2">
              obtaining unauthorized access to data, systems or networks,
              including any attempt to probe, scan or test the vulnerability of
              a system or network or to breach security or authentication
              measures;
            </li>
            <li className="mb-2">
              unauthorized monitoring of data or traffic on any network system;
            </li>
            <li className="mb-2">
              interfering or disrupting service to any other user, host or
              networking, including mail bombing, flooding, attempts to overload
              a system, and broadcast attacks;
            </li>
            <li className="mb-2">
              forging of any TCP-IP packet header or any part of the header
              information in an e-mail or newsgroup posting;
            </li>
            <li className="mb-2">
              relaying mail via another site&apos;s mail server without express
              permission of that site;
            </li>
            <li className="mb-2">
              canceling any postings or messages other than Subscriber&apos;s
              own, except for postings in Subscriber&apos;s name that have been
              forged;
            </li>
            <li className="mb-2">
              impersonating any person or entity, including any KVH employee or
              representative;
            </li>
            <li className="mb-2">
              disclosing Subscriber&apos;s password or other means for accessing
              the Service to any third party, or otherwise facilitating
              unauthorized access thereto;
            </li>
            <li className="mb-2">
              using the Service where it is not authorized; or
            </li>
            <li className="mb-2">furthering criminal activity.</li>
          </ul>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Internet Content</strong>
          </p>
          <p className="mb-2">
            The Internet is an inherently insecure medium and KVH does not
            represent, warrant or guarantee the security or integrity of any
            communications made or received using the Service. Subscriber shall
            be solely responsible and liable for the security, confidentiality
            and integrity of all messages and the content that Subscriber
            receives, transmits through or stores on the Service.
          </p>
          <p className="mb-2">
            The reliability, availability, legality, performance and other
            aspects of resources accessed through the Internet are beyond
            KVH&apos;s reasonable control and KVH and its service providers do
            not endorse, warrant, and are not responsible or liable for any
            content, advertising, products, or other materials on or available
            from sites or resources available through the Service, including the
            accuracy or reliability of any material or claims contained therein.
          </p>
          <p className="mb-2">
            Subscriber acknowledges that Internet sites, video programming, and
            other content available through the Internet, might consist of,
            include and/or provide access to images, subject matter, sounds,
            messages, text, service or other content and material that may be
            unsuitable for minors and that may be objectionable to many adults.
            Subscriber acknowledges that access to the Internet and video
            programming content through the Service is at Subscriber&apos;s sole
            risk.
          </p>
          <p className="mb-2">
            KVH retains absolute and complete discretion to block Internet sites
            and to select the content made available through the Service at any
            time without notice.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Privacy</strong>
          </p>
          <p className="mb-2">
            Subscriber understands that data communications transmitted over
            satellite and wireless systems and the Internet may not be private
            or secure and KVH makes no warranties regarding the privacy of
            Subscriber&apos;s data or communications. Subscriber consents to KVH
            or its service providers monitoring Subscriber&apos;s account
            activity in order to resolve billing queries, prevent and minimize
            fraud or misuse and ensure the quality of the Service.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Electronic Communications</strong>
          </p>
          <p className="mb-2">
            When you send e-mails to us, you are communicating with us
            electronically, and thereby you are consenting to receive
            communications from us electronically or by other means available.
            We will communicate with you electronically, by telephone or by
            facsimile. It is further understood that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically satisfy any legal requirement that such communication
            be in writing.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Emergency Communications</strong>
          </p>
          <p className="mb-2">
            Subscriber acknowledges that the Service may not support emergency
            distress communications and KVH is under no obligation to provide
            any emergency referral capabilities using the Service.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Import/Export Control</strong>
          </p>
          <p className="mb-2">
            You agree not to transmit any content using this Service or commit
            any act involving the transfer of information or data in violation
            of applicable import/export control, espionage or national security
            laws. You will comply fully with such laws.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Intellectual Property</strong>
          </p>
          <p className="mb-2">
            All software and other intellectual property involved in the
            provision of the Service, or maintained within the Service
            equipment, is subject to copyright, database protection, and other
            rights to the extent of applicable laws. Unauthorized use of such
            content is a violation of patent, copyright, trademark and
            potentially other laws.
          </p>
          <p className="mb-2">
            Some of the KVH-supplied equipment and some of the content includes
            software, or is made available to Subscriber through software or
            firmware interfaces, or through software or firmware storage,
            compression, transmission, encryption, and other security measures.
            Regardless of the purpose of the software or firmware, Subscriber
            shall not, and shall not authorize or assist others, in any attempt
            to bypass, disable, defeat, modify, or obtain the source code for
            the software or firmware through decompilation, disassembly, reverse
            engineering or other means.
          </p>
          <p className="mb-2">
            The intellectual property rights in the equipment, software, and
            firmware remain solely with KVH and its service providers and
            Subscriber&apos;s infringement of any intellectual property rights
            shall be a breach of these terms and will result in termination of
            your account and other potential legal action.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Warranty Disclaimers</strong>
          </p>
          <p className="mb-2" style={{ textTransform: 'uppercase' }}>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THIS SERVICE IS
            PROVIDED ON AN &quot;AS IS&quot; BASIS AND IS USED ONLY AT YOUR SOLE
            RISK, TO THE FULLEST EXTENT PERMISSIBLE BY LAW. KVH DISCLAIMS ALL
            WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND, REGARDING THIS SERVICE
            (INCLUDING ITS CONTENT, HARDWARE, SOFTWARE AND LINKS), INCLUDING ANY
            IMPLIED WARRANTIES AS TO FITNESS FOR A PARTICULAR PURPOSE, QUALITY,
            MERCHANTABILITY, TITLE, NON-INFRINGEMENT, RESULTS, ACCURACY,
            COMPLETENESS, ACCESSIBILITY, COMPATIBILITY, SECURITY AND FREEDOM
            FROM COMPUTER VIRUS. IF APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION
            OF SOME OR ALL OF THE ABOVE IMPLIED WARRANTIES TO APPLY TO YOU, THE
            ABOVE EXCLUSIONS WILL APPLY TO YOU TO THE FULLEST EXTENT PERMITTED
            BY APPLICABLE LAW.
          </p>
          <p className="mb-2" style={{ textTransform: 'uppercase' }}>
            THIS LIMITATION ABOVE IS NOT INTENDED TO DEPRIVE YOU AND DOES NOT
            DEPRIVE YOU OF ANY RIGHTS YOU MAY HAVE UNDER MANDATORY APPLICABLE
            CONSUMER PROTECTION LAWS.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Limitations on Liability and Remedies</strong>
          </p>
          <p className="mb-2" style={{ textTransform: 'uppercase' }}>
            KVH&apos;S ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY WITH RESPECT
            TO ANY DISPUTE WITH KVH (INCLUDING WITHOUT LIMITATION YOUR USE OF
            THE SERVICE) IS TO DISCONTINUE YOUR USE OF THE SERVICE. KVH AND ITS
            SERVICE PROVIDERS SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
            SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGE ARISING FROM
            YOUR USE OF THIS SERVICE OR FOR ANY OTHER CLAIM RELATED IN ANY WAY
            TO YOUR USE OR REGISTRATION WITH KVH. THESE EXCLUSIONS FOR DIRECT,
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES
            INCLUDE, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOST DATA,
            LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR
            ANY OTHER COMMERCIAL DAMAGES OR LOSSES, EVEN IF KVH HAD BEEN ADVISED
            OF THE POSSIBILITY THEREOF AND REGARDLESS OF THE LEGAL OR EQUITABLE
            THEORY UPON WHICH THE CLAIM IS BASED. BECAUSE SOME STATES OR
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
            JURISDICTIONS, KVH AND ITS SERVICE PROVIDERS&apos; LIABILITY IN SUCH
            STATE OR JURISDICTION SHALL BE LIMITED TO THE EXTENT PERMITTED BY
            LAW. KVH DOES NOT ENDORSE, WARRANT OR GUARANTEE THIS SERVICE AND
            WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
            ANY TRANSACTION BETWEEN YOU AND THIRD-PARTIES.
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-2">
            <strong>Dispute Resolution</strong>
          </p>
          <p className="mb-2">
            Notwithstanding where you reside or whether you are a consumer or a
            business user, KVH and you agree that all disputes arising under
            these Terms of Use shall be brought by you in your individual
            capacity, and not as a plaintiff or class member in any purported
            class or representative proceeding, and shall be resolved by
            confidential binding arbitration administered by the American
            Arbitration Association (&quot;AAA&quot;) in Rhode Island, USA, or
            another forum or location reasonably convenient to you and mutually
            agreed upon by you and KVH, pursuant to the commercial arbitration
            Rules (&quot;Rules&quot;) of the AAA by a sole arbitrator nominated
            by agreement of you and KVH and confirmed in accordance with the
            Rules. If AAA is not hearing consumer commercial disputes at the
            time, KVH may select another arbitral body in its sole discretion.
            In the event you and KVH are unable to agree on a location for the
            arbitration hearing, a place shall be chosen by the arbitrator or
            arbitrator administrator. The arbitrator&apos;s award shall be
            binding and may be entered as a judgment in a court of competent
            jurisdiction. You agree that KVH is entitled to obtain preliminary
            injunctive relief to the extent allowed by law to enforce any of the
            terms of these Terms of Use pending a final arbitral decision.
          </p>
        </div>
      </div>
    </ContentBox>
  );
};

export default TermsOfUse;
