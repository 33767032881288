import { Button, DataTable, useSmallScreen } from '@hvk/react-components';
import classNames from 'class-names';
import React, { useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';

import {
  userVoucherColumns,
  userVoucherColumnsMobile,
} from '../../components/DataTables/ColumnData';
import Spinner from '../../components/Spinner/Spinner';
import ContentBox from '../../layout/ContentBox';
import { callPaginatedGetApi } from '../../services/serviceHelper';
import {
  getProfileBundles,
  getUserVouchers,
} from '../../services/VouchersService';
import { StateContext } from '../../StateContext';
import {
  convertCsvDate,
  isVtidValid,
  matchProfile,
} from '../../utility/HelperFunctions';
import { userVouchersAddRoute } from '../../utility/routes';
import { ErrorMsgs } from '../../utility/StaticTexts';

const UserVouchers = () => {
  const { context, setContext } = useContext(StateContext);
  const navigate = useNavigate();
  const isExtraSmallScreen = useSmallScreen('xs');
  const isSmallScreen = useSmallScreen('sm');

  const [voucherData, setVoucherData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [loadedVoucherContents, setLoadedVoucherContents] = useState(false);
  const [loadedProfileContents, setLoadedProfileContents] = useState(false);
  const [combinedData, setCombinedData] = useState([]);
  const [showDisconnected, setShowDisconnected] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const handleGetUserVouchers = async () => {
    try {
      setShowSpinner(true);
      setShowDisconnected(!context.isOnline);

      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = await isVtidValid(context.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      // Get user voucher data, paginating as needed.
      const vouchers = await callPaginatedGetApi(
        getUserVouchers,
        context,
        setContext,
        0,
        100,
        null,
        [],
        () => setShowDisconnected(true),
      );
      if (vouchers != null) {
        setVoucherData(vouchers);
        setLoadedVoucherContents(true);
      } else {
        setShowDisconnected(true);
      }
    } catch (error) {
      setShowDisconnected(true);
    }
  };

  const handleGetProfileBundles = async () => {
    try {
      setShowSpinner(true);
      setShowDisconnected(!context.isOnline);

      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = await isVtidValid(context.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      // Get user profile data, paginating as needed.
      const profiles = await callPaginatedGetApi(
        getProfileBundles,
        context,
        setContext,
        0,
        100,
        null,
        ['?kind=voucher'],
        () => setShowDisconnected(true),
      );
      if (profiles != null) {
        setProfileData(profiles);
        setLoadedProfileContents(true);
      } else {
        setShowDisconnected(true);
      }
    } catch (error) {
      setShowDisconnected(true);
    }
  };

  useEffect(() => {
    // Attempts to load content if not already loaded.
    if (!loadedVoucherContents) {
      handleGetUserVouchers();
    }
  }, [context.isOnline, loadedVoucherContents]);

  useEffect(() => {
    // Attempts to load content if not already loaded.
    if (!loadedProfileContents) {
      handleGetProfileBundles();
    }
  }, [context.isOnline, loadedProfileContents]);

  useEffect(() => {
    // Attempts to show content if loaded but not showing.
    if (loadedVoucherContents && loadedProfileContents && showDisconnected) {
      setShowDisconnected(!context.isOnline);
    }
    setShowSpinner(!(loadedVoucherContents && loadedProfileContents));
  }, [context.isOnline, loadedVoucherContents, loadedProfileContents]);

  useEffect(() => {
    if (voucherData.length > 0 && profileData.length > 0) {
      setCombinedData(
        voucherData.map((user) => {
          return {
            ...user,
            profileData: matchProfile(user.allocationBundleName, profileData),
          };
        }),
      );
    }
  }, [voucherData, profileData]);

  const getCsvFormat = (data) => {
    const csvData = data.map((row) => {
      return {
        'Voucher Profile': row.allocationBundleName,
        Name: row.userFullName,
        Username: row.username,
        'Date Issued': convertCsvDate(row.createdAt),
      };
    });
    return csvData;
  };

  const getCsvLink = () => {
    return showDisconnected ? (
      <div
        className={isExtraSmallScreen ? 'p-2 m-2' : 'p-2 mr-4'}
        style={{ color: '#718190', opacity: 0.5 }}
      >
        Download CSV
      </div>
    ) : (
      <CSVLink
        className={isExtraSmallScreen ? 'p-2 m-2' : 'p-2 mr-4'}
        data={getCsvFormat(voucherData)}
        filename="User Vouchers.csv"
      >
        Download CSV
      </CSVLink>
    );
  };

  const getAddButton = () => {
    return (
      <Button
        icon="add-circle-outline"
        onClick={() => navigate(userVouchersAddRoute)}
      >
        Assign A Voucher
      </Button>
    );
  };

  return (
    <ContentBox
      htmlHeading={
        <div
          className={
            isExtraSmallScreen
              ? ''
              : 'd-flex justify-content-between align-items-center'
          }
        >
          <header className="h5 d-flex mb-4">User Vouchers</header>
          <div
            className={classNames(
              isSmallScreen &&
                'd-flex align-items-center justify-content-center',
              isExtraSmallScreen && 'flex-column',
            )}
          >
            {isExtraSmallScreen ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                {getAddButton()}
                {getCsvLink()}
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                {getCsvLink()}
                {getAddButton()}
              </div>
            )}
          </div>
        </div>
      }
    >
      {showSpinner ? (
        <div className="d-flex justify-content-center py-4">
          <Spinner />
        </div>
      ) : (
        <div>
          {showDisconnected ? (
            <div className="p-4">{ErrorMsgs.information_not_available}</div>
          ) : (
            <>
              <div className={isExtraSmallScreen ? 'pb-3' : 'pt-5'}>
                Assign a voucher to provide additional data to crew members.
              </div>
              <div
                className={classNames(
                  'pb-5 d-flex flex-wrap',
                  !isExtraSmallScreen && 'pt-5',
                  isSmallScreen && 'justify-content-center',
                )}
              >
                <div
                  className="flex-column"
                  style={{
                    width: isSmallScreen ? '600px' : '100%',
                    maxWidth: isSmallScreen ? '600px' : '100%',
                    fontSize: '14px',
                    // HACK: Restores disappearing top border on mobile.
                    borderTop: isExtraSmallScreen ? '1px solid #DEE2E6' : '',
                    boxSizing: 'border-box',
                    MozBoxSizing: 'border-box',
                    WebkitBoxSizing: 'border-box',
                  }}
                >
                  <DataTable
                    tableClassNames="table-bordered"
                    data={combinedData}
                    columns={
                      isSmallScreen
                        ? userVoucherColumnsMobile
                        : userVoucherColumns
                    }
                    pageSize={10}
                    paginate
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </ContentBox>
  );
};

export default UserVouchers;
