import classNames from 'class-names';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

const MobileTableRow = ({
  data,
  columnData,
  subTable = false,
  noHeaders = false,
}) => {
  return (
    <div className={subTable ? 'w-100' : ''}>
      <Table
        className="m-0"
        style={{ fontSize: '14px' }}
        bordered={!subTable}
        borderless
      >
        <tbody>
          {columnData.map((columnInfo) => {
            return (
              <tr key={columnInfo.key}>
                {!noHeaders && (
                  <td
                    className="data-table-label bold-text py-2"
                    style={{ width: '48%' }}
                  >
                    {columnInfo.header.toUpperCase()}
                  </td>
                )}
                <td
                  className={classNames(
                    'data-table-value',
                    columnInfo.className || 'py-2',
                  )}
                >
                  {columnInfo.format != null
                    ? columnInfo.format(data[columnInfo.key])
                    : data[columnInfo.key]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

MobileTableRow.propTypes = {
  data: PropTypes.shape({}).isRequired,
  columnData: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      className: PropTypes.string,
      format: PropTypes.func,
    }),
  ).isRequired,
  subTable: PropTypes.bool,
  noHeaders: PropTypes.bool,
};

export default MobileTableRow;
