export default {
  ONSHORE_LOGIN: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/auth/',
  },
  ONSHORE_PASSWORD_RESET: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/password/reset/',
  },
  ONSHORE_SUMMARY: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/',
  },
  ONSHORE_USAGE_LOG: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/usage/',
  },
  ONSHORE_SESSION: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/session/',
  },
  ONSHORE_PROFILE_BUNDLES: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/profile-bundles/',
  },
  ONSHORE_CREW_USERS: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/crew-users/',
  },
  ONSHORE_GUEST_VOUCHERS: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/guests/',
  },
  ONSHORE_USER_VOUCHERS: {
    baseKey: 'apiBaseUrlOnshore',
    relative: '/api/v2/user/vouchers/',
  },
  ONBOARD_CBX_STATUS: {
    baseKey: 'apiBaseUrlOnboardCbx',
    relative: '/login',
  },
  ONBOARD_FW_TOKEN: {
    baseKey: 'apiBaseUrlOnboardFW',
    relative: '/cbx/v3/crew/token',
  },
  ONBOARD_FW_CREW_SESSION: {
    baseKey: 'apiBaseUrlOnboardFW',
    relative: '/cbx/v3/crew/session',
  },
};
