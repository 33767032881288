import { mockDelay } from './mockDelay';

export const getProfileBundles = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          totalRecords: 3,
          data: [
            {
              uuid: '13842ec0-ba16-4d86-8151-cc51ee072323',
              createdAt: '2023-10-02T14:32:11.305782Z',
              updatedAt: '2023-10-02T14:32:11.305790Z',
              name: 'Weekend Guest',
              kind: 'guest',
              description:
                'A guest profile that allows a small allocation to all connections.',
              wanUsageLimits: [
                {
                  wanName: 'vsat',
                  allowedBytes: 10000000,
                },
                {
                  wanName: 'cell0',
                  allowedBytes: 10000000,
                },
                {
                  wanName: 'wifi0',
                  allowedBytes: 10000000,
                },
                {
                  wanName: 'alternate',
                  allowedBytes: 10000000,
                },
                {
                  wanName: 'backup',
                  allowedBytes: 10000000,
                },
              ],
              fleetIdentifier: 'Loopers Fleet',
            },
            {
              uuid: '3fe31fc9-0014-43bb-b4ee-3360f4531133',
              createdAt: '2023-10-02T14:29:08.538984Z',
              updatedAt: '2023-10-02T14:29:08.538993Z',
              name: 'Guest Service',
              kind: 'guest',
              description: 'A guest profile that only allows wifi usage.',
              wanUsageLimits: [
                {
                  wanName: 'vsat',
                  allowedBytes: 0,
                },
                {
                  wanName: 'cell0',
                  allowedBytes: 0,
                },
                {
                  wanName: 'wifi0',
                  allowedBytes: 11111111111,
                },
                {
                  wanName: 'alternate',
                  allowedBytes: 0,
                },
                {
                  wanName: 'backup',
                  allowedBytes: 0,
                },
              ],
              fleetIdentifier: 'Loopers Fleet',
            },
            {
              uuid: '7b45b467-666c-4c71-9af7-9765806fad80',
              createdAt: '2023-10-02T13:38:30.555625Z',
              updatedAt: '2023-10-02T13:44:50.268453Z',
              name: 'Charter Guest',
              kind: 'guest',
              description:
                'A standard guest profile for vsat, cell, and wifi usage.',
              wanUsageLimits: [
                {
                  wanName: 'vsat',
                  allowedBytes: 500000000,
                },
                {
                  wanName: 'cell0',
                  allowedBytes: 1000000000,
                },
                {
                  wanName: 'wifi0',
                  allowedBytes: 2000000000,
                },
                {
                  wanName: 'alternate',
                  allowedBytes: 0,
                },
                {
                  wanName: 'backup',
                  allowedBytes: 0,
                },
              ],
              fleetIdentifier: 'Loopers Fleet',
            },
          ],
        }
      : null,
    status: success ? 200 : 504,
  };
};

export const getCrewUsers = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          totalRecords: 4,
          data: [
            {
              uuid: '63115d9e-dd61-4f09-bb98-9c1d44c18a53',
              username: 'ob_user',
              name: 'ob_user',
              enabled: true,
              updatedAt: '2023-10-16T14:30:46.703154Z',
            },
            {
              uuid: 'fb0bd2c4-46bb-4598-8ae3-322ac1555f84',
              username: 'u1',
              name: 'u1',
              enabled: true,
              updatedAt: '2023-09-26T13:28:15.091172Z',
            },
            {
              uuid: '82059ed8-9701-40e1-917f-380619a12f9b',
              username: 'admin',
              name: '',
              enabled: true,
              updatedAt: '2023-01-04T19:16:26.305278Z',
            },
            {
              uuid: '6c001180-bbdc-4ab8-b9df-932931af23ed',
              username: 'demo_user',
              name: 'demo_user',
              enabled: true,
              updatedAt: '2022-12-27T15:09:59.583477Z',
            },
          ],
        }
      : null,
    status: success ? 200 : 504,
  };
};

export const getGuestVouchers = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          totalRecords: 3,
          data: [
            {
              username: '202301011',
              pin: 'SVG0079',
              uuid: '00000000-0000-0000-0000-000000000000',
              createdAt: '2022-10-09T13:18:00.519556Z',
              createdBy: 'jcroy123',
              lastLogin: '2022-10-11T13:18:00.519556Z',
              allocationBundleName: 'Charter Guest 1',
            },
            {
              username: '202301012',
              pin: 'SVG0016',
              uuid: '00000000-0000-0000-0000-000000000001',
              createdAt: '2022-10-09T13:20:00.519556Z',
              createdBy: 'jcroy123',
              lastLogin: '2022-10-10T13:20:00.519556Z',
              allocationBundleName: 'Charter Guest 2',
            },
            {
              username: '202301013',
              pin: 'SVG0021',
              uuid: '00000000-0000-0000-0000-000000000002',
              createdAt: '2022-10-09T13:22:00.519556Z',
              createdBy: 'jcroy123',
              lastLogin: '2022-10-09T13:22:00.519556Z',
              allocationBundleName: 'Charter Guest 3',
            },
          ],
        }
      : null,
    status: success ? 200 : 504,
  };
};

export const postGuestVouchers = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    status: success ? 204 : 503,
  };
};

export const getUserVouchers = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          totalRecords: 4,
          data: [
            {
              userFullName: 'Crew User 1',
              username: 'crew_user_1',
              allocationBundleName: 'Premium Voucher',
              createdAt: '2022-10-06T13:18:00.519556Z',
              createdBy: 'jcroy123',
            },
            {
              userFullName: 'Crew User 2',
              username: 'crew_user_2',
              allocationBundleName: 'Basic Voucher',
              createdAt: '2022-10-06T13:19:00.519556Z',
              createdBy: 'jcroy123',
            },
            {
              userFullName: 'Crew User 3',
              username: 'crew_user_3',
              allocationBundleName: 'Premium Voucher',
              createdAt: '2022-10-06T13:20:00.519556Z',
              createdBy: 'jcroy123',
            },
            {
              userFullName: 'Crew User 1',
              username: 'crew_user_1',
              allocationBundleName: 'Premium Voucher',
              createdAt: '2022-10-07T13:20:00.519556Z',
              createdBy: 'jcroy123',
            },
          ],
        }
      : null,
    status: success ? 200 : 504,
  };
};

export const postUserVouchers = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    status: success ? 204 : 503,
  };
};
