import { toast } from 'react-toastify';

const actionsMap = {
  'copy-square': 'copy',
  'print-square': 'print',
};

export const copyGuestCredentials = (row) => {
  navigator.clipboard.writeText(`Username: ${row.username}\nPIN: ${row.pin}\n`);
  toast.info('Username/PIN copied.');
};

export const printGuestVouchers = (onPrint, selectedRows) => {
  if (onPrint != null && selectedRows?.length > 0) {
    onPrint(selectedRows);
  } else {
    toast.error('Nothing to print.');
  }
};

export const handleGuestVoucherActions = (actionInfo, row) => {
  const action = actionInfo.icon
    ? actionsMap[actionInfo.icon] || actionInfo.icon
    : actionsMap[actionInfo] || actionInfo;

  if (action === 'copy') {
    copyGuestCredentials(row);
  }

  if (action === 'print') {
    printGuestVouchers(row?.onPrint, [row?.index]);
  }
};
