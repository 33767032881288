import {
  Button,
  PasswordFormControl,
  getPasswordRegex,
  useSmallScreen,
} from '@hvk/react-components';
import classNames from 'class-names';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Spinner from '../../components/Spinner/Spinner';
import ContentBox from '../../layout/ContentBox';
import { setPassword } from '../../services/AuthService';
import { StateContext } from '../../StateContext';
import { getJWTField } from '../../utility/HelperFunctions';
import { rootRoute } from '../../utility/routes';
import { ApiMsgs, LoginMsgs } from '../../utility/StaticTexts';

import styles from './login.module.css';

const passwordOptions = {
  minChars: 8,
  noSpaces: true,
};

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password is required')
    .matches(
      getPasswordRegex(passwordOptions).fullRegex,
      'Please enter strong password.',
    ),
  confirmNewPassword: yup
    .string()
    .required('Confirm password is required')
    .nullable()
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

const SetPassword = () => {
  const { setContext } = useContext(StateContext);
  const isExtraSmallScreen = useSmallScreen('xs');
  const isMediumScreen = useSmallScreen('md');
  const navigate = useNavigate();
  const location = useLocation();

  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (values) => {
    setIsDisabled(true);
    const setPasswordInfo = {
      username: location.state.username,
      tempPassword: location.state.tempPassword,
      passwordOne: values.newPassword,
      passwordTwo: values.confirmNewPassword,
      onboardToken: location.state.onboardToken,
    };
    try {
      await setPassword(location.accessToken, setPasswordInfo)
        .then((response) => {
          if (response?.status === 200) {
            const userRole = getJWTField(
              response.data.accessToken,
              'user_role',
            );
            setContext({
              isLoggedIn: true,
              accessToken: response.data.accessToken,
              username: location.state.username,
              vtid: location.state.vtid,
              userRole,
            });
            setTimeout(() => {
              toast.success(LoginMsgs.reset_password_success, {
                className: 'bg-success',
              });
            }, 100);
            navigate(rootRoute);
          } else {
            toast.error(LoginMsgs.reset_password_failure);
            setIsDisabled(false);
          }
        })
        .catch((error) => {
          switch (error?.response?.status) {
            case 400:
              toast.error(ApiMsgs.onshore_pw_reset_400);
              break;
            case 401:
              // Use API-supplied message if availaible.
              toast.error(
                error?.response?.data?.errorMessage ||
                  ApiMsgs.onshore_pw_reset_401,
              );
              break;
            default:
              // eslint-disable-next-line no-console
              console.log(error?.response);
              toast.error(LoginMsgs.reset_password_failure);
          }
          setIsDisabled(false);
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      toast.error(LoginMsgs.reset_password_failure);
      setIsDisabled(false);
    }
  };

  return (
    <ContentBox isLogin className={classNames(styles.authFormContainer, 'p-0')}>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          newPassword: '',
          confirmNewPassword: '',
        }}
      >
        {(formik) => (
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            className={classNames(
              styles.authForm,
              'mx-0 d-flex justify-content-center align-items-center',
              isMediumScreen && 'my-0',
              isExtraSmallScreen && 'px-4',
            )}
            style={
              isMediumScreen
                ? { height: `100vh`, width: '100vw', maxWidth: '1000px' }
                : {}
            }
          >
            <div
              style={{
                paddingTop: isMediumScreen ? '80px' : '0px',
                width: isExtraSmallScreen ? '600px' : '400px',
              }}
            >
              <Form.Group className="mb-4 text-white">
                <h4 className="text-center pb-2">Set Your New Password</h4>
              </Form.Group>
              <PasswordFormControl
                name="newPassword"
                label="New Password"
                labelClassName="text-white"
                strengthMeter
                passwordOptions={passwordOptions}
              />
              <PasswordFormControl
                name="confirmNewPassword"
                label="Re-enter New Password"
                labelClassName="text-white"
                passwordOptions={passwordOptions}
              />
              <Form.Group className="text-center m-0 pt-4">
                {isDisabled ? (
                  <div style={{ height: '44px' }}>
                    <Spinner />
                  </div>
                ) : (
                  <Button type="submit" disabled={isDisabled}>
                    Set Password
                  </Button>
                )}
              </Form.Group>
            </div>
          </Form>
        )}
      </Formik>
    </ContentBox>
  );
};

export default SetPassword;
