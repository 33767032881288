import Config from '../config';

// Returns true if all API methods under the service are mocked or
// if the specified API method is mocked.
export const isServiceMocked = (serviceName, methodName) => {
  const mocked = Config.mockedServices
    ? Config.mockedServices[serviceName] ?? false
    : false;
  return (
    (typeof mocked === 'boolean' && mocked) ||
    (typeof mocked === 'object' && mocked.includes(methodName))
  );
};
