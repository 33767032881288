import {
  Button,
  DataTable,
  Icon,
  Input,
  useSmallScreen,
} from '@hvk/react-components';
import classNames from 'class-names';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import {
  handleGuestVoucherActions,
  printGuestVouchers,
} from '../../components/DataTables/ColumnActions';
import {
  guestVoucherColumns,
  guestVoucherColumnsMobile,
  selectVoucherProfileColumns,
  selectVoucherProfileColumnsMobile,
} from '../../components/DataTables/ColumnData';
import PrintVoucherModal from '../../components/modals/PrintVoucherModal';
import Spinner from '../../components/Spinner/Spinner';
import ContentBox from '../../layout/ContentBox';
import { callPaginatedGetApi } from '../../services/serviceHelper';
import {
  getGuestVouchers,
  getProfileBundles,
  postGuestVouchers,
} from '../../services/VouchersService';
import { StateContext } from '../../StateContext';
import { isVtidValid, matchProfile } from '../../utility/HelperFunctions';
import { guestVouchersRoute } from '../../utility/routes';
import { ErrorMsgs, ExternalMsgs } from '../../utility/StaticTexts';

const AddGuestVouchers = () => {
  const { context, setContext } = useContext(StateContext);
  const navigate = useNavigate();
  const isExtraSmallScreen = useSmallScreen('xs');
  const isSmallScreen = useSmallScreen('sm');

  const [profileData, setProfileData] = useState([]);
  const [showDisconnected, setShowDisconnected] = useState(false);
  const [loadedContents, setLoadedContents] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showButtonSpinner, setShowButtonSpinner] = useState(false);

  const [selectedRow, setSelectedRow] = useState(-1);
  const [numNewUsers, setNumNewUsers] = useState(1);
  const [vouchersCreated, setVouchersCreated] = useState(false);

  const [selectedVoucherRows, setSelectedVoucherRows] = useState([]);
  const [voucherData, setVoucherData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const [printModal, setPrintModal] = useState(false);
  const [printedVouchers, setPrintedVouchers] = useState(selectedVoucherRows);

  const openPrintModal = (printRows) => {
    if (printRows != null) {
      setPrintedVouchers(printRows);
    } else {
      setPrintedVouchers(selectedVoucherRows);
    }
    setPrintModal(true);
  };

  const onHidePrintModal = (showModal) => {
    setPrintModal(showModal);
  };

  const disableSubmit =
    vouchersCreated ||
    selectedRow < 0 ||
    Number.isNaN(numNewUsers) ||
    numNewUsers <= 0;

  const schema = yup.object().shape({
    profile: yup.number().required('Guest Profile is required'),
    users: yup
      .number()
      .required('No. users is required')
      .integer('No. users must be a positive integer')
      .positive('No. users must be a positive integer')
      .max(100, 'Can generate only 100 vouchers at a time.'),
  });

  const resetForm = () => {
    setVouchersCreated(false);
  };

  const handleGetProfileBundles = async () => {
    try {
      setShowSpinner(true);
      setShowDisconnected(!context.isOnline);

      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = await isVtidValid(context.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      // Get guest profile data, paginating as needed.
      const profiles = await callPaginatedGetApi(
        getProfileBundles,
        context,
        setContext,
        0,
        100,
        null,
        ['?kind=guest'],
        () => setShowDisconnected(true),
      );
      if (profiles != null) {
        setProfileData(profiles);
        setLoadedContents(true);
      } else {
        setShowDisconnected(true);
      }
    } catch (error) {
      setShowDisconnected(true);
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    // Attempts to load content if not already loaded.
    if (!loadedContents) {
      handleGetProfileBundles();
    }

    // Attempts to show content if loaded but not showing.
    if (loadedContents && showDisconnected) {
      setShowDisconnected(!context.isOnline);
    }
  }, [context.isOnline, loadedContents]);

  useEffect(() => {
    if (voucherData.length > 0 && profileData.length > 0) {
      setCombinedData(
        voucherData.map((user, i) => {
          return {
            ...user,
            profileData: matchProfile(user.allocationBundleName, profileData),
            onPrint: openPrintModal,
            index: i,
          };
        }),
      );
    }
  }, [voucherData, profileData]);

  const selectNewRow = (state) => {
    const stringKey = Object.keys(state.rowSelection)?.toString();
    if (stringKey !== '' && selectedRow?.toString() !== stringKey) {
      setSelectedRow(parseInt(stringKey, 10));
    }
  };

  const getSelectProfileSection = () => {
    return (
      <>
        <div className="py-3">
          <strong>1. Select a Guest Voucher Profile</strong>
        </div>
        <div className="pb-3 px-4">
          <strong className="text-danger">Important!</strong> Guest vouchers
          will still limit data usage even when allocation amounts are not
          enforced for crew members.
        </div>
        <div
          className={classNames(
            'py-0 d-flex flex-wrap',
            isSmallScreen && 'justify-content-center',
          )}
        >
          <div
            className="flex-column"
            style={{
              width: isSmallScreen ? '600px' : '100%',
              maxWidth: isSmallScreen ? '600px' : '100%',
              fontSize: '14px',
            }}
          >
            <DataTable
              tableClassNames="table-bordered"
              data={profileData}
              columns={
                isSmallScreen
                  ? selectVoucherProfileColumnsMobile
                  : selectVoucherProfileColumns
              }
              enableMultiRowSelection={false}
              handleStateChanged={selectNewRow}
              pageSize={10}
              paginate
            />
          </div>
        </div>
      </>
    );
  };

  const getSubmitButton = () => {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '44px', width: '238px', minWidth: '238px' }}
      >
        {showButtonSpinner ? (
          <Spinner />
        ) : (
          <Button
            className={disableSubmit ? 'btn-light-gray' : 'btn-primary'}
            type="submit"
            disabled={disableSubmit}
          >
            Generate Guest Credentials
          </Button>
        )}
      </div>
    );
  };

  const getGenerateUsersSection = () => {
    return (
      <div className="pb-3">
        <div className="py-3">
          <strong>2. Generate Username(s) and PIN(s)</strong>
        </div>
        <div
          className={classNames(
            'd-flex align-items-top mb-5',
            isSmallScreen ? 'justify-content-center' : 'pl-4',
          )}
        >
          <div className="mt-2" style={{ width: '130px', minWidth: '130px' }}>
            No. of Vouchers:
          </div>
          <div style={{ width: '200px' }}>
            <Input
              name="users"
              groupClassName={classNames(
                'ml-2 my-1',
                !isExtraSmallScreen && 'mr-5',
              )}
              type="number"
              min="1"
              max="100"
              value={numNewUsers}
              onInput={(e) => {
                setNumNewUsers(parseInt(e.target.value, 10));
              }}
            />
          </div>
          {!isExtraSmallScreen && getSubmitButton()}
        </div>
        {isExtraSmallScreen && (
          <div className="pb-5 d-flex justify-content-center">
            {getSubmitButton()}
          </div>
        )}
      </div>
    );
  };

  const handleGetGuestVouchers = async () => {
    try {
      // Get new guest voucher data, paginating as needed.
      const vouchers = await callPaginatedGetApi(
        getGuestVouchers,
        context,
        setContext,
        0,
        numNewUsers,
        numNewUsers,
        [],
        () => setShowDisconnected(true),
      );
      if (vouchers != null) {
        setVoucherData(vouchers);
      } else {
        setShowDisconnected(true);
      }
    } catch (error) {
      setShowDisconnected(true);
    }
  };

  const handlePostGuestVouchers = async () => {
    setShowButtonSpinner(true);

    // HACK: Validates vtid by retrieving the current JWT token for the terminal
    //       and comparing to the vtid in context.
    const vtidsMatch = await isVtidValid(context.vtid);
    if (!vtidsMatch) {
      // If invalid token or vtid, log out the user for security.
      setContext({ forceLogOut: true });
      // END HACK
    } else {
      const newVouchersData = {
        allocationBundleUUID: profileData[selectedRow]?.uuid,
        guestCount: numNewUsers,
      };
      await postGuestVouchers(context.accessToken, newVouchersData)
        .then(async (response) => {
          if (response?.status === 204) {
            await handleGetGuestVouchers();
            toast.success(
              `${numNewUsers} guest voucher${
                numNewUsers > 1 ? 's' : ''
              } created`,
            );
            setVouchersCreated(true);
          } else {
            toast.error('An error occurred. Guest vouchers were not created.');
          }
          setShowButtonSpinner(false);
        })
        .catch((error) => {
          if (
            error?.response?.data?.errorMessage ===
            ExternalMsgs.django_token_not_valid
          ) {
            // If invalid token, log out the user for security.
            setContext({ forceLogOut: true });
          } else {
            // eslint-disable-next-line no-console
            console.log(error);
          }
          setShowButtonSpinner(false);
        });
    }
  };

  const selectNewVoucherRow = (state) => {
    const newRows = Object.keys(state.rowSelection).map((stringKey) => {
      return parseInt(stringKey, 10);
    });
    if (
      selectedVoucherRows !== newRows &&
      newRows.length !== selectedVoucherRows.length
    ) {
      setSelectedVoucherRows(newRows);
    }
  };

  const getResultsSection = () => {
    return (
      <>
        <hr className="mt-0" />
        <div
          className={classNames(
            'pt-5 pb-3 d-flex align-items-center justify-content-between',
            isExtraSmallScreen ? 'flex-column' : 'pl-3',
          )}
        >
          <div
            className={classNames(
              'd-flex align-items-center text-success',
              !isExtraSmallScreen && 'mr-5',
            )}
          >
            <Icon name="check-mark" style={{ height: '30px', width: '30px' }} />
            <div className="pl-2" style={{ fontSize: '20px' }}>
              <strong>Success!</strong>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'd-flex align-items-center',
            isSmallScreen && 'justify-content-center',
          )}
        >
          <Button
            className="mr-5"
            variant="link"
            disabled={selectedVoucherRows.length < 1}
            onClick={() =>
              printGuestVouchers(openPrintModal, selectedVoucherRows)
            }
          >
            {`Print Selected Vouchers (${selectedVoucherRows.length})`}
          </Button>
          <Button
            variant="link"
            onClick={() =>
              printGuestVouchers(
                openPrintModal,
                combinedData.map((_, i) => i),
              )
            }
          >
            Print All New Vouchers
          </Button>
        </div>
        <div
          className={classNames(
            'd-flex flex-wrap',
            isSmallScreen && 'justify-content-center',
          )}
        >
          <div
            className="flex-column"
            style={{
              width: isSmallScreen ? '600px' : '100%',
              maxWidth: isSmallScreen ? '600px' : '100%',
              fontSize: '14px',
            }}
          >
            <DataTable
              tableClassNames="table-bordered"
              data={combinedData}
              columns={
                isSmallScreen ? guestVoucherColumnsMobile : guestVoucherColumns
              }
              pageSize={10}
              paginate
              onAction={handleGuestVoucherActions}
              enableMultiRowSelection
              handleStateChanged={selectNewVoucherRow}
            />
          </div>
        </div>

        {printModal && (
          <PrintVoucherModal
            modal={printModal}
            onHide={onHidePrintModal}
            voucherData={voucherData}
            profileData={profileData}
            selectedRows={printedVouchers}
          />
        )}

        <div
          className={classNames(
            'd-flex justify-content-center align-items-center py-4',
            isExtraSmallScreen && 'flex-column',
          )}
        >
          <Button
            className={classNames(
              'btn-link',
              isExtraSmallScreen ? 'mb-4' : 'mr-5',
            )}
            variant="link"
            onClick={() => navigate(guestVouchersRoute)}
          >
            Back to Guest Vouchers Home
          </Button>
          <Button icon="add-circle-outline" onClick={() => resetForm()}>
            New Voucher(s)
          </Button>
        </div>
      </>
    );
  };

  return (
    <ContentBox
      htmlHeading={
        <div className="d-flex mb-4">
          <Button
            variant="link"
            className="py-0 ml-n3"
            onClick={() => navigate(guestVouchersRoute)}
          >
            <Icon
              name="chevron-left"
              style={{ height: '16px', width: '16px' }}
            />
          </Button>
          <header className="h5 d-flex m-0">Create a New Guest Voucher</header>
        </div>
      }
    >
      {showSpinner ? (
        <div className="d-flex justify-content-center py-4">
          <Spinner />
        </div>
      ) : (
        <div>
          {showDisconnected ? (
            <div className="p-4">{ErrorMsgs.information_not_available}</div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={handlePostGuestVouchers}
              initialValues={{
                profile: -1,
                users: 1,
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  {getSelectProfileSection()}
                  {getGenerateUsersSection()}
                  {vouchersCreated && getResultsSection()}
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
    </ContentBox>
  );
};

export default AddGuestVouchers;
