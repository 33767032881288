import { Button, DataTable, Icon, useSmallScreen } from '@hvk/react-components';
import classNames from 'class-names';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import {
  selectUserColumns,
  selectVoucherProfileColumns,
  selectVoucherProfileColumnsMobile,
  userVoucherColumns,
  userVoucherColumnsMobile,
} from '../../components/DataTables/ColumnData';
import Spinner from '../../components/Spinner/Spinner';
import ContentBox from '../../layout/ContentBox';
import { callPaginatedGetApi } from '../../services/serviceHelper';
import {
  getCrewUsers,
  getProfileBundles,
  getUserVouchers,
  postUserVouchers,
} from '../../services/VouchersService';
import { StateContext } from '../../StateContext';
import { isVtidValid, matchProfile } from '../../utility/HelperFunctions';
import { userVouchersRoute } from '../../utility/routes';
import { ErrorMsgs, ExternalMsgs } from '../../utility/StaticTexts';

const AddUserVouchers = () => {
  const { context, setContext } = useContext(StateContext);
  const navigate = useNavigate();
  const isExtraSmallScreen = useSmallScreen('xs');
  const isSmallScreen = useSmallScreen('sm');

  const [userData, setUserData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [loadedUserContents, setLoadedUserContents] = useState(false);
  const [loadedProfileContents, setLoadedProfileContents] = useState(false);
  const [showDisconnected, setShowDisconnected] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showButtonSpinner, setShowButtonSpinner] = useState(false);

  const [selectedUserRow, setSelectedUserRow] = useState(-1);
  const [selectedVoucherRow, setSelectedVoucherRow] = useState(-1);
  const [vouchersCreated, setVouchersCreated] = useState(false);
  const [voucherData, setVoucherData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const disableSubmit =
    vouchersCreated || selectedUserRow < 0 || selectedVoucherRow < 0;

  // HACK: Ignores checking for positive values for Formik fields.
  //       Why are the Formik fields not set properly when using DataTables?
  const schema = yup.object().shape({
    user: yup.number().required('Crew member is required'),
    profile: yup.number().required('Voucher profile is required'),
  });

  const resetForm = () => {
    setVouchersCreated(false);
  };

  const handleGetUsers = async () => {
    try {
      setShowSpinner(true);
      setShowDisconnected(!context.isOnline);

      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = await isVtidValid(context.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      // Get user data, paginating as needed.
      const users = await callPaginatedGetApi(
        getCrewUsers,
        context,
        setContext,
        0,
        100,
        null,
        [],
        () => setShowDisconnected(true),
      );
      if (users != null) {
        setUserData(users);
        setLoadedUserContents(true);
      } else {
        setShowDisconnected(true);
      }
    } catch (error) {
      setShowDisconnected(true);
    }
  };

  const handleGetProfileBundles = async () => {
    try {
      setShowSpinner(true);
      setShowDisconnected(!context.isOnline);

      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = await isVtidValid(context.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      // Get user profile data, paginating as needed.
      const profiles = await callPaginatedGetApi(
        getProfileBundles,
        context,
        setContext,
        0,
        100,
        null,
        ['?kind=voucher'],
        () => setShowDisconnected(true),
      );
      if (profiles != null) {
        setProfileData(profiles);
        setLoadedProfileContents(true);
      } else {
        setShowDisconnected(true);
      }
    } catch (error) {
      setShowDisconnected(true);
    }
  };

  useEffect(() => {
    // Attempts to load content if not already loaded.
    if (!loadedUserContents) {
      handleGetUsers();
    }
  }, [context.isOnline, loadedUserContents]);

  useEffect(() => {
    // Attempts to load content if not already loaded.
    if (!loadedProfileContents) {
      handleGetProfileBundles();
    }
  }, [context.isOnline, loadedProfileContents]);

  useEffect(() => {
    // Attempts to show content if loaded but not showing.
    if (loadedUserContents && loadedProfileContents && showDisconnected) {
      setShowDisconnected(!context.isOnline);
    }
    setShowSpinner(!(loadedUserContents && loadedProfileContents));
  }, [context.isOnline, loadedUserContents, loadedProfileContents]);

  useEffect(() => {
    if (voucherData.length > 0 && profileData.length > 0) {
      setCombinedData(
        voucherData.map((user) => {
          return {
            ...user,
            profileData: matchProfile(user.allocationBundleName, profileData),
          };
        }),
      );
    }
  }, [voucherData, profileData]);

  const selectNewUserRow = (state) => {
    const stringKey = Object.keys(state.rowSelection)?.toString();
    if (stringKey !== '' && selectedUserRow?.toString() !== stringKey) {
      setSelectedUserRow(parseInt(stringKey, 10));
    }
  };

  const getSelectUserSection = () => {
    return (
      <>
        <div className="py-3">
          <strong>1. Select a Crew Member</strong>
        </div>
        <div
          className={classNames(
            'py-0 d-flex flex-wrap',
            isSmallScreen && 'justify-content-center',
          )}
        >
          <div
            className="flex-column"
            style={{
              width: '600px',
              maxWidth: '600px',
              fontSize: '14px',
            }}
          >
            <DataTable
              tableClassNames="table-bordered"
              data={userData}
              columns={selectUserColumns}
              enableMultiRowSelection={false}
              handleStateChanged={selectNewUserRow}
              searchable
              pageSize={10}
              paginate
            />
          </div>
        </div>
      </>
    );
  };

  const selectNewVoucherRow = (state) => {
    const stringKey = Object.keys(state.rowSelection)?.toString();
    if (stringKey !== '' && selectedVoucherRow?.toString() !== stringKey) {
      setSelectedVoucherRow(parseInt(stringKey, 10));
    }
  };

  const getSelectProfileSection = () => {
    return (
      <>
        <div className="py-3">
          <strong>2. Select a Voucher Profile</strong>
        </div>
        <div
          className={classNames(
            'py-0 d-flex flex-wrap',
            isSmallScreen && 'justify-content-center',
          )}
        >
          <div
            className="flex-column"
            style={{
              width: isSmallScreen ? '600px' : '100%',
              maxWidth: isSmallScreen ? '600px' : '100%',
              fontSize: '14px',
              // HACK: Restores disappearing top border on mobile.
              borderTop: isSmallScreen ? '1px solid #DEE2E6' : '',
              boxSizing: 'border-box',
              MozBoxSizing: 'border-box',
              WebkitBoxSizing: 'border-box',
            }}
          >
            <DataTable
              tableClassNames="table-bordered"
              data={profileData}
              columns={
                isSmallScreen
                  ? selectVoucherProfileColumnsMobile
                  : selectVoucherProfileColumns
              }
              enableMultiRowSelection={false}
              handleStateChanged={selectNewVoucherRow}
              pageSize={10}
              paginate
            />
          </div>
        </div>
      </>
    );
  };

  const getSubmitButton = () => {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '44px', width: '265px' }}
      >
        {showButtonSpinner ? (
          <Spinner />
        ) : (
          <Button
            className={disableSubmit ? 'btn-light-gray' : 'btn-primary'}
            type="submit"
            disabled={disableSubmit}
          >
            Assign Voucher to Crew Member
          </Button>
        )}
      </div>
    );
  };

  const getAssignVoucherSection = () => {
    return (
      <div className="pb-3">
        <div className="py-3">
          <strong>3. Assign Voucher</strong>
        </div>
        <div
          className={classNames(
            'mb-5',
            isSmallScreen && 'd-flex justify-content-center',
          )}
        >
          {getSubmitButton()}
        </div>
      </div>
    );
  };

  const handleGetUserVouchers = async () => {
    try {
      // Get user voucher data, paginating as needed.
      const vouchers = await callPaginatedGetApi(
        getUserVouchers,
        context,
        setContext,
        0,
        1,
        1,
        [],
        () => setShowDisconnected(true),
      );
      if (vouchers != null) {
        setVoucherData(vouchers);
      } else {
        setShowDisconnected(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handlePostUserVouchers = async () => {
    setShowButtonSpinner(true);

    // HACK: Validates vtid by retrieving the current JWT token for the terminal
    //       and comparing to the vtid in context.
    const vtidsMatch = await isVtidValid(context.vtid);
    if (!vtidsMatch) {
      // If invalid token or vtid, log out the user for security.
      setContext({ forceLogOut: true });
      // END HACK
    } else {
      const newVouchersData = {
        allocationBundleUUID: profileData[selectedVoucherRow]?.uuid,
        userUUID: userData[selectedUserRow]?.uuid,
      };
      await postUserVouchers(context.accessToken, newVouchersData)
        .then(async (response) => {
          if (response?.status === 204) {
            await handleGetUserVouchers();
            toast.success('Voucher assigned to user.');
            setVouchersCreated(true);
          } else {
            toast.error('An error occurred. User voucher was not assigned.');
          }
          setShowButtonSpinner(false);
        })
        .catch((error) => {
          if (
            error?.response?.data?.errorMessage ===
            ExternalMsgs.django_token_not_valid
          ) {
            // If invalid token, log out the user for security.
            setContext({ forceLogOut: true });
          } else {
            // eslint-disable-next-line no-console
            console.log(error);
          }
          setShowButtonSpinner(false);
        });
    }
  };

  const getResultsSection = () => {
    return (
      <>
        <hr className="mt-0" />
        <div
          className={classNames(
            'pt-5 pb-3 d-flex align-items-center text-success',
            isExtraSmallScreen ? 'justify-content-center' : 'pl-3',
          )}
        >
          <Icon name="check-mark" style={{ height: '30px', width: '30px' }} />
          <div className="pl-2" style={{ fontSize: '20px' }}>
            <strong>Success!</strong>
          </div>
        </div>
        <div
          className={classNames(
            'd-flex flex-wrap',
            isSmallScreen && 'justify-content-center',
          )}
        >
          <div
            className="flex-column"
            style={{
              width: isSmallScreen ? '600px' : '100%',
              maxWidth: isSmallScreen ? '600px' : '100%',
              fontSize: '14px',
              // HACK: Restores disappearing top border.
              borderTop: isSmallScreen ? '1px solid #DEE2E6' : '',
              boxSizing: 'border-box',
              MozBoxSizing: 'border-box',
              WebkitBoxSizing: 'border-box',
            }}
          >
            <DataTable
              tableClassNames="table-bordered"
              data={combinedData}
              columns={
                isSmallScreen ? userVoucherColumnsMobile : userVoucherColumns
              }
              pageSize={10}
              paginate
            />
          </div>
        </div>
        <div
          className={classNames(
            'd-flex justify-content-center align-items-center py-4',
            isExtraSmallScreen && 'flex-column',
          )}
        >
          <Button
            className={classNames(
              'btn-link',
              isExtraSmallScreen ? 'mb-4' : 'mr-5',
            )}
            variant="link"
            onClick={() => navigate(userVouchersRoute)}
          >
            Back to User Vouchers Home
          </Button>
          <Button icon="add-circle-outline" onClick={() => resetForm()}>
            Assign A Voucher
          </Button>
        </div>
      </>
    );
  };

  return (
    <ContentBox
      htmlHeading={
        <div className="d-flex mb-4">
          <Button
            variant="link"
            className="py-0 ml-n3"
            onClick={() => navigate(userVouchersRoute)}
          >
            <Icon
              name="chevron-left"
              style={{ height: '16px', width: '16px' }}
            />
          </Button>
          <header className="h5 d-flex m-0">Assign a New User Voucher</header>
        </div>
      }
    >
      {showSpinner ? (
        <div className="d-flex justify-content-center py-4">
          <Spinner />
        </div>
      ) : (
        <div>
          {showDisconnected ? (
            <div className="p-4">{ErrorMsgs.information_not_available}</div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={handlePostUserVouchers}
              initialValues={{
                user: -1,
                profile: -1,
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  {getSelectUserSection()}
                  {getSelectProfileSection()}
                  {getAssignVoucherSection()}
                  {vouchersCreated && getResultsSection()}
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
    </ContentBox>
  );
};

export default AddUserVouchers;
