import { mockDelay } from './mockDelay';

export const getUsageLog = async (msDelay = 500, success = true) => {
  await mockDelay(msDelay);
  return {
    data: success
      ? {
          totalRecords: 500,
          data: [
            {
              sessionUUID: '00000000-0000-0000-0000-000000000000',
              wanName: 'wifi',
              byteIn: 13000,
              byteOut: 2000,
              byteCount: 15000,
              startingBytes: 0,
              remainingBytes: 0,
              startingSecsPerDay: 0,
              remainingSecsPerDay: 300,
              startedAt: '2022-12-20T14:08:54.509849Z',
              stoppedAt: '2022-12-20T14:08:54.509863Z',
              durationSecs: 120,
              terminalIdentifier: 'vtid129019',
            },
            {
              sessionUUID: '00000000-0000-0000-0000-000000000001',
              wanName: 'cell',
              byteIn: 15000,
              byteOut: 3600,
              byteCount: 20000,
              startingBytes: 0,
              remainingBytes: 0,
              startingSecsPerDay: 0,
              remainingSecsPerDay: 900,
              startedAt: '2022-09-24T08:58:05.559914Z',
              stoppedAt: '2022-09-24T10:00:05.559930Z',
              durationSecs: 200,
              terminalIdentifier: 'vtid129019',
            },
            {
              sessionUUID: '00000000-0000-0000-0000-000000000002',
              wanName: 'vsat',
              byteIn: 11000,
              byteOut: 1000,
              byteCount: 13000,
              startingBytes: 0,
              remainingBytes: 0,
              startingSecsPerDay: 0,
              remainingSecsPerDay: 100,
              startedAt: '2022-09-21T14:58:05.559914Z',
              stoppedAt: '2022-09-21T16:00:05.559930Z',
              durationSecs: 100,
              terminalIdentifier: 'vtid129019',
            },
          ],
        }
      : null,
    status: success ? 200 : 504,
  };
};
