import { DataTable, useSmallScreen } from '@hvk/react-components';
import classNames from 'class-names';
import React, { useContext, useEffect, useState } from 'react';

import {
  usageLogColumns,
  usageLogColumnsMobile,
} from '../../components/DataTables/ColumnData';
import Spinner from '../../components/Spinner/Spinner';
import ContentBox from '../../layout/ContentBox';
import { getUsageLog } from '../../services/UsageLogService';
import { StateContext } from '../../StateContext';
import { isVtidValid } from '../../utility/HelperFunctions';
import { ErrorMsgs, ExternalMsgs } from '../../utility/StaticTexts';

const UsageLog = () => {
  const { context, setContext } = useContext(StateContext);
  const isSmallScreen = useSmallScreen('sm');
  const isMediumScreen = useSmallScreen('md');

  const [usageData, setUsageData] = useState([]);
  const [loadedContents, setLoadedContents] = useState(false);
  const [showDisconnected, setShowDisconnected] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const handleGetUsageLog = async () => {
    try {
      setShowSpinner(true);
      setShowDisconnected(!context.isOnline);

      // HACK: Validates vtid by retrieving the current JWT token for the terminal
      //       and comparing to the vtid in context.
      const vtidsMatch = await isVtidValid(context.vtid);
      if (!vtidsMatch) {
        // If invalid token or vtid, log out the user for security.
        setContext({ forceLogOut: true });
      }
      // END HACK

      await getUsageLog(context.accessToken)
        .then((response) => {
          if (response?.status === 200) {
            setUsageData(response.data.data);
            setLoadedContents(true);
          } else {
            setShowDisconnected(true);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.errorMessage ===
            ExternalMsgs.django_token_not_valid
          ) {
            // If invalid token, log out the user for security.
            setContext({ forceLogOut: true });
          } else {
            setShowDisconnected(true);
          }
        });
    } catch (error) {
      setShowDisconnected(true);
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    // Attempts to load content if not already loaded.
    if (!loadedContents) {
      handleGetUsageLog();
    }

    // Attempts to show content if loaded but not showing.
    if (loadedContents && showDisconnected) {
      setShowDisconnected(!context.isOnline);
    }
  }, [context.isOnline, loadedContents]);

  return (
    <ContentBox heading="Usage Log">
      {showSpinner ? (
        <div className="d-flex justify-content-center py-4">
          <Spinner />
        </div>
      ) : (
        <div>
          {showDisconnected ? (
            <div className="p-4">{ErrorMsgs.information_not_available}</div>
          ) : (
            <div
              className={classNames(
                'pb-5 d-flex flex-wrap',
                !isMediumScreen && 'pt-5',
                isSmallScreen && 'justify-content-center',
              )}
            >
              <div
                className="flex-column"
                style={{
                  width: isSmallScreen ? '600px' : '100%',
                  maxWidth: isSmallScreen ? '600px' : '100%',
                  fontSize: '14px',
                  // HACK: Restores disappearing top border on mobile.
                  borderTop: isSmallScreen ? '1px solid #DEE2E6' : '',
                  boxSizing: 'border-box',
                  MozBoxSizing: 'border-box',
                  WebkitBoxSizing: 'border-box',
                }}
              >
                <DataTable
                  tableClassNames="table-bordered"
                  data={usageData}
                  columns={
                    isSmallScreen ? usageLogColumnsMobile : usageLogColumns
                  }
                  pageSize={10}
                  paginate
                />
              </div>
            </div>
          )}
        </div>
      )}
    </ContentBox>
  );
};

export default UsageLog;
